/* eslint  @typescript-eslint/no-unused-vars: 0 */

import { captureMessage, captureException } from '@sentry/react';
import store from '../redux/store';
import { Inmate, Fees, Bill, StoreState, Coin } from '../redux/types';
import { SettingsData } from '../redux/types';
import { MAX_ATTEMPTS, SERVER_URL, TIME_BETWEEN_ATTEMPTS } from './global';
import { useSelector } from 'react-redux';

import { useIntl, IntlProvider } from 'react-intl';

interface getNumR {
    sFee?: number | string | null;
    pFee?: number | string | null;
}

const getServiceType = (service?: any) => {
    switch (service) {
        case 'phone_service':
            return 'Phone Service';
        case 'commissary_service':
            return 'Commissary Service';
        case 'booking_service':
            return 'Booking Service';
    }
};
function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
//Get number representation of Credit Fees
const getNumberCreditFees = (total: number) => {
    const { serviceFee, processingFee } = store.getState().fees.creditFees;
    const { creditMax, creditMin } = store.getState().loadLimits;
    const sAmount = parseFloat(serviceFee.amount!);
    const pAmount = parseFloat(processingFee.amount!);
    let sFee = null,
        pFee = null;
    if (!isNaN(sAmount)) {
        if (serviceFee.isFlat) {
            sFee = sAmount;
        } else {
            sFee = getPercentageFee(sAmount, total);
        }
    }
    if (!isNaN(pAmount)) {
        if (processingFee.isFlat) {
            pFee = pAmount;
        } else {
            pFee = getPercentageFee(pAmount, total);
        }
    }
    const result: getNumR = { sFee, pFee };
    return result;
};

// calculate percentage fees
const getPercentageFee = (feeAmount: number, total: number) => {
    return Math.round((feeAmount * 100 * total) / 100) / 100;
};

const getFlatFee = () => {
    const { serviceFee, processingFee } = store.getState().fees.creditFees;
    const sAmount = parseFloat(serviceFee.amount!);
    const pAmount = parseFloat(processingFee.amount!);
    let sFee = 0,
        pFee = 0;
    if (serviceFee.isFlat) {
        sFee = sAmount;
    } else if (serviceFee.isFlat === false) {
        sFee = parseFloat((sAmount / 100).toFixed(2));
    } else {
        sFee = 0;
    }

    if (processingFee.isFlat) {
        pFee = pAmount;
    } else if (processingFee.isFlat === false) {
        pFee = parseFloat((pAmount / 100).toFixed(2));
    } else {
        pFee = 0;
    }
    const result: getNumR = { sFee, pFee };
    return result;
};

//Get string representation of Credit Fees
const getStringCreditFees = (total: number) => {
    const { serviceFee, processingFee } = store.getState().fees.creditFees;
    let { sFee, pFee } = getNumberCreditFees(total);
    const { creditMin } = store.getState().loadLimits;
    if (sFee !== null) {
        // @ts-ignore
        sFee = String(parseFloat(sFee).toFixed(2));
    }
    if (pFee !== null) {
        // @ts-ignore
        pFee = String(parseFloat(pFee).toFixed(2));
    }
    if (serviceFee.amount !== null) {
        if (serviceFee.isFlat) sFee = '$' + sFee;
        else {
            if (total > 0) sFee = '$' + sFee;
            else sFee = serviceFee.amount + '%';
        }
    }
    if (processingFee.amount !== null) {
        if (processingFee.isFlat) {
            pFee = '$' + pFee;
        } else {
            if (total > 0) pFee = '$' + pFee;
            else pFee = processingFee.amount + '%';
        }
    }
    return { sFee, pFee };
};

//Get number representation of Cash Fees
const getNumberCashFees = (total: number) => {
    const { serviceFee, processingFee } = store.getState().fees.cashFees;
    const sAmount = parseFloat(serviceFee.amount!);
    const pAmount = parseFloat(processingFee.amount!);
    let sFee = null,
        pFee = null;
    if (!isNaN(sAmount)) {
        if (serviceFee.isFlat) {
            sFee = sAmount;
        } else {
            sFee = getPercentageFee(sAmount, total);
        }
    }
    if (!isNaN(pAmount)) {
        if (processingFee.isFlat) {
            pFee = pAmount;
        } else {
            pFee = getPercentageFee(pAmount, total);
        }
    }
    const result: getNumR = { sFee, pFee };
    return result;
};

//Get string representation of cash Fees
const getStringCashFees = (total: number) => {
    const { serviceFee, processingFee } = store.getState().fees.cashFees;
    let { sFee, pFee } = getNumberCashFees(total);

    if (sFee !== null) {
        // @ts-ignore
        sFee = String(parseFloat(sFee).toFixed(2));
    }
    if (pFee !== null) {
        // @ts-ignore
        pFee = String(parseFloat(pFee).toFixed(2));
    }
    if (serviceFee.amount !== null) {
        if (serviceFee.isFlat) sFee = '$' + sFee;
        else {
            if (total > 0) sFee = '$' + sFee;
            else sFee = serviceFee.amount + '%';
        }
    }
    if (processingFee.amount !== null) {
        if (processingFee.isFlat) {
            pFee = '$' + pFee;
        } else {
            if (total > 0) pFee = '$' + pFee;
            else pFee = processingFee.amount + '%';
        }
    }
    return { sFee, pFee };
};

const numberPrettifier = (number: string) => {
    let result = '';

    if (number.length >= 1) {
        result = '(' + number.slice(0, 3);
    }
    if (number.length > 3) {
        result = result + ')' + number.slice(3, 6);
    }
    if (number.length > 6) {
        result = result + '-' + number.slice(6, 10);
    }
    return result;
};

const ccPrettifier = (number: string) => {
    let result = '';
    number = number.replace(/-/g, '');
    if (number.length >= 1) {
        result = number.slice(0, 4);
    }
    if (number.length > 4) {
        result = result + '-' + number.slice(4, 8);
    }
    if (number.length > 8) {
        result = result + '-' + number.slice(8, 12);
    }
    if (number.length > 12) {
        result = result + '-' + number.slice(12, 19);
    }
    return result;
};

const datePrettifier = (number: string) => {
    let result = '';
    number = number.replace(/\//g, '');
    if (number.length >= 1) {
        result = number.slice(0, 2);
    }
    if (number.length > 2) {
        result = result + '/' + number.slice(2, 4);
    }
    return result;
};

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const styles = () => ({
    //style for font size
    resize: {
        fontSize: '30px',
    },
    stateFont: {
        fontSize: '25px',
    },
    label: {
        fontSize: '20px',
        paddingTop: '4px',
    },
    state: {
        height: '73px',
        minWidth: '100px',
        maxWidth: '100px',
    },
    country: {
        display: 'flex',
        alignItems: 'center',
    },
    stateSelect: {
        minHeight: '0px',
    },
    focused: {
        paddingTop: '0px',
    },
});

const btnStyles = () => ({
    yesBtn: {
        width: '170px',
        height: '60px',
        backgroundColor: '#25408F',
        color: '#FFFFFF',
        fontSize: '26px',
    },
    backBtn: {
        width: '170px',
        height: '60px',
        color: '#25408F',
        fontSize: '26px',
    },
});

const stylesReceipt = {
    cell: {
        borderBottom: 'none',
        height: '10px',
        padding: '10px',
        fontSize: '30px',
    },
};

const _getNow = () => {
    const { timezone } = store.getState().kiosk;
    const d = new Date().toLocaleString('en-US', { timeZone: timezone });
    return new Date(d);
};

const getTime = () => {
    const date = _getNow();
    let hours: number | string = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    const amPm = date.getHours() >= 12 ? 'PM' : 'AM';
    hours = hours < 10 ? '0' + hours : hours;
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    // const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const time = hours + ':' + minutes + ' ' + amPm;
    return time;
};

const getDate = () => {
    const today = _getNow();
    return (
        String(today.getMonth() + 1).padStart(2, '0') +
        '/' +
        String(today.getDate()).padStart(2, '0') +
        '/' +
        today.getFullYear()
    );
};
//Convert server response into Fee object
//
const dumpFees = (data: SettingsData, service: string) => {
    // Sets fees for booking service to 0.00 by default. Will need to change if booking service has fees.
    if (service === 'booking_service') {
        const result: Fees = {
            cashFees: { serviceFee: { amount: '0.00', isFlat: null }, processingFee: { amount: '0.00', isFlat: null } },
            creditFees: {
                serviceFee: { amount: '0.00', isFlat: null },
                processingFee: { amount: '0.00', isFlat: null },
            },
        };
        return result;
    } else {
        const result: Fees = {
            cashFees: { serviceFee: { amount: null, isFlat: null }, processingFee: { amount: null, isFlat: null } },
            creditFees: { serviceFee: { amount: null, isFlat: null }, processingFee: { amount: null, isFlat: null } },
        };
        const s_data = data[service];
        // Cash processing Fee
        result.cashFees.processingFee.amount =
            s_data.cash_processing_fee_type !== null ? s_data.cash_processing_fee_amount! : null;
        if (s_data.cash_processing_fee_type !== null) {
            result.cashFees.processingFee.isFlat = s_data.cash_processing_fee_type === 0 ? true : false;
        }
        // Cash service Fee
        result.cashFees.serviceFee.amount =
            s_data.cash_service_fee_type !== null ? s_data.cash_service_fee_amount! : null;
        if (s_data.cash_service_fee_type !== null) {
            result.cashFees.serviceFee.isFlat = s_data.cash_service_fee_type === 0 ? true : false;
        }
        // Credit processing Fee
        result.creditFees.processingFee.amount =
            s_data.credit_processing_fee_type !== null ? s_data.credit_processing_fee_amount! : null;
        if (s_data.credit_processing_fee_type !== null) {
            result.creditFees.processingFee.isFlat = s_data.credit_processing_fee_type === 0 ? true : false;
        }
        // Credit Srvice Fee
        result.creditFees.serviceFee.amount =
            s_data.credit_service_fee_type !== null ? s_data.credit_service_fee_amount! : null;
        if (s_data.credit_service_fee_type !== null) {
            result.creditFees.serviceFee.isFlat = s_data.credit_service_fee_type === 0 ? true : false;
        }
        return result;
    }
};

const getArrayAllowedCash = (data: SettingsData) => {
    const result = [];
    if (data.accept_1) {
        result.push(1);
    }
    if (data.accept_2) {
        result.push(2);
    }
    if (data.accept_5) {
        result.push(5);
    }
    if (data.accept_10) {
        result.push(10);
    }
    if (data.accept_20) {
        result.push(20);
    }
    if (data.accept_50) {
        result.push(50);
    }
    if (data.accept_100) {
        result.push(100);
    }
    return result;
};

const kwPresent = /KioWare/i.test(navigator.userAgent);

const getFirstLastName = (fullName: string) => {
    const arr = fullName.split(', ', 2);
    const firstName = arr[1];
    const lastName = arr[0];
    return { firstName, lastName };
};

const getNumberServiceType = (serviceType: string) => {
    let result;
    switch (serviceType) {
        case 'phone_service':
            result = 0;
            break;
        case 'commissary_service':
            result = 1;
            break;
    }
    return result;
};

const printErrorReceipt = (formatMessage: Function) => {
    const esc = '\x1B'; //ESC byte in hex notation
    const newLine = '\x0A'; //LF byte in hex notation
    const fontSize = esc + '\x21'; // Add number to make it legit 00-99
    const fontAlign = esc + '\x61'; //  00 - left 01 - center 02 - right
    const fontFamiliy = esc + '\x66'; // 00 - A, 01 - B
    const bold = esc + '\x45'; // \x01 on \x00 off
    const cut = '\x1c\xc0\x34';

    const date = getDate();
    const time = getTime();
    //today.getHours() + ':' + String(today.getMinutes()).padStart(2, '0') + ':' + today.getSeconds();
    const state = store.getState();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let regex;
    let cmds = `${esc}\x4a\x02`;
    cmds += `${fontSize}\x13`;
    cmds += `${fontAlign}\x01`;
    cmds += `${formatMessage({ id: 'printError.title' })}`;
    cmds += `${newLine}${newLine}`;
    cmds += `${fontSize}\x00`;
    cmds += state.facility ? `${state.facility},` : '';
    cmds += `${newLine}`;
    cmds += formatMessage({ id: 'receipt.paper.date' }, { date: date, time: time });
    cmds += `${newLine}${newLine}`;
    cmds += `\x1d\x4c\x05\x05`; //Set left margin
    cmds += `${fontSize}\x01`;
    cmds += `${fontAlign}\x00`;
    cmds += `${fontFamiliy}\x00`;
    if (state.outOfOrderMsg) {
        cmds += state.outOfOrderMsg;
    } else {
        cmds += formatMessage({ id: 'printError.message.sorry' });
        cmds += `${newLine}`;
        cmds += formatMessage({ id: 'printError.message.contact' });
    }
    cmds += `${newLine}${newLine}`;
    if (state.serviceType) {
        switch (state.serviceType) {
            case 'phone_service':
                cmds += formatMessage({ id: 'receipt.paper.st.phone_service' });
                cmds += `${newLine}`;
                break;
            case 'commissary_service':
                cmds += formatMessage({ id: 'receipt.paper.st.commissary_service' });
                cmds += `${newLine}`;
                break;
        }
    }
    cmds += state.inmate.inmatename
        ? `${formatMessage({ id: 'receipt.inmate' })} : ${state.inmate.inmatename}${newLine}`
        : '';
    cmds += `${formatMessage({ id: 'receipt.kiosk' })} : ${state.kioskName}`;
    cmds += `${newLine}${newLine}`;
    cmds += `${fontAlign}\x01`;
    cmds += `${fontFamiliy}\x01`;
    cmds += `www.ncic.com`;
    cmds += `${newLine}${newLine}`;
    cmds += `${cut}`;
    // @ts-ignore
    KioRawWinPrinter.Print('printer', cmds);
};

export type TransactionData = {
    id: string;
    inmate_id: string;
    inmate_first_name: string;
    inmate_last_name: string;
    pin: string;
    phone: string;
    payment_type: number;
    bills: Bill[];
    coin: Coin[];
    subtotal: number;
    service_fee: string | number | null | undefined;
    card_number: string;
    processing_fee: string | number | null | undefined;
    total: number;
    ps_load_type?: number;
    service_type?: number;
    str_service_type?: string;
    successful?: boolean;
    result_description?: string;
    photo?: string;
};

const sendTransaction = async (data: TransactionData) => {
    const ERR_MESSAGE = 'Payment result could not be sent to server';
    const SUCCESS_MESSAGE = 'Payment result sent to server';
    const newDate = new Date();
    let p_type, s_type;
    data.payment_type === 1 ? (p_type = 'Cash') : (p_type = 'Credit');

    let WinLog = `Transaction ID: ${data.id}\nInmate ID: ${data.inmate_id}\nInmate Name: ${data.inmate_first_name} ${data.inmate_last_name}\nInmate PIN: ${data.pin}\nAmount: ${data.total}\nPayment Type: ${p_type}\nService Type: ${data.str_service_type}`;
    if (data.service_type === 0) {
        WinLog += '\nLoad Type: ';
        WinLog += data.ps_load_type === 0 ? 'Inmate Account' : 'Phone Time';
    }
    for (let i = 0; i < MAX_ATTEMPTS; i++) {
        try {
            const response = await fetch(SERVER_URL + 'transaction/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (response.status === 204) {
                // @ts-ignore
                KioApp.LogInfo(`${SUCCESS_MESSAGE}:\n${WinLog}`);
                return;
            } else {
                if (i === MAX_ATTEMPTS - 1) {
                    // @ts-ignore
                    KioApp.LogErr(`${ERR_MESSAGE}: ${response.status}\n${WinLog}`);
                    captureMessage(`${ERR_MESSAGE}: ${data.id} at ${newDate.toLocaleString()}`);
                    return;
                }
            }
        } catch (err) {
            if (i === MAX_ATTEMPTS - 1) {
                // @ts-ignore
                KioApp.LogErr(`${ERR_MESSAGE}: ${err}\n${WinLog}`);
                captureMessage(`${ERR_MESSAGE}: ${data.id} at ${newDate.toLocaleString()}`);
                return;
            }
        }
        await sleep(TIME_BETWEEN_ATTEMPTS);
    }
};

const phoneServiceFunction = (
    loadType: number,
    photoMode: number,
    webcamEnabled: boolean,
    webcamDisabled: boolean,
    props: any,
) => {
    switch (loadType) {
        case 0: // Both
            switch (photoMode) {
                case 0:
                    props.history.push('/funds/');
                    break;
                case 1:
                    props.history.push('/funds/');
                    break;
                case 2:
                    if (webcamEnabled === false || webcamDisabled === true) {
                        props.history.push('/funds/');
                        break;
                    } else {
                        props.history.push('/capture/');
                        break;
                    }
            }
            break;
        case 1: // Inmate
            switch (photoMode) {
                case 0:
                    props.history.push('/address/');
                    props.setToPhoneNumber(false);
                    break;
                case 1:
                    props.history.push('/address/');
                    props.setToPhoneNumber(false);
                    break;
                case 2:
                    if (webcamEnabled === false || webcamDisabled === true) {
                        props.history.push('/address/');
                        props.setToPhoneNumber(false);
                        break;
                    } else {
                        props.history.push('/capture/');
                        props.setToPhoneNumber(false);
                        break;
                    }
            }
            break;
        case 2: // Phone number
            switch (photoMode) {
                case 0:
                    props.history.push('/phone/');
                    props.setToPhoneNumber(true);
                    break;
                case 1:
                    props.history.push('/phone/');
                    props.setToPhoneNumber(true);
                    break;
                case 2:
                    if (webcamEnabled === false || webcamDisabled === true) {
                        props.history.push('/phone/');
                        props.setToPhoneNumber(true);
                        break;
                    } else {
                        props.history.push('/capture/');
                        props.setToPhoneNumber(true);
                        break;
                    }
            }
            break;
    }
};
const commissaryServiceFunction = (photoMode: number, webcamEnabled: boolean, webcamDisabled: boolean, props: any) => {
    switch (photoMode) {
        case 0:
            props.history.push('/address/');
            break;
        case 1:
            props.history.push('/address/');
            break;
        case 2:
            if (webcamEnabled === false || webcamDisabled === true) {
                props.history.push('/address/');
                break;
            } else {
                props.history.push('/capture/');
                break;
            }
    }
};
const bookingServiceFunction = (photoMode: number, webcamEnabled: boolean, webcamDisabled: boolean, props: any) => {
    switch (photoMode) {
        case 0:
            props.history.push('/cash/');
            break;
        case 1:
            props.history.push('/cash/');
            break;
        case 2:
            if (webcamEnabled === false || webcamDisabled === true) {
                props.history.push('/cash/');
                break;
            } else {
                props.history.push('/capture/');
                break;
            }
    }
};

const printerStatusCodesObj: any = {
    0: 'Ok',
    1: 'Offline',
    2: 'Cover Open',
    4: 'Low Paper',
    8: 'Out of Paper',
    10: 'No Recover Error',
    20: 'Recover Error',
    40: 'Serial Settings Error',
    80: 'Send Status Query Failed',
    100: 'Read Status Query Failed',
    200: 'Paper Jam',
    400: 'F Device Busy',
    800: 'Monitor Out Of Range',
    1000: 'Device Full',
};

const getPrinterStatusErrorCodes = (printerDeviceStatus: number) => {
    if (printerStatusCodesObj.hasOwnProperty(printerDeviceStatus)) {
        return captureMessage(`Printing Failed: ${printerStatusCodesObj[printerDeviceStatus]}`);
    } else {
        return captureMessage(`Printing Failed: Error code ${printerDeviceStatus}`);
    }
};

export {
    numberPrettifier,
    ccPrettifier,
    datePrettifier,
    sleep,
    styles,
    stylesReceipt,
    kwPresent,
    getStringCreditFees,
    getNumberCreditFees,
    getStringCashFees,
    getNumberCashFees,
    getFlatFee,
    getServiceType,
    getTime,
    getDate,
    dumpFees,
    getArrayAllowedCash,
    btnStyles,
    printErrorReceipt,
    getFirstLastName,
    getNumberServiceType,
    sendTransaction,
    capitalize,
    phoneServiceFunction,
    commissaryServiceFunction,
    bookingServiceFunction,
    getPrinterStatusErrorCodes,
};
