import React, { Component } from 'react';

import './funds.style.scss';

import Footer from '../../components/footer/footer.component';

import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import setToPhoneNumber from '../../redux/toPhoneNumber/toPhoneNumber.action';
import TimeoutWarning from '../../components/timeout/timeout.component';

import { Dispatch } from 'redux';
import { StoreState } from '../../redux/types';
import { FormattedMessage } from 'react-intl';

const ButtonPressable = withStyles({
    root: {
        width: '480px',
        height: '80px',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
        textTransform: 'none',
        fontSize: 40,
        padding: '6px 12px',
        //   border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: 'rgba(37, 64, 143, 0.2)',
        color: '#0044C2',
        borderColor: '#007bff',

        '&:hover': {
            backgroundColor: 'rgba(37, 64, 143, 0.3)',
        },
    },
})(Button);

interface FundsProps {
    [keyname: string]: any;
}

interface FundsState {
    nextPage: string;
}

class Funds extends Component<FundsProps, FundsState> {
    constructor(props: FundsProps) {
        super(props);
        this.state = {
            nextPage: '',
        };
    }

    componentDidMount() {
        this.setState({
            nextPage: this.props.toPhoneNumber ? '/phone/' : '/address/',
        });
    }

    handleToggle = (btnName: string) => {
        this.setState({ nextPage: btnName });
    };

    getState = (btnName: string) => {
        if (btnName === this.state.nextPage) {
            return 'toggled';
        }
    };

    clickConfirm = () => {
        this.props.setToPhoneNumber(this.state.nextPage === '/phone/');
        this.props.history.push(this.state.nextPage);
    };

    render() {
        return (
            <div id="whole">
                <TimeoutWarning />
                <SnackBar />
                <Header screen="funds" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div className="content-funds">
                                <div className="account">
                                    <ButtonPressable
                                        id={this.getState('/address/')}
                                        className="btn-shadow"
                                        value="/address/"
                                        onClick={() => this.handleToggle('/address/')}
                                    >
                                        <FormattedMessage id="funds.account.button" />
                                    </ButtonPressable>
                                </div>
                                <div className="number">
                                    <ButtonPressable
                                        id={this.getState('/phone/')}
                                        value="/phone/"
                                        onClick={() => this.handleToggle('/phone/')}
                                    >
                                        <FormattedMessage id="funds.phone.button" />
                                    </ButtonPressable>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="content-funds">
                                <div className="number" id={this.getState('/phone/')}>
                                    <h3 className="primary">
                                        <FormattedMessage id="funds.phone.header" />
                                    </h3>
                                    <h5>
                                        <FormattedMessage id="funds.phone.description" />
                                    </h5>
                                </div>
                                <div className="account" id={this.getState('/address/')}>
                                    <h3 className="primary">
                                        <FormattedMessage id="funds.account.header" />
                                    </h3>
                                    <h5>
                                        <FormattedMessage id="funds.account.description" />
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickConfirm,
                        confirm: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    toPhoneNumber: state.toPhoneNumber,
});

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setToPhoneNumber: (toPhoneNumber: boolean) => dispatch(setToPhoneNumber(toPhoneNumber)),
});

export default connect(mapStateToProps, mapDispatchFromProps)(Funds);
