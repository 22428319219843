import React, { Component, ChangeEvent } from 'react';

import './amount.style.scss';

import Footer from '../../components/footer/footer.component';

import Header from '../../components/header';
import Fees from '../../components/fees';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import CloseKeyboard from '../../components/useFunction';
import { connect } from 'react-redux';
import TimeoutWarning from '../../components/timeout/timeout.component';
import { getNumberCreditFees, getFlatFee } from '../../components/functions';

import { StoreState } from '../../redux/types';
import { Amounts } from '../../redux/types';
import setAmounts from '../../redux/amounts/amounts.action';
import { Dispatch } from 'redux';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import VKWrapper from '../../components/VKWrapper';

import { withStyles } from '@material-ui/core';
import { styles } from '../../components/functions';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type MethodProps = {
    [keyname: string]: any;
} & WrappedComponentProps;

type LabelValue = {
    number: number;
};

interface AmountState {
    total: string;
    deposit: string;
    inputError: boolean;
    numberCorrect: boolean;
    helperText: string;
    labelValue: LabelValue;
}

class Amount extends Component<MethodProps, AmountState> {
    constructor(props: any) {
        super(props);
        const { sFee: _sFee, pFee: _pFee } = getFlatFee();
        const highestFee =
            this.props.loadLimits.creditMin > Math.ceil((_sFee as any) + (_pFee as any))
                ? this.props.loadLimits.creditMin
                : Math.ceil((_sFee as any) + (_pFee as any));
        this.state = {
            total: '',
            deposit: '',
            inputError: false,
            numberCorrect: false,
            helperText: 'amount.helperMin',
            labelValue: {
                number: highestFee,
            },
        };
    }

    componentDidMount() {
        if (this.props.amounts.total)
            this.setState({
                total: this.props.amounts.total,
                deposit: this.props.amounts.deposit,
                numberCorrect: true,
            });
    }

    clickConfirm = () => {
        if (!this.state.numberCorrect) {
            this.setState({ inputError: true });
        } else {
            if (/KioWare/i.test(navigator.userAgent)) {
                CloseKeyboard();
            }
            const amounts = {
                total: parseFloat(this.state.total),
                deposit: parseFloat(this.state.deposit),
            };
            this.props.setAmounts(amounts);
            this.props.history.push('/caddress/');
        }
    };

    handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const el = e.target as HTMLInputElement;
        const creditMaxLength = this.props.loadLimits.creditMax.toString().length;
        //delete non-number chars
        let number = parseInt(el.value.replace(/[^.\d]/g, '').slice(0, creditMaxLength));
        let total = number.toString();
        //if nothing is in field
        if (isNaN(number)) {
            number = 0;
            total = '';
        }
        //Get numbers out of fees
        let { sFee, pFee } = getNumberCreditFees(parseFloat(total));
        sFee = sFee ? sFee : 0;
        pFee = pFee ? pFee : 0;
        const { sFee: __sFee, pFee: __pFee } = getFlatFee();
        let deposit;
        if (number > 0) {
            deposit = number - (sFee as any) - (pFee as any);
            deposit = deposit > 0 ? deposit : 0;
            deposit = deposit.toString();
        } else {
            deposit = '';
        }
        this.setState({
            deposit: deposit ? parseFloat(deposit).toFixed(2) : '',
            total: total ? total : '',
        });
        if (!total) {
            return;
        }
        const numCheck = number;
        if (numCheck >= this.props.loadLimits.creditMin && numCheck >= Math.ceil((__sFee as any) + (__pFee as any))) {
            this.setState({
                numberCorrect: true,
                inputError: false,
                helperText: 'amount.helperMax',
                labelValue: { number: this.props.loadLimits.creditMax },
            });
            if (numCheck > this.props.loadLimits.creditMax) {
                this.setState({
                    numberCorrect: false,
                    inputError: true,
                    helperText: 'amount.helperMax',
                    labelValue: { number: this.props.loadLimits.creditMax },
                });
            }
        } else {
            if (this.props.loadLimits.creditMin >= Math.ceil((__sFee as any) + (__pFee as any))) {
                this.setState({
                    numberCorrect: false,
                    inputError: true,
                    helperText: 'amount.helperMin',
                    labelValue: {
                        number: this.props.loadLimits.creditMin,
                    },
                });
            } else {
                this.setState({
                    numberCorrect: false,
                    inputError: true,
                    helperText: 'amount.helperMin',
                    labelValue: {
                        number: Math.ceil((__sFee as any) + (__pFee as any)),
                    },
                });
            }
        }
    };

    render() {
        return (
            <div id="whole">
                <TimeoutWarning />
                <SnackBar />
                <Header screen="amount" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div className="content">
                                <div className="phone-number">
                                    <div className="moveUp">
                                        <AttachMoneyIcon
                                            fontSize="large"
                                            className="primary"
                                            style={{ fontSize: '40px', marginTop: '15px' }}
                                        />

                                        <VKWrapper
                                            id="amount"
                                            screen="amount"
                                            autoFocus={!Boolean(this.props.amounts.total)}
                                            typeKeyboard="number"
                                            labelValue={this.state.labelValue}
                                            error={this.state.inputError}
                                            helperText={this.state.helperText}
                                            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                                this.handleChange(e);
                                            }}
                                            value={this.state.total}
                                            variant="outlined"
                                            InputProps={{
                                                classes: {
                                                    input: this.props.classes.resize,
                                                    notchedOutline: this.props.classes.label,
                                                },
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: this.props.classes.label,
                                                    shrink: this.props.classes.focused,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="fees">
                                <Fees total={this.state.total} byCash={false} deposit={this.state.deposit} />
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickConfirm,
                        confirm: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    fees: state.fees,
    amounts: state.amounts,
    loadLimits: state.loadLimits,
});

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setAmounts: (amounts: Amounts) => dispatch(setAmounts(amounts)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchFromProps)(injectIntl(Amount)));
