const setShowKeyboard = (showKeyboard: boolean) => ({
    type: 'SET_SHOWKEYBOARD',
    payload: showKeyboard,
});
const setNextClicked = (showKeyboard: boolean) => ({
    type: 'SET_NEXTKEYBOARD',
    payload: showKeyboard,
});

export { setShowKeyboard, setNextClicked };
