import { Component } from 'react';
import React from 'react';

import './confirm.style.scss';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';
import TimeoutWarning from '../../components/timeout/timeout.component';
import Loading from '../../components/loading/loading.component';
import { addBreadcrumb, captureMessage, Severity } from '@sentry/react';

import { connect } from 'react-redux';

import { StoreState } from '../../redux/types';
import { Dispatch } from 'redux';
import setToPhoneNumber from '../../redux/toPhoneNumber/toPhoneNumber.action';
import { FormattedMessage } from 'react-intl';
import { phoneServiceFunction, commissaryServiceFunction, bookingServiceFunction } from '../../components/functions';

import setCaptureImage from '../../redux/captureImage/captureImage.action';
import Webcam from 'react-webcam';

interface ConfirmProps {
    [keyname: string]: any;
}
interface ConfirmState {
    time: number;
    showSnackbar: boolean;
    showCamera: boolean;
    loaderClass: boolean;
    contentHidden: boolean;
    webcamDisabled: boolean;
}

class Confirm extends Component<ConfirmProps, ConfirmState> {
    constructor(props: ConfirmProps) {
        super(props);
        this.state = {
            time: 0,
            showSnackbar: false,
            showCamera: false,
            loaderClass: false,
            contentHidden: true,
            webcamDisabled: false,
        };
    }

    componentDidMount() {
        //Breadcrumbs for Sentry
        addBreadcrumb({
            category: 'Page',
            message: 'Confirm',
            level: Severity.Info,
        });
        if (this.props.photoMode === 0 || this.props.kiosk.webcamEnabled === false) {
            this.setState({ loaderClass: true });
            this.setState({ contentHidden: false });
        } else {
            this.setState({ showCamera: true });
        }
    }

    setRef = (webcam: any) => {
        // @ts-ignore
        this.webcam = webcam;
    };

    handleUserMedia = () => {
        this.setState({ loaderClass: true });
        this.setState({ contentHidden: false });
    };

    handleWebcamDisabled = () => {
        this.setState({ webcamDisabled: true });
        this.setState({ loaderClass: true });
        this.setState({ contentHidden: false });
        addBreadcrumb({
            category: 'Webcam',
            message: 'Webcam disabled',
            level: Severity.Error,
        });
        captureMessage('Webcam could not be detected.');
    };

    clickConfirm = (props: any) => {
        switch (this.props.serviceType) {
            case 'phone_service':
                phoneServiceFunction(
                    this.props.loadType,
                    this.props.photoMode,
                    this.props.kiosk.webcamEnabled,
                    this.state.webcamDisabled,
                    props,
                );
                break;
            case 'commissary_service':
                commissaryServiceFunction(
                    this.props.photoMode,
                    this.props.kiosk.webcamEnabled,
                    this.state.webcamDisabled,
                    props,
                );
                break;
            case 'booking_service':
                bookingServiceFunction(
                    this.props.photoMode,
                    this.props.kiosk.webcamEnabled,
                    this.state.webcamDisabled,
                    props,
                );
                break;
        }
        if (this.props.photoMode === 1 && this.props.kiosk.webcamEnabled === true) {
            // @ts-ignore
            const imageSrc = this.webcam.getScreenshot();
            this.props.setCaptureImage(imageSrc);
        }
    };

    render() {
        const videoConstraints = {
            width: 1920,
            height: 1080,
            facingMode: 'user',
        };

        return (
            <div id="whole">
                <TimeoutWarning />
                {!this.state.contentHidden && <Header screen="confirm" />}
                <div className={`loader ${this.state.loaderClass ? 'loader--online' : 'loader--offline'}`}>
                    <Loading noSnackBar={true} noFooter={false} />
                </div>
                <div id="data">
                    <div className={`hidden ${this.state.contentHidden ? 'hidden--online' : 'hidden--offline'}`}>
                        <div className="content content-inmate">
                            <div className="left">
                                <div className="marginAuto">
                                    <div className="moveUpSmall">
                                        <div
                                            className={`border-card ${
                                                this.props.photoMode === 0 || this.props.kiosk.webcamEnabled === false
                                                    ? 'border-card--offline'
                                                    : 'border-card--online'
                                            }`}
                                        >
                                            <div className="name">
                                                <h5>
                                                    <FormattedMessage id="confirm.name" defaultMessage="Name" />:
                                                </h5>
                                                <h3>{this.props.inmate.inmatename}</h3>
                                            </div>
                                            <div className="pin">
                                                <h5>PIN:</h5> <h3 className="primary">{this.props.inmate.pin}</h3>
                                            </div>
                                        </div>
                                        {this.state.showCamera && (
                                            <div className="confirm-webcam">
                                                <Webcam
                                                    audio={false}
                                                    height={400}
                                                    ref={this.setRef}
                                                    forceScreenshotSourceSize
                                                    screenshotFormat="image/jpeg"
                                                    width={430}
                                                    videoConstraints={videoConstraints}
                                                    onUserMedia={this.handleUserMedia}
                                                    onUserMediaError={this.handleWebcamDisabled}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="confirm-inmate confirm ">
                                    <h3 className="primary">
                                        <FormattedMessage id="confirm.helper" />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: () => {
                            this.clickConfirm(this.props);
                        },
                        confirm: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    inmate: state.inmate,
    timeouts: state.timeouts,
    loadType: state.loadType,
    serviceType: state.serviceType,
    photoMode: state.photoMode,
    kiosk: state.kiosk,
});
const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setToPhoneNumber: (toPhoneNumber: boolean) => dispatch(setToPhoneNumber(toPhoneNumber)),
    setCaptureImage: (toCaptureImage: string) => dispatch(setCaptureImage(toCaptureImage)),
});

export default connect(mapStateToProps, mapDispatchFromProps)(Confirm);
