import { setbyCash } from '../types';

const INITIAL_STATE = true;

const byCashReducer = (state: boolean = INITIAL_STATE, action: setbyCash) => {
    switch (action.type) {
        case 'SET_BY_CASH':
            return action.payload;

        default:
            return state;
    }
};

export default byCashReducer;
