import { setErrorText } from '../types';

const INITIAL_STATE = "something's gone wrong";

const errorTextReducer = (state: string = INITIAL_STATE, action: setErrorText) => {
    switch (action.type) {
        case 'SET_ERROR_TEXT':
            return action.payload;

        default:
            return state;
    }
};

export default errorTextReducer;
