const getServiceHeader = (service?: any) => {
    switch (service) {
        case 'phone_service':
            return 'Inmate Phone Time';
        case 'commissary_service':
            return 'Inmate Commissary Load';
        case 'booking_service':
            return 'Inmate Booking';
        case 'admin':
            return 'Admin Panel';
        case '':
            return 'Select Service';
    }
};

export { getServiceHeader };
