//URLS
const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

// NCIC Server
const CASH_PHONE_DEPOSIT_URL = 'https://ws.ncic.com/WebServices/api/payments/applypaymentbyphonenumber';
const CASH_INMATE_DEPOSIT_URL = 'https://ws.ncic.com/WebServices/api/payments/applypaymentbyinmateid';
const CREDIT_PHONE_DEPOSIT_URL = 'https://ws.ncic.com/WebServices/api/payments/applyccpaymentbyphonenumber';
const CREDIT_INMATE_DEPOSIT_URL = 'https://ws.ncic.com/WebServices/api/payments/applyccpaymentbyinmateid';
const CASH_COMMISSARY_DEPOSIT_URL = 'https://ws.ncic.com/WebServices/api/payments/applycommissarypaymentbyinmateid';
const CREDIT_COMMISSARY_DEPOSIT_URL = 'https://ws.ncic.com/WebServices/api/payments/applycccommissarypaymentbyinmateid';
const RESTRICTED_NUMBERS_URL = 'https://restapi.ncic.com/restrictednumbers';
const NCIC_HEARTBEAT = 'https://ws.ncic.com/WebServices/api/vv/apicheck';

const RESTRICTED_NUMBER_MESSAGE = 'error.restrictedNumber';
const RESTRICTED_SMS_NUMBER_MESSAGE = 'error.restrictedNumberSms';
const SERVER_ERROR_MESSAGE = 'error.server';
// Messages to record for success/fail of sending and processing transactions to NCIC
const ERROR_REPORT_MESSAGE = 'Payment processing failed';
const SUCCESS_REPORT_MESSAGE = 'Payment processed successfully';
// Settings for retry logic
const MAX_ATTEMPTS = 3;
const TIME_BETWEEN_ATTEMPTS = 1 * 1000;

// How often check server if dead, if alive
const CHECK_SERVER_NORMAL = 60 * 1000;
const CHECK_SERVER_CRASHED = 10 * 1000;

//Kiosk unique ID, set by KioConfig
const KIOSK_ID = navigator.userAgent.match(/kioskid=([\w\d-]*)\)/)
    ? navigator.userAgent.match(/kioskid=([\w\d-]*)\)/)![1]
    : undefined;

const LANGUAGES = [
    {
        value: 'en',
        label: 'English',
    },
    {
        value: 'es',
        label: 'Espanol',
    },
];

// Device names in KioWare config
const BILL_ACCEPTOR = 'mei';
const COIN_ACCEPTOR = 'coin';
const RECEIPT_PRINTER = 'printer';

export {
    LANGUAGES,
    SERVER_URL,
    CASH_INMATE_DEPOSIT_URL,
    CASH_PHONE_DEPOSIT_URL,
    CREDIT_INMATE_DEPOSIT_URL,
    CREDIT_PHONE_DEPOSIT_URL,
    CREDIT_COMMISSARY_DEPOSIT_URL,
    CASH_COMMISSARY_DEPOSIT_URL,
    RESTRICTED_NUMBERS_URL,
    RESTRICTED_NUMBER_MESSAGE,
    NCIC_HEARTBEAT,
    SERVER_ERROR_MESSAGE,
    MAX_ATTEMPTS,
    CHECK_SERVER_NORMAL,
    CHECK_SERVER_CRASHED,
    KIOSK_ID,
    RESTRICTED_SMS_NUMBER_MESSAGE,
    TIME_BETWEEN_ATTEMPTS,
    ERROR_REPORT_MESSAGE,
    SUCCESS_REPORT_MESSAGE,
    BILL_ACCEPTOR,
    COIN_ACCEPTOR,
    RECEIPT_PRINTER,
};
