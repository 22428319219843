import React from 'react';
import { connect } from 'react-redux';

import './snackbar.style.scss';

import { Inmate, StoreState } from '../../redux/types';

interface SnackBarProps {
    inmate?: Inmate;
}

const SnackBar = (props: SnackBarProps) => {
    return (
        <div className="snackbar secondary-bgc">
            <div className="left"></div>
            <div className="right">
                <p className="name primary">{props.inmate!.inmatename}:</p>
                <p className="pin text-primary">{props.inmate!.pin}</p>
            </div>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    inmate: state.inmate,
});

export default connect(mapStateToProps)(SnackBar);
