import { Component } from 'react';
import React from 'react';

import FeesContainer from './FeesContainer';
import { FormattedMessage } from 'react-intl';

type Props = {
    amounts?: any;
    fees?: any;
    byCash?: any;
    total?: any;
    deposit?: any;
    serviceType?: any;
    sFee?: any;
    pFee?: any;
};

class PhoneAndCommissaryFees extends Component<Props> {
    renderLeftSide = (props: any) => {
        return (
            <>
                <FormattedMessage id={`fee.total.${props.byCash ? 'cash' : 'credit'}`}>
                    {(msg: string) => <h5>{msg}:</h5>}
                </FormattedMessage>
                <h5>
                    <FormattedMessage id="fee.service" />:
                </h5>
                <h5>
                    <FormattedMessage id="fee.processing" />:
                </h5>
                <h5>
                    <FormattedMessage id="fee.deposit" />:
                </h5>
            </>
        );
    };
    renderRightSide = (props: any) => {
        return (
            <>
                <h5>${props.total}</h5>
                <h5>{props.sFee}</h5>
                <h5>{props.pFee}</h5>
                <h5>${props.deposit}</h5>
            </>
        );
    };
    render() {
        return (
            <FeesContainer
                titleId="fee.header"
                {...this.props}
                renderLeftSide={this.renderLeftSide}
                renderRightSide={this.renderRightSide}
            />
        );
    }
}

export default PhoneAndCommissaryFees;
