/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component } from 'react';
// @ts-ignore
import './cash.style.scss';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';
import Fees from '../../components/fees';
import Loading from '../../components/loading/loading.component';
import SnackBar from '../../components/snackbar/snackbar.component';
import { connect } from 'react-redux';
import setAmounts from '../../redux/amounts/amounts.action';
import {
    btnStyles,
    getFirstLastName,
    getNumberCashFees,
    sendTransaction,
    TransactionData,
} from '../../components/functions';

import cloneDeep from 'lodash/cloneDeep';
import { addBreadcrumb, Severity, captureMessage } from '@sentry/react';

import { Dispatch } from 'redux';
import { StoreState, Amounts, BillAcceptor, Bill, Coin } from '../../redux/types';

import TimeoutWarning from '../../components/timeout/timeout.component';
import BillAcceptorWarning from '../../components/billAcceptorWarning/billAcceptorWarning.component';
import Modal from '@material-ui/core/Modal';
//Always name bills with number in the end!
import Bill_1 from '../../components/img/money1.svg';
import Bill_2 from '../../components/img/money2.svg';
import Bill_5 from '../../components/img/money5.svg';
import Bill_10 from '../../components/img/money10.svg';
import Bill_20 from '../../components/img/money20.svg';
import Bill_50 from '../../components/img/money50.svg';
import Bill_100 from '../../components/img/money100.svg';
import { setBillAcceptor, turnBillAcceptorOn, turnBillAcceptorOff } from '../../redux/billAcceptor/billAcceptor.action';
import setTransactionID from '../../redux/transactionID/transactionID.action';
import setErrorText from '../../redux/errorText/errorText.action';
import {
    CASH_PHONE_DEPOSIT_URL,
    CASH_INMATE_DEPOSIT_URL,
    MAX_ATTEMPTS,
    CASH_COMMISSARY_DEPOSIT_URL,
    TIME_BETWEEN_ATTEMPTS,
    ERROR_REPORT_MESSAGE,
    SUCCESS_REPORT_MESSAGE,
} from '../../components/global';
import { Button, capitalize, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { BILL_ACCEPTOR } from '../../components/global';

function ServerError(this: any, message: string) {
    this.name = 'Server Error';
    this.message = message;
}

ServerError.prototype = new Error();

interface CashProps {
    [keyname: string]: any;
}

interface CashState {
    amounts: Amounts;
    loading: boolean;
    error: boolean;
    attempt: number;
    hideBackButton: boolean;
    modal: boolean;
    fromModal: boolean;
    maxExceeded: boolean;
    cashDisabled: boolean;
}

class Cash extends Component<CashProps, CashState> {
    _isMounted = false;
    _checkBackButtonTimer: undefined | number;

    constructor(props: any) {
        super(props);
        (window as any).cash = this;
        this._checkBackButtonTimer = undefined;
        this.state = {
            amounts: {
                total: 0,
                deposit: 0,
            },
            loading: false,
            error: false,
            attempt: 0,
            hideBackButton: false,
            modal: false,
            fromModal: false,
            maxExceeded: false,
            cashDisabled: false,
        };
    }

    componentDidMount = () => {
        // Gets bill acceptor state, sets to enable and dispatches
        // @ts-ignore
        const acceptorOffline = KioDevice.GetStatus(BILL_ACCEPTOR) === 1;
        if (!acceptorOffline) {
            this.props.turnBillAcceptorOn();
            this._isMounted = true;
            this.setState({ cashDisabled: false });
        } else {
            this.setState({ cashDisabled: true });
        }
    };

    componentDidUpdate = (prevProps: CashProps) => {
        //Call handleBillAcceptorChange if bill acceptor status changed
        if (prevProps.billAcceptor.status !== this.props.billAcceptor.status) {
            this.setState({ hideBackButton: true }, () => {
                clearTimeout(this._checkBackButtonTimer);
                this._checkBackButtonTimer = window.setTimeout(this.checkFunds, 3000);
            });
        }
        // If old amount is different than new amount, update totals (and make sure there's a current bill)
        if (this.props.billAcceptor.stackedTotal !== this.state.amounts.total && this.props.billAcceptor.currentBill) {
            const billAcceptor = cloneDeep(this.props.billAcceptor);
            // @ts-ignore
            this.addAmount(billAcceptor.currentBill);
            this.props.setAmounts(this.state.amounts);
        }
    };

    componentWillUnmount() {
        // Disables bill acceptor hardware
        this.props.turnBillAcceptorOff();
        clearTimeout(this._checkBackButtonTimer);
        this._isMounted = false;
    }

    checkFunds = () => {
        if (this.state.amounts.total === 0) {
            this.setState({ hideBackButton: false });
        }
    };

    addAmount = (bill: Bill) => {
        const amounts = this.state.amounts;
        amounts.total = amounts.total + bill.amount;
        //Get numbers out of fees
        let { sFee, pFee } = getNumberCashFees(amounts.total);
        sFee = sFee ? sFee : 0;
        pFee = pFee ? pFee : 0;
        let deposit = amounts.total - (sFee as any) - (pFee as any);
        deposit = parseFloat(deposit.toFixed(2));
        amounts.deposit = deposit > 0 ? deposit : 0;
        // Autocomplete if max exceeded
        if (amounts.total >= this.props.loadLimits.cashMax) {
            this.setState({ amounts: amounts, maxExceeded: true }, this.clickConfirm);
        }
        this.setState({ amounts: amounts });
    };

    handleError = async (error: any) => {
        addBreadcrumb({
            category: 'Response',
            message: JSON.stringify(error),
            level: Severity.Info,
        });
        captureMessage(`Server error: ${String(error)}`);
        if (this.state.attempt === MAX_ATTEMPTS - 1) {
            const WinLog = `Transaction ID: ${this.props.transactionID}\nInmate ID: ${this.props.inmate.id}\nInmate Name: ${this.props.inmate.inmatename}\nInmate PIN: ${this.props.inmate.pin}\nAmount: ${this.state.amounts.total}\nPayment Type: Cash\nService Type: ${this.props.serviceType}`;
            // @ts-ignore
            KioApp.LogErr(`${ERROR_REPORT_MESSAGE}: ${error}\n${WinLog}`);
            this.props.setErrorText('an error occurred processing your payment');
            // Send to backend
            await this._sendTransaction('Failed sending payment to NCIC servers.');
            this.setState({ error: true });
        } else {
            this.setState({ attempt: this.state.attempt + 1 }, () => {
                setTimeout(() => {
                    if (this._isMounted) {
                        this.payAndRedirect();
                    }
                }, TIME_BETWEEN_ATTEMPTS);
            });
        }
    };

    _sendTransaction = async (error?: string) => {
        const { sFee, pFee } = getNumberCashFees(this.state.amounts.total);
        const { firstName, lastName } = getFirstLastName(this.props.inmate.inmatename);
        let serviceType;
        const bills = this.props.billAcceptor.billsArray.map((bill: Bill) => bill.amount);
        const coins = this.props.billAcceptor.coinsArray.map((coin: Coin) => coin.amount);
        switch (this.props.serviceType) {
            case 'phone_service':
                serviceType = 0;
                break;
            case 'commissary_service':
                serviceType = 1;
                break;
            case 'booking_service':
                serviceType = 2;
                break;
        }
        let data: TransactionData = {
            id: this.props.transactionID,
            inmate_id: this.props.inmate.id,
            inmate_first_name: firstName,
            inmate_last_name: lastName,
            pin: this.props.inmate.unmaskedpin || this.props.inmate.pin,
            phone: this.props.phoneNumber,
            payment_type: 1,
            bills: bills,
            coin: coins,
            subtotal: this.state.amounts.deposit,
            service_fee: sFee,
            card_number: '',
            processing_fee: pFee,
            total: this.state.amounts.total,
            ps_load_type: this.props.toPhoneNumber ? 1 : 0,
            service_type: serviceType,
            photo: this.props.captureImage,
        };
        if (error) {
            data = { ...data, successful: false, result_description: capitalize(error) };
        }
        await sendTransaction({ ...data, str_service_type: this.props.serviceType });
    };

    payAndRedirect = () => {
        // turn off bill acceptor
        this.props.turnBillAcceptorOff();
        let { sFee, pFee } = getNumberCashFees(this.state.amounts.total);
        // To prevent fees from being 'null'
        sFee = sFee ? sFee : 0;
        pFee = pFee ? pFee : 0;
        const body = {
            apikey: this.props.apiKey,
            amount: this.state.amounts.total,
            transactionid: this.props.transactionID,
            depositorfirstname: this.props.address.firstName,
            depositorlastname: this.props.address.lastName,
            depositorphone: this.props.phoneNumber,
            depositoraddress: this.props.address.address,
            depositorcity: this.props.address.city,
            depositorstate: this.props.address.st,
            depositorzip: this.props.address.zip,
            depositorcountry: this.props.address.country,
            processingfee: pFee,
            servicefee: sFee,
        };
        if (this.props.toPhoneNumber) {
            // @ts-ignore
            body.number = this.props.phoneNumber;
        } else {
            // @ts-ignore
            body.id = this.props.inmate.id;
        }
        const bodyWithoutKey = cloneDeep(body);
        delete bodyWithoutKey.apikey;
        addBreadcrumb({
            category: 'Request',
            message: JSON.stringify(bodyWithoutKey),
            level: Severity.Info,
        });
        let URL = '';
        switch (this.props.serviceType) {
            case 'commissary_service':
                URL = CASH_COMMISSARY_DEPOSIT_URL;
                break;
            case 'phone_service':
                URL = this.props.toPhoneNumber ? CASH_PHONE_DEPOSIT_URL : CASH_INMATE_DEPOSIT_URL;
                break;
            case 'booking_service':
                URL = CASH_INMATE_DEPOSIT_URL;
                break;
            default:
                captureMessage('Unknown service type');
                break;
        }
        fetch(
            // Changes endpoint regarding type of service
            URL,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
        )
            .then((response) => response.json())
            .then((data) => {
                this._redirect(data);
            })
            .catch((error) => {
                this.handleError(error);
            });
    };

    _redirect = async (data: any) => {
        addBreadcrumb({
            category: 'Response',
            message: JSON.stringify(data),
            level: Severity.Info,
        });
        if (data.code === '1') {
            this.props.setAmounts(this.state.amounts);
            this.props.setTransactionID(data.transactionid);
            const WinLog = `Transaction ID: ${data.transactionid}\nInmate ID: ${this.props.inmate.id}\nInmate Name: ${this.props.inmate.inmatename}\nInmate PIN: ${this.props.inmate.pin}\nAmount: ${this.state.amounts.total}\nPayment Type: Cash\nService Type: ${this.props.serviceType}`;
            // @ts-ignore
            KioApp.LogInfo(`${SUCCESS_REPORT_MESSAGE}:\n${WinLog}`);
            await this._sendTransaction();
            this.setState({ loading: false });
            const pushProps = { pathname: '/receipt' };
            if (this.state.maxExceeded) {
                pushProps['state'] = { receiptErrorSource: 'MAX_EXCEEDED' };
            }
            this.props.history.push(pushProps);
        } else {
            this.handleError(data.text);
        }
    };

    clickConfirm = () => {
        if (this.state.amounts.deposit > 0 || this.state.fromModal) {
            this.setState({ loading: true }, this.payAndRedirect);
        } else {
            this.setState({ modal: true });
        }
    };

    onTimeOut = () => {
        if (this.state.amounts.total > 0) {
            this.setState({ loading: true }, this.payAndRedirect);
        } else {
            addBreadcrumb({
                category: 'Event',
                message: `Redirected without payment`,
                level: Severity.Info,
            });
            this.props.history.push('/');
        }
    };

    getAcceptedBills = () => {
        const chosenBills = [];
        const allBills = {
            Bill_1,
            Bill_2,
            Bill_5,
            Bill_10,
            Bill_20,
            Bill_50,
            Bill_100,
        };
        for (const bill in allBills) {
            if (this.props.kiosk.billsAccepted.includes(Number(bill.match(/\d+/)![0]))) {
                chosenBills.push(allBills[bill]);
            }
        }
        const elements = chosenBills.map((bill: string) => {
            return <img key={bill} src={bill} alt="" />;
        });
        return elements;
    };

    modalBody = () => (
        <div id="modal">
            <div id="modal-content">
                <h2 id="modal-title">
                    <FormattedMessage id="modal.title" />
                </h2>
                <h5 id="modal-description">
                    <FormattedMessage id="modal.text" />
                </h5>
            </div>
            <div id="modal-buttons">
                <Button
                    variant="contained"
                    className={this.props.classes.backBtn}
                    onClick={() => this.setState({ modal: false })}
                >
                    <FormattedMessage id="button.back" />
                </Button>
                <Button
                    className={this.props.classes.yesBtn}
                    variant="contained"
                    onClick={() => {
                        this.setState({ fromModal: true }, this.clickConfirm);
                    }}
                >
                    <FormattedMessage id="button.accept" />
                </Button>
            </div>
        </div>
    );
    render() {
        this.getAcceptedBills();
        if (this.state.error) {
            // prettier-ignore
            throw new ((ServerError as unknown) as FunctionConstructor)("server didn't respond");
        }
        if (this.state.loading) {
            return <Loading description="loading.processingPayment" />;
        } else {
            return (
                <div id="whole">
                    <Modal
                        open={this.state.modal}
                        onClose={() => {
                            this.setState({ modal: false });
                        }}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {this.modalBody()}
                    </Modal>
                    <TimeoutWarning
                        timer={this.props.timeouts.cash}
                        onTimeOut={this.onTimeOut}
                        resetType="BILL_ACCEPTOR"
                        billAcceptor={this.props.billAcceptor}
                    />
                    {/* pop up warning if cash acceptor disabled */}
                    {this.state.cashDisabled && <BillAcceptorWarning cashDisabled={this.state.cashDisabled} />}
                    <SnackBar />
                    {/* Insert cash */}
                    <Header screen="cash" />
                    <div id="data">
                        <div className="content">
                            <div className="left">
                                <div className="marginAuto">
                                    <h3 className="total primary">
                                        <FormattedMessage id="cash.accepted" />
                                    </h3>
                                    <div className="bills">{this.getAcceptedBills()}</div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="fees">
                                    <Fees
                                        byCash={true}
                                        total={this.state.amounts.total}
                                        deposit={this.state.amounts.deposit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Buttons
                        props={{
                            clickGoBack: () => this.props.history.goBack(),
                            clickConfirm: this.clickConfirm,
                            confirmText: 'finish',
                            confirm: this.state.amounts.total > 0,
                            back: !this.state.hideBackButton && this.state.amounts.total === 0,
                        }}
                    />
                    <Footer />
                </div>
            );
        }
    }
}

const mapStateToProps = (state: StoreState) => ({
    phoneNumber: state.phoneNumber,
    address: state.address,
    inmate: state.inmate,
    fees: state.fees,
    timeouts: state.timeouts,
    billAcceptor: state.billAcceptor,
    transactionID: state.transactionID,
    toPhoneNumber: state.toPhoneNumber,
    apiKey: state.apiKey,
    kiosk: state.kiosk,
    loadLimits: state.loadLimits,
    serviceType: state.serviceType,
    photoMode: state.photoMode,
    captureImage: state.captureImage,
});

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setAmounts: (amounts: Amounts) => dispatch(setAmounts(amounts)),
    setTransactionID: (transactionID: string) => dispatch(setTransactionID(transactionID)),
    setBillAcceptor: (billAcceptor: BillAcceptor) => dispatch(setBillAcceptor(billAcceptor)),
    turnBillAcceptorOn: () => dispatch(turnBillAcceptorOn()),
    turnBillAcceptorOff: () => dispatch(turnBillAcceptorOff()),
    setErrorText: (errorText: string) => dispatch(setErrorText(errorText)),
});

export default withStyles(btnStyles)(connect(mapStateToProps, mapDispatchFromProps)(Cash));
