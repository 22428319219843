import { BillAcceptor, setBillAcceptor } from '../types';
import cloneDeep from 'lodash/cloneDeep';

import { BILL_ACCEPTOR, COIN_ACCEPTOR } from '../../components/global';

const INITIAL_STATE: BillAcceptor = {
    enable: false,
    status: 0,
    stackedBills: [],
    billsArray: [],
    coinsArray: [],
    stackedTotal: 0,
    currentBill: null,
    backButton: true,
};

const billAcceptorReducer = (state: BillAcceptor = INITIAL_STATE, action: setBillAcceptor) => {
    const copyAcceptor = cloneDeep(action.payload);
    switch (action.type) {
        case 'SET_BILL_ACCEPTOR':
            return action.payload;
        case 'TURN_BILL_ACCEPTOR_ON':
            copyAcceptor.enable = true;
            // @ts-ignore
            KioCurrencyAcceptor.Enable(BILL_ACCEPTOR);
            // @ts-ignore
            KioCurrencyAcceptor.Enable(COIN_ACCEPTOR);
            return copyAcceptor;
        case 'TURN_BILL_ACCEPTOR_OFF':
            copyAcceptor.enable = false;
            // @ts-ignore
            KioCurrencyAcceptor.Disable(BILL_ACCEPTOR);
            // @ts-ignore
            KioCurrencyAcceptor.Disable(COIN_ACCEPTOR);
            return copyAcceptor;
        default:
            return state;
    }
};

export default billAcceptorReducer;
