import React from 'react';
import { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import VKWrapper from './VKWrapper';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => ({
    resize: {
        fontSize: '40px',
    },
    label: {
        fontSize: '30px',
        paddingTop: '5px',
    },
    shrink: {
        paddingTop: '0px',
    },
}));

interface propsDict {
    inputError: boolean;
    handleChange: Function;
    value: string;
    autoFocus?: boolean;
}

const PhoneField = (props: propsDict) => {
    const classes = useStyles();
    const intl = useIntl();
    return (
        <VKWrapper
            autoFocus={props.autoFocus ? true : false}
            screen="phoneNumber"
            id="phoneNumber"
            typeKeyboard="number"
            className={classes.resize}
            style={{ width: '400px' }}
            error={props.inputError}
            helperText={props.inputError ? intl.formatMessage({ id: 'Required' }) : ' '}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                props.handleChange(e);
            }}
            value={props.value}
            variant="outlined"
            label="Phone Number"
            InputProps={{
                endAdornment: <PhoneIcon fontSize="large" className="primary" />,
                classes: {
                    input: classes.resize,
                    notchedOutline: classes.label,
                },
            }}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                    shrink: classes.shrink,
                },
            }}
        />
    );
};

export default PhoneField;
