/* eslint  @typescript-eslint/no-unused-vars: 0 */
import React, { Component } from 'react';
import { LoadLimits, StoreState } from '../redux/types';
import { connect } from 'react-redux';
import { getStringCashFees, getStringCreditFees } from './functions';
import { FormattedMessage } from 'react-intl';

import BookingFees from './feesDirectory/BookingFees';
import PhoneAndCommissaryFees from './feesDirectory/PhoneAndCommissaryFees';

type Props = {
    amounts: any;
    fees: any;
    byCash: any;
    total?: any;
    deposit?: any;
    loadLimits?: LoadLimits;
    serviceType?: any;
};

class Fees extends Component<Props> {
    render() {
        let { total, deposit } = this.props;
        if (!this.props.byCash && total <= 0) {
            total = deposit = '0.00';
        }
        if (!this.props.byCash && total > this.props.loadLimits!.creditMax) {
            total = deposit = '0.00';
        }
        if (!('total' in this.props) && !('deposit' in this.props)) {
            total = this.props.amounts.total;
            deposit = this.props.amounts.deposit;
        }
        const { serviceFee, processingFee } = this.props.byCash ? this.props.fees.cashFees : this.props.fees.creditFees;
        //Get string out of fee numbers
        let { sFee, pFee } = this.props.byCash ? getStringCashFees(0) : getStringCreditFees(0);
        const rar = this.props.byCash ? getStringCashFees(total) : getStringCreditFees(total);
        sFee = rar.sFee;
        pFee = rar.pFee;

        const total_ = total ? parseFloat(total).toFixed(2) : '0.00';
        const deposit_ = deposit ? parseFloat(deposit).toFixed(2) : '0.00';
        const sFee_ = sFee ? sFee : '$0.00';
        const pFee_ = pFee ? pFee : '$0.00';

        return this.props.serviceType === 'booking_service' ? (
            <BookingFees total={total_} />
        ) : (
            <PhoneAndCommissaryFees
                total={total_}
                deposit={deposit_}
                sFee={sFee_}
                pFee={pFee_}
                byCash={this.props.byCash}
            />
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    amounts: state.amounts,
    fees: state.fees,
    loadLimits: state.loadLimits,
    serviceType: state.serviceType,
});

export default connect(mapStateToProps)(Fees);
