import React from 'react';
import './App.scss';

import { Component } from 'react';

import WithRouts from './WithRouts';

class App extends Component {
    render() {
        return (
            <>
                <WithRouts />
            </>
        );
    }
}

export default App;
