import React from 'react';
import { Component } from 'react';
import { Route, RouteComponentProps, BrowserRouter as Router } from 'react-router-dom';

import Start from './pages/start/start.page';
import Search from './pages/search/search.page';
import Confirm from './pages/confirm/confirm.page';
import Funds from './pages/funds/funds.page';
import Phone from './pages/phone/phone.page';
import Address from './pages/address/address.page';
import Method from './pages/method/method.page';
import Amount from './pages/amount/amount.page';
import Receipt from './pages/receipt/receipt.page';
import Email from './pages/email/email.page';
import SMS from './pages/sms/sms.page';
import Sent from './pages/sent/sent.page';
import Cash from './pages/cash/cash.page';
import Swipe from './pages/swipe/swipe.page';
import CCpage from './pages/cc/cc.page';
import ConfirmAddress from './pages/confirm_address/address.page';
import SearchPin from './pages/search/searchPin.page';
import CaptureImage from './pages/capture_image/captureImage.page';
import AcceptImage from './pages/accept_image/acceptImage.page';

import { captureMessage, Severity } from '@sentry/react';
import { connect } from 'react-redux';
import { StoreState } from './redux/types';
import SentryBoundary from './sentry/borders.component';
import Admin from './pages/admin/admin';

import { IntlProvider } from 'react-intl';
import localeData from './locales/languages.json';
import Navbar from './components/navbar/navbar.component';

type Props = {
    [str: string]: any;
    apiKey?: string;
} & RouteComponentProps;

type State = {
    lang: string;
};

class WithRoutes extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            lang: 'en',
        };
    }

    changeLang = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ lang: event.target.value });
    };

    render() {
        let routs;
        if (process.env.NODE_ENV === 'development' || this.props.apiKey) {
            routs = (
                <>
                    <Route path="/confirm/" render={(props) => <Confirm {...props} />} />
                    <Route path="/funds/" render={(props) => <Funds {...props} />} />
                    <Route path="/address/" render={(props) => <Address {...props} />} />
                    <Route path="/phone/" render={(props) => <Phone {...props} />} />
                    <Route path="/method/" render={(props) => <Method {...props} />} />
                    <Route path="/amount/" render={(props) => <Amount {...props} />} />
                    <Route path="/receipt/" render={(props) => <Receipt {...props} />} />
                    <Route path="/email/" render={(props) => <Email {...props} />} />
                    <Route path="/sms/" render={(props) => <SMS {...props} />} />
                    <Route path="/sent/" render={(props) => <Sent {...props} />} />
                    <Route path="/cash/" render={(props) => <Cash {...props} />} />
                    <Route path="/swipe/" render={(props) => <Swipe {...props} />} />
                    <Route path="/cc/" render={(props) => <CCpage {...props} />} />
                    <Route path="/caddress/" render={(props) => <ConfirmAddress {...props} />} />
                    <Route path="/capture/" render={(props) => <CaptureImage {...props} />} />
                    <Route path="/accept/" render={(props) => <AcceptImage {...props} />} />
                </>
            );
        } else {
            routs = (
                <Route
                    path="/"
                    render={(props) => {
                        if (
                            !(
                                props.location.pathname === '/' ||
                                props.location.pathname === '/search/' ||
                                props.location.pathname === '/search-pin/' ||
                                props.location.pathname === '/admin/'
                            )
                        ) {
                            // Log if user tried to go to a page entering path in link bar
                            captureMessage('Attempt to break workflow', Severity.Warning);
                            return <Start {...props} />;
                        }
                    }}
                />
            );
        }

        return (
            <>
                <IntlProvider locale={this.state.lang} messages={localeData[this.state.lang]}>
                    <Router>
                        <SentryBoundary>
                            <Navbar lang={this.state.lang} changeLang={this.changeLang} />

                            <div>
                                <Route exact path="/" render={(props) => <Start {...props} />} />
                                <Route path="/search/" component={Search} />
                                <Route path="/admin/" component={Admin} />
                                <Route path="/search-pin/" component={SearchPin} />
                                {routs}
                            </div>
                        </SentryBoundary>
                    </Router>
                </IntlProvider>
            </>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    apiKey: state.apiKey,
});

export default connect(mapStateToProps)(WithRoutes);
