import { SetTransactionID } from '../types';

const INITIAL_STATE = '';

const transactionIDReducer = (state: string = INITIAL_STATE, action: SetTransactionID) => {
    switch (action.type) {
        case 'SET_TRANSACTIONID':
            return action.payload;

        default:
            return state;
    }
};

export default transactionIDReducer;
