import { SetPhotoMode } from '../types';

const INITIAL_STATE = 0;

const photoModeReducer = (state: number = INITIAL_STATE, action: SetPhotoMode) => {
    switch (action.type) {
        case 'SET_PHOTO_MODE':
            return action.payload;

        default:
            return state;
    }
};

export default photoModeReducer;
