import { setBill, newBill } from '../types';

const INITIAL_STATE: newBill = {
    status: 'IDLE',
    value: 0,
};

const billReducer = (state: newBill = INITIAL_STATE, action: setBill) => {
    switch (action.type) {
        case 'SET_BILL':
            return action.payload;

        default:
            return state;
    }
};

const setNewBill = (bill: newBill) => ({
    type: 'SET_BILL',
    payload: bill,
});

export { billReducer, setNewBill, INITIAL_STATE };
