import { setTimeouts, Timeouts } from '../types';

const INITIAL_STATE: Timeouts = {
    default: 30,
    cash: 60,
    cc: 30,
    receipt: 5,
};

const timeoutsReducer = (state: Timeouts = INITIAL_STATE, action: setTimeouts) => {
    switch (action.type) {
        case 'SET_TIMEOUTS':
            return action.payload;

        default:
            return state;
    }
};

export default timeoutsReducer;
