import { Component } from 'react';
import React from 'react';

import './acceptImage.style.scss';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import TimeoutWarning from '../../components/timeout/timeout.component';
import { addBreadcrumb, Severity } from '@sentry/react';

import { connect } from 'react-redux';

import { StoreState } from '../../redux/types';
import { Dispatch } from 'redux';
import setToPhoneNumber from '../../redux/toPhoneNumber/toPhoneNumber.action';

interface ConfirmProps {
    [keyname: string]: any;
}
interface ConfirmState {
    time: number;
    showSnackbar: boolean;
}

class AcceptImage extends Component<ConfirmProps, ConfirmState> {
    constructor(props: ConfirmProps) {
        super(props);
        this.state = {
            time: 0,
            showSnackbar: false,
        };
    }

    componentDidMount() {
        //Breadcrumbs for Sentry
        addBreadcrumb({
            category: 'Page',
            message: 'Confirm',
            level: Severity.Info,
        });
    }

    clickAccept = () => {
        switch (this.props.serviceType) {
            case 'phone_service':
                switch (this.props.loadType) {
                    case 0: // Both
                        this.props.history.push('/funds/');
                        break;
                    case 1: // Inmate
                        this.props.history.push('/address/');
                        this.props.setToPhoneNumber(false);
                        break;
                    case 2: // Phone number
                        this.props.history.push('/phone/');
                        this.props.setToPhoneNumber(true);
                        break;
                }
                break;
            case 'commissary_service':
                this.props.history.push('/address/');
                break;

            case 'booking_service':
                this.props.history.push('/cash/');
                break;
        }
    };

    render() {
        return (
            <div id="whole">
                <TimeoutWarning />
                <SnackBar />
                <Header screen="photo" />
                <div id="data">
                    <div className="content content-inmate">
                        <div className="marginAuto">
                            <div className="moveUpSmall">
                                <div className="accept-card">
                                    {this.props.captureImage && <img src={this.props.captureImage} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: () => {
                            this.clickAccept();
                        },
                        accept: true,
                        retake: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    inmate: state.inmate,
    timeouts: state.timeouts,
    loadType: state.loadType,
    serviceType: state.serviceType,
    captureImage: state.captureImage,
});

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setToPhoneNumber: (toPhoneNumber: boolean) => dispatch(setToPhoneNumber(toPhoneNumber)),
});

export default connect(mapStateToProps, mapDispatchFromProps)(AcceptImage);
