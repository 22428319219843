import React from 'react';
import Loader from 'react-loader-spinner';

import './loading.style.scss';

import Footer from '../footer/footer.component';
import SnackBar from '../../components/snackbar/snackbar.component';
import { FormattedMessage } from 'react-intl';

interface LoadingProps {
    description?: string;
    noSnackBar?: true;
    noFooter?: boolean;
}

const Loading = (props: LoadingProps) => {
    return (
        <div id="whole">
            {props.noSnackBar ? '' : <SnackBar />}
            <div id="data" style={{ display: 'flex' }}>
                <FormattedMessage id={props.description ? props.description : 'loading.default'}>
                    {(msg: string) => (
                        <div className="errorMessage marginAuto" style={{ display: 'flex' }}>
                            <Loader type="BallTriangle" color="#2979FF" height={100} width={100} />
                            <p style={{ margin: '40px' }}>{msg}...</p>
                        </div>
                    )}
                </FormattedMessage>
            </div>
            {props.noFooter ? '' : <Footer />}
        </div>
    );
};

export default Loading;
