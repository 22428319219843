import { setOutOfOrderMsg } from '../types';

const INITIAL_STATE = '';

const outOfOrderMsgReducer = (state: string = INITIAL_STATE, action: setOutOfOrderMsg) => {
    switch (action.type) {
        case 'SET_OUT_OF_ORDER_MSG':
            return action.payload;

        default:
            return state;
    }
};

export default outOfOrderMsgReducer;
