import React, { ChangeEventHandler } from 'react';
import './navbar.style.scss';
import logo from '../img/logo.png';
import { useSelector } from 'react-redux';
import { StoreState } from '../../redux/types';
import { FormattedMessage } from 'react-intl';
import { MenuItem, TextField } from '@material-ui/core';
import { LANGUAGES } from '../global';
import { getServiceHeader } from './utils';
import useStyles from './styles';

type Props = {
    lang?: 'en' | 'es' | {};
    changeLang?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};

const Navbar = ({ lang, changeLang }: Props) => {
    const service = useSelector((state: StoreState) => state.serviceType);
    const classes = useStyles();
    return (
        <>
            <div className="nav-img">
                <img src={logo} alt="" />
            </div>
            <div className="navbar primary-bgc">
                <h1 className="text">
                    <FormattedMessage id={'nav.' + getServiceHeader(service)?.replaceAll(' ', '')} />
                </h1>
            </div>
            {/* display set to none in styles to disable language select */}
            <div className="nav-lang">
                <TextField
                    id="standard-select-currency"
                    select
                    value={lang}
                    onChange={changeLang}
                    variant="outlined"
                    helperText={<FormattedMessage id="nav.SelectLanguage" />}
                    className={classes.root}
                    InputProps={{
                        classes: {
                            input: classes.input,
                        },
                    }}
                    FormHelperTextProps={{
                        classes: {
                            root: classes.helperText,
                        },
                    }}
                >
                    {LANGUAGES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        </>
    );
};

export default Navbar;
