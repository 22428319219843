import React, { Component, ChangeEvent } from 'react';

import './email.style.scss';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';
import { SERVER_URL } from '../../components/global';
import { getStringCreditFees, getStringCashFees, getDate, getTime } from '../../components/functions';
import TimeoutWarning from '../../components/timeout/timeout.component';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { withStyles } from '@material-ui/core';
import VKWrapper from '../../components/VKWrapper';

import store from '../../redux/store';
import { captureMessage } from '@sentry/react';
import Loading from '../../components/loading/loading.component';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

type EmailProps = {
    [keyname: string]: any;
} & WrappedComponentProps;
interface EmailState {
    email: string;
    inputError: boolean;
    helperText: string;
    clicked: boolean;
    error: boolean;
    loading: boolean;
}

const styles = () => ({
    //style for font size
    resize: {
        fontSize: '30px',
    },
    label: {
        fontSize: '20px',
    },
    state: {
        height: '73px',
    },
});

class Email extends Component<EmailProps, EmailState> {
    constructor(props: EmailProps) {
        super(props);
        this.state = {
            email: '',
            inputError: false,
            helperText: 'invalidEmail',
            clicked: false,
            error: false,
            loading: false,
        };
    }

    sendEmail = () => {
        const { intl } = this.props;
        const http = SERVER_URL + 'send/';
        const state = store.getState();
        const fees = state.byCash ? getStringCashFees(state.amounts.total) : getStringCreditFees(state.amounts.total);
        const serviceType = intl.formatMessage({ id: state.serviceType });
        const loadType = intl.formatMessage({ id: state.toPhoneNumber ? 'phone_number' : 'inmate_account' });
        let data = {
            method: 'EMAIL',
            email: this.state.email.trim(),
            data: {
                facility: state.facility,
                total: state.amounts.total.toFixed(2),
                processingFee: fees.pFee ? fees.pFee : '$0.00',
                serviceFee: fees.sFee ? fees.sFee : '$0.00',
                deposit: state.amounts.deposit.toFixed(2),
                name: state.inmate.inmatename,
                pin: state.inmate.pin,
                phoneNumber: state.phoneNumber,
                transactionID: state.transactionID,
                loadType: loadType,
                time: getTime(),
                date: getDate(),
                serviceType: serviceType,
                ccNumber: state.cc.number ? state.cc.number.replace(/\d(?=\d{4})/g, '*') : false,
                kioskName: state.kioskName,
                locale: intl.locale,
            },
        };
        if (state.serviceType === 'booking_service') {
            // @ts-ignore
            data = { ...data, data: { ...data['data'], isBooking: true } };
        }
        const request = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        };
        fetch(http, request)
            .then((response) => response.json())
            .then((response) => {
                if (response.details === 'success') {
                    this.props.history.push('/sent/');
                } else {
                    captureMessage(response);
                    this.setState({ loading: false, error: true });
                }
            })
            .catch((error) => {
                captureMessage(error);
                this.setState({ loading: false, error: true });
            });
    };

    validateEmail() {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.state.email).toLowerCase());
    }

    clickSend = () => {
        this.setState({ clicked: true });
        if (this.validateEmail()) {
            //Sending email
            this.setState({ loading: true });
            this.sendEmail();
        } else {
            this.setState({ inputError: true });
        }
    };

    handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const el = e.target as HTMLInputElement;

        this.setState({ email: el.value.trimStart().slice(0, 255) }, () => {
            this.setState({ inputError: !this.validateEmail() });
        });
    };

    render() {
        //Input error
        const error = this.state.inputError && this.state.clicked;
        if (this.state.loading) {
            return <Loading />;
        }
        if (this.state.error) {
            return <Redirect to={{ pathname: '/receipt/', state: { receiptErrorSource: 'EMAIL' } }} />;
        }
        return (
            <div id="whole">
                <TimeoutWarning />
                <Header screen="email" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div className="marginAuto">
                                <div className="email-address">
                                    <VKWrapper
                                        id="email"
                                        screen="email"
                                        autoFocus
                                        error={error}
                                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            this.handleChange(e);
                                        }}
                                        helperText={error ? this.state.helperText : ' '}
                                        value={this.state.email}
                                        label="Email"
                                        variant="outlined"
                                        fullWidth={true}
                                        InputProps={{
                                            endAdornment: <AlternateEmailIcon fontSize="large" className="primary" />,
                                            classes: {
                                                input: this.props.classes.resize,
                                                notchedOutline: this.props.classes.label,
                                            },
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: this.props.classes.label,
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="confirm-send">
                                <h3 className="primary">
                                    <FormattedMessage id="email.helper" />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickSend,
                        confirm: true,
                        confirmText: 'send',
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles)(injectIntl(Email));
