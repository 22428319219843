import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CloseKeyboard from './useFunction';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    wrapper: {
        width: '100%',
        margin: '11px 0px',
    },
    btns: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    btnPrimary: {
        backgroundColor: '#25408F',
        marginLeft: '0px',
        marginRight: '100px',
        minWidth: '270px',
        height: '70px',
        color: 'white',
        fontSize: '32px',
        '&:first-child': {
            marginLeft: '100px',
            marginRight: '0px',
        },
        '&:hover': {
            backgroundColor: 'rgb(21, 37, 85)',
        },
    },
    btnSecondary: {
        backgroundColor: 'rgba(37, 64, 143, 0.1)',
        marginLeft: '0px',
        marginRight: '100px',
        minWidth: '270px',
        height: '70px',
        color: '#25408F',
        fontSize: '32px',
        '&:first-child': {
            marginLeft: '100px',
            marginRight: '0px',
        },
        '&:hover': {
            backgroundColor: 'rgba(37, 64, 143, 0.2)',
        },
    },
}));

interface ButtonProps {
    back?: boolean;
    backLink?: string;
    confirm?: boolean;
    clickConfirm?: Function;
    confirmText?: string;
    backText?: string;
    search?: boolean;
    searchText?: string;
    capture?: boolean;
    captureText?: string;
    retake?: boolean;
    retakeText?: string;
    accept?: boolean;
    acceptText?: string;
    clickGoBack?: Function;
}

interface EntitiesPageProps extends RouteComponentProps<any> {
    props: ButtonProps;
}

const Buttons = (params: EntitiesPageProps) => {
    const goBack = () => {
        //Check if in KioWare if so - close keyboard
        if (/KioWare/i.test(navigator.userAgent)) {
            // @ts-ignore
            KioApp.CloseKeyboard();
            CloseKeyboard();
        }
        params.props.clickGoBack!();
    };

    const goForward = () => {
        //Check if in KioWare if so - close keyboard
        if (/KioWare/i.test(navigator.userAgent)) {
            // @ts-ignore
            KioApp.CloseKeyboard();
            CloseKeyboard();
        }
        params.props.clickConfirm!();
    };

    const classes = useStyles();
    let backId;
    if (!params.props.confirmText) {
        params.props.confirmText = 'button.next';
    }
    if (!params.props.backText) {
        params.props.backText = 'button.back';
    }
    if (!params.props.back) {
        backId = 'hide';
    }
    if (params.props.retake) {
        backId = 'no-hide';
    }
    if (!params.props.searchText) {
        params.props.searchText = 'button.search';
    }
    if (!params.props.captureText) {
        params.props.captureText = 'button.capture';
    }
    if (!params.props.acceptText) {
        params.props.acceptText = 'button.accept';
    }
    if (!params.props.retakeText) {
        params.props.retakeText = 'button.retake';
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.root + ' ' + classes.btns}>
                <Button id={backId} className={classes.btnSecondary} variant="contained" onClick={() => goBack()}>
                    {params.props.back ? (
                        <FormattedMessage id={params.props.backText} />
                    ) : (
                        <FormattedMessage id={params.props.retakeText} />
                    )}
                </Button>
                {params.props.confirm && (
                    <Button
                        className={classes.btnPrimary}
                        onClick={() => {
                            goForward();
                        }}
                        variant="contained"
                    >
                        <FormattedMessage id={params.props.confirmText} />
                    </Button>
                )}
                {params.props.search && (
                    <Button
                        className={classes.btnPrimary}
                        onClick={() => {
                            goForward();
                        }}
                        variant="contained"
                    >
                        <FormattedMessage id={params.props.searchText} />
                    </Button>
                )}
                {params.props.capture && (
                    <Button
                        className={classes.btnPrimary}
                        onClick={() => {
                            goForward();
                        }}
                        variant="contained"
                    >
                        <FormattedMessage id={params.props.captureText} />
                    </Button>
                )}
                {params.props.accept && (
                    <Button
                        className={classes.btnPrimary}
                        onClick={() => {
                            goForward();
                        }}
                        variant="contained"
                    >
                        <FormattedMessage id={params.props.acceptText} />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default withRouter(Buttons);
