import React, { Component } from 'react';

import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/types';
import Warning from '../../components/toast';
import { setShowKeyboard } from '../../redux/showKeyboard/showKeyboard.action';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import cloneDeep from 'lodash/cloneDeep';

import ReactTimeout from 'react-timeout';
import { ReactTimeoutProps } from 'react-timeout';

import { addBreadcrumb, Severity } from '@sentry/react';

import './timeout.style.scss';
import { SnackbarCloseReason } from '@material-ui/core';

import { kwPresent } from '../functions';

import { setIsTimedOut } from '../../redux/timeout/timeout.action';
import { FormattedMessage } from 'react-intl';

type Props = {
    timeout?: any;
    timeouts: any;
    billAcceptor?: any;
    text?: string;
    timer?: number;
    startAt?: number;
    setShowKeyboard: Function;
    onTimeOut?: Function;
    setIsTimedOut: Function;
    resetType?: string;
} & RouteComponentProps &
    ReactTimeoutProps;

interface timeoutState {
    currentCount: number;
    timer: number;
    intervalId: any;
    isTimedOut: boolean;
    prevProp?: any;
    calledTimeOutFunction: boolean;
    timeout: any;
}

class TimeoutWarning extends Component<Props, timeoutState> {
    idleTimer: any;
    _isMounted = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentCount: this.props.timer ? this.props.timer : this.props.timeouts.default,
            intervalId: null,
            timer: this.props.timer ? this.props.timer : this.props.timeouts.default,
            isTimedOut: this.props.timeout.isTimedOut,
            prevProp: null,
            calledTimeOutFunction: false,
            timeout: null,
        };

        this.idleTimer = React.createRef();
        this._onActive = this._onActive.bind(this);
        this._onIdle = this._onIdle.bind(this);
    }

    _onActive() {
        if (this.props.timeout.isTimedOut) {
            this.props.setIsTimedOut(false);
            this.Reset();
        }
    }

    _onIdle() {
        this.props.setIsTimedOut(true);
        this.Start();
    }

    componentDidMount() {
        this._isMounted = true;
        switch (this.props.resetType) {
            case 'BILL_ACCEPTOR':
                this.setState({ prevProp: cloneDeep(this.props.billAcceptor) });
                break;
            default:
                break;
        }
    }

    componentDidUpdate() {
        // Reset timer if redux state (billAcceptor) changed, compairing it with prevProp recorded in local state
        switch (this.props.resetType) {
            case 'BILL_ACCEPTOR':
                if (JSON.stringify(this.props.billAcceptor) !== JSON.stringify(this.state.prevProp)) {
                    this._onActive();
                    this.idleTimer.reset();
                    this.setState({ prevProp: cloneDeep(this.props.billAcceptor) });
                }
                break;

            default:
                break;
        }
    }

    componentWillUnmount() {
        // clear the interval
        this.Reset();
        this._isMounted = false;
        clearTimeout(this.state.timeout);
    }

    Reset = () => {
        clearInterval(this.state.intervalId);
        this.setState({
            timeout: setTimeout(() => {
                if (this._isMounted) this.setState({ currentCount: this.state.timer });
            }, 200),
        });
    };
    Start = () => {
        addBreadcrumb({
            category: 'Timeout started',
            level: Severity.Info,
        });
        const intervalId = setInterval(() => {
            this.timer();
        }, 1000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });
    };

    timer = () => {
        // setState method is used to update the state
        if (this.state.currentCount > 0) {
            this.setState({ currentCount: this.state.currentCount - 1 });
        } else {
            if (!this.state.calledTimeOutFunction) {
                this.setState({ calledTimeOutFunction: true }, this.onTimeOut);
            }
        }
    };

    onTimeOut = () => {
        addBreadcrumb({
            category: 'Timedout',
            level: Severity.Info,
        });
        //close keyboard
        if (kwPresent) {
            this.props.setShowKeyboard(false);
            (document as any).KioApp.CloseKeyboard();
        }
        //reset action -> Click on warning
        // if (this.idleTimer){
        //   this.idleTimer.reset()
        // }
        this.props.setIsTimedOut(false);
        //by default go to first page
        if (!this.props.onTimeOut) this.props.history.push('/');
        //call function from parent if assigned
        else {
            this.props.onTimeOut();
        }
    };

    onClose = (event?: React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.Reset();
    };

    render() {
        return (
            <div>
                <IdleTimer
                    ref={(ref: any) => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this._onActive}
                    onIdle={this._onIdle}
                    debounce={250}
                    timeout={this.props.startAt ? this.props.startAt : this.props.timeout.timeout}
                />
                <FormattedMessage id={`${this.props.text ? this.props.text : 'timeout.default'}`}>
                    {(msg: string) => (
                        <Warning
                            open={this.props.timeout.isTimedOut}
                            onClose={this.onClose}
                            text={msg + ' ' + this.state.currentCount + 's'}
                        />
                    )}
                </FormattedMessage>
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    timeouts: state.timeouts,
    timeout: state.timeout,
    // billAcceptor: state.billAcceptor,
});

const mapDispatchFromProps = (dispatch: Function) => ({
    setShowKeyboard: (showKeyboard: boolean) => dispatch(setShowKeyboard(showKeyboard)),
    setIsTimedOut: (isTimedOut: boolean) => dispatch(setIsTimedOut(isTimedOut)),
});

export default withRouter(connect(mapStateToProps, mapDispatchFromProps)(ReactTimeout(TimeoutWarning)));
