import { SetCC, CC } from '../types';

const INITIAL_STATE: CC = {
    number: '',
    firstName: '',
    lastName: '',
    cvc: '',
    date: '',
};

const ccReducer = (state: CC = INITIAL_STATE, action: SetCC) => {
    switch (action.type) {
        case 'SET_CC':
            return action.payload;

        default:
            return state;
    }
};

export default ccReducer;
