import { SetNewFees, Fees } from '../types';

const INITIAL_STATE: Fees = {
    // type 0: flat, 1: percentage
    cashFees: {
        serviceFee: {
            amount: '2.00',
            isFlat: true,
        },
        processingFee: {
            amount: null,
            isFlat: null,
        },
    },
    creditFees: {
        serviceFee: {
            amount: '1.00',
            isFlat: true,
        },
        processingFee: {
            amount: '2.00',
            isFlat: false,
        },
    },
};

const feesReducer = (state: Fees = INITIAL_STATE, action: SetNewFees) => {
    switch (action.type) {
        case 'SET_FEES':
            return action.payload;

        default:
            return state;
    }
};

export default feesReducer;
