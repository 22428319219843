import React, { Component } from 'react';

import './receipt.style.scss';

import CloseKeyboard from '../../components/useFunction';

import Footer from '../../components/footer/footer.component';
import Buttons from '../../components/buttons';
import Header from '../../components/header';
import TimeoutWarning from '../../components/timeout/timeout.component';

import Button from '@material-ui/core/Button';
import ReceiptTable from '../../components/receipt/receipt.component';
import store from '../../redux/store';
import { getNumberCreditFees, getNumberCashFees, getDate } from '../../components/functions';
import { getTime, getPrinterStatusErrorCodes } from '../../components/functions';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { StoreState } from '../../redux/types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RECEIPT_PRINTER } from '../../components/global';

const SMS_ERROR = 'receipt.error.sms';
const EMAIL_ERROR = 'receipt.error.email';
const PRINTER_ERROR = 'receipt.error.printer';
const PRINTER_DISABLED = 'receipt.error.printerDisabled';
const MAX_EXCEEDED = 'receipt.error.max';

interface ReceiptState {
    receiptError: boolean;
    receiptErrorSource: string;
    timeout: any;
    printerDisabled: boolean;
}

type ReceiptProps = {
    [keyname: string]: any;
} & WrappedComponentProps;

class Receipt extends Component<ReceiptProps, ReceiptState> {
    createData(name: string, value: string) {
        return { name, value };
    }

    constructor(props: ReceiptProps) {
        super(props);
        this.state = {
            receiptError: false,
            receiptErrorSource: '',
            timeout: null,
            printerDisabled: false,
        };
    }

    componentWillUnmount = () => {
        clearTimeout(this.state.timeout);
    };

    componentDidMount = () => {
        if (/KioWare/i.test(navigator.userAgent)) {
            CloseKeyboard();
        }
        if (this.props.location.state) {
            this.setState({ receiptError: true, receiptErrorSource: this.props.location.state.receiptErrorSource });
        }
        // printer device status of 0 means OK
        // printer device status of 4 means low paper
        // check printer status, if 0 or 4 printer is good to print
        // if NOT 0 or 4 there is something wrong with printer, disable print button and create sentry message
        // List of printer status codes https://m.kioware.com/api/windows/8.15/javascript/kiodevicestatus
        // @ts-ignore
        const printerDeviceStatus = KioDevice.GetStatus(RECEIPT_PRINTER);
        if (printerDeviceStatus === 0 || printerDeviceStatus === 4) {
            this.setState({ printerDisabled: false });
        } else {
            this.setState({ printerDisabled: true });
            if (this.props.kiosk.printerEnabled) {
                getPrinterStatusErrorCodes(printerDeviceStatus);
            }
        }
    };

    printButton = () => {
        if (this.state.printerDisabled || !this.props.kiosk.printerEnabled) {
            return (
                <>
                    <Button id="disabled" className="btn-recpt" disabled={true} variant="contained">
                        <FormattedMessage id="receipt.print" />
                    </Button>
                    <h4 style={{ textAlign: 'center' }}>
                        <FormattedMessage id="method.disabled" />
                    </h4>
                </>
            );
        } else {
            return (
                <Button
                    id="btn-primary"
                    className="btn-recpt"
                    onClick={() => {
                        this.btnClick('print');
                    }}
                    variant="contained"
                >
                    <FormattedMessage id="receipt.print" />
                </Button>
            );
        }
    };

    btnClick(btn: string) {
        const { formatMessage } = this.props.intl;
        switch (btn) {
            case 'no':
                this.props.history.push('/');
                break;
            case 'sms':
                this.props.history.push('/sms/');
                break;
            case 'email':
                this.props.history.push('/email/');
                break;
            case 'print':
                if (this.props.kiosk.printerEnabled) {
                    try {
                        const esc = '\x1B'; //ESC byte in hex notation
                        const newLine = '\x0A'; //LF byte in hex notation
                        const fontSize = esc + '\x21'; // Add number to make it legit 00-99
                        const fontAlign = esc + '\x61'; //  00 - left 01 - center 02 - right
                        const fontFamiliy = esc + '\x66'; // 00 - A, 01 - B
                        const bold = esc + '\x45'; // \x01 on \x00 off
                        const cut = '\x1c\xc0\x34';

                        const date = getDate();
                        const time = getTime();
                        //today.getHours() + ':' + String(today.getMinutes()).padStart(2, '0') + ':' + today.getSeconds();
                        const state = store.getState();
                        let fees: { sFee?: any; pFee?: any };
                        if (state.byCash) {
                            fees = getNumberCashFees(state.amounts.total);
                        } else {
                            fees = getNumberCreditFees(state.amounts.total);
                        }
                        let Fees = '';
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        let length = 0;
                        let subtotal = state.amounts.total;
                        let regex;
                        if (fees.sFee) {
                            subtotal -= fees.sFee;
                            length = fees.sFee.toFixed(2).length;
                            regex = new RegExp(`\\s{${length}}$`);
                            Fees +=
                                `${formatMessage({ id: 'receipt.paper.service' })}       `.replace(
                                    regex,
                                    '$' + fees.sFee.toFixed(2),
                                ) + `${newLine}`;
                        } else {
                            Fees += `${formatMessage({ id: 'receipt.paper.service' })}   $0.00${newLine}`;
                        }
                        if (fees.pFee) {
                            subtotal -= fees.pFee;
                            length = fees.pFee.toFixed(2).length;
                            regex = new RegExp(`\\s{${length}}$`);
                            Fees +=
                                `${formatMessage({ id: 'receipt.paper.process' })}       `.replace(
                                    regex,
                                    '$' + fees.pFee.toFixed(2),
                                ) + `${newLine}`;
                        } else {
                            Fees += `${formatMessage({ id: 'receipt.paper.process' })}   $0.00${newLine}`;
                        }

                        let cmds = `${esc}\x4a\x02`;
                        cmds += `${fontSize}\x13`;
                        cmds += `${fontAlign}\x01`;
                        cmds += formatMessage({ id: 'receipt.paper.' + state.serviceType });
                        cmds += `${newLine}${newLine}`;
                        cmds += `${fontSize}\x00`;
                        cmds += `${state.facility},`;
                        cmds += `${newLine}`;
                        cmds += formatMessage({ id: 'receipt.paper.date' }, { date: date, time: time });
                        cmds += `${newLine}${newLine}`;
                        cmds += `\x1d\x4c\x05\x05`; //Set left margin
                        cmds += `${fontSize}\x01`;
                        cmds += `${fontAlign}\x00`;
                        cmds += `${fontFamiliy}\x00`;
                        switch (state.serviceType) {
                            case 'phone_service':
                                cmds += formatMessage({ id: 'receipt.paper.st.phone_service' });
                                cmds += `${newLine}`;
                                cmds += state.toPhoneNumber
                                    ? formatMessage({ id: 'receipt.paper.lt.phone' })
                                    : formatMessage({ id: 'receipt.paper.lt.inmate' });
                                cmds += `${newLine}`;
                                break;
                            case 'commissary_service':
                                cmds += formatMessage({ id: 'receipt.paper.st.commissary_service' });
                                cmds += `${newLine}`;
                                break;
                            case 'booking_service':
                                cmds += formatMessage({ id: 'receipt.paper.st.booking_service' });
                                cmds += `${newLine}`;
                                break;
                        }
                        cmds += `${formatMessage({ id: 'receipt.inmate' })}: ${state.inmate.inmatename}`;
                        cmds += `${newLine}`;
                        cmds += `PIN: ${state.inmate.pin}`;
                        cmds += `${newLine}`;

                        if (this.props.serviceType !== 'booking_service') {
                            cmds += `${formatMessage({ id: 'receipt.phone' })}: ${state.phoneNumber}`;
                            cmds += `${newLine}`;
                        }

                        cmds += formatMessage(
                            { id: 'receipt.paper.pt' },
                            {
                                pt: state.byCash
                                    ? formatMessage({ id: 'method.cash' })
                                    : formatMessage({ id: 'method.credit' }),
                            },
                        );
                        if (!state.byCash) {
                            cmds += `${newLine}`;
                            cmds += `${formatMessage({ id: 'receipt.paper.card' })} ${state.cc.number.replace(
                                /\d(?=\d{4})/g,
                                '*',
                            )}`;
                        }
                        cmds += `${newLine}${newLine}`;
                        regex = new RegExp(`\\s{${subtotal.toFixed(2).length}}$`);
                        cmds += `${bold}\x01`;
                        cmds +=
                            `${formatMessage({ id: 'receipt.paper.deposit' })}       `.replace(
                                regex,
                                '$' + subtotal.toFixed(2),
                            ) + `${newLine}`;
                        cmds += `${bold}\x00`;
                        {
                            this.props.serviceType !== 'booking_service' && (cmds += Fees);
                        }
                        regex = new RegExp(`\\s{${state.amounts.total.toFixed(2).length}}$`);
                        {
                            this.props.serviceType !== 'booking_service' &&
                                (cmds +=
                                    `${formatMessage({ id: 'receipt.paper.total' })}                     `.replace(
                                        regex,
                                        '$' + state.amounts.total.toFixed(2),
                                    ) + `${newLine}`);
                        }
                        cmds += `${newLine}`;
                        cmds += `${formatMessage({ id: 'receipt.kiosk' })}: ${state.kioskName}`;
                        cmds += `${newLine}`;
                        cmds += `${formatMessage({ id: 'receipt.trans' })}: ${state.transactionID}`;
                        cmds += `${newLine}${newLine}`;
                        cmds += `${fontAlign}\x01`;
                        cmds += `${fontFamiliy}\x01`;
                        cmds += `www.ncic.com`;
                        cmds += `${newLine}${newLine}`;
                        cmds += `${cut}`;
                        // TODO: REMOVE
                        // console.log(cmds);
                        // @ts-ignore
                        KioRawWinPrinter.Print('printer', cmds);
                        this.props.history.push('/sent/');
                    } catch {
                        this.setState({ receiptError: false }, () => {
                            this.setState({
                                timeout: setTimeout(() => {
                                    this.setState({ receiptError: true, receiptErrorSource: 'PRINTER' });
                                }, 100),
                            });
                        });
                    }
                } else {
                    this.setState({ receiptError: false }, () => {
                        this.setState({
                            timeout: setTimeout(() => {
                                this.setState({ receiptError: true, receiptErrorSource: 'PRINTER_DISABLED' });
                            }, 100),
                        });
                    });
                }
                break;
        }
    }

    render() {
        let ErrorMessage = '';
        switch (this.state.receiptErrorSource) {
            case 'EMAIL':
                ErrorMessage = EMAIL_ERROR;
                break;
            case 'SMS':
                ErrorMessage = SMS_ERROR;
                break;
            case 'PRINTER':
                ErrorMessage = PRINTER_ERROR;
                break;
            case 'PRINTER_DISABLED':
                ErrorMessage = PRINTER_DISABLED;
                break;
            case 'MAX_EXCEEDED':
                ErrorMessage = MAX_EXCEEDED;
                break;
        }
        return (
            <div id="whole">
                <TimeoutWarning />
                <Snackbar
                    style={{ marginTop: '200px' }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.receiptError}
                    onClose={() => this.setState({ receiptError: false })}
                >
                    <Alert
                        onClose={() => this.setState({ receiptError: false })}
                        severity={ErrorMessage === MAX_EXCEEDED ? 'warning' : 'error'}
                    >
                        <FormattedMessage id={ErrorMessage} />
                    </Alert>
                </Snackbar>
                {/* Order complete */}
                <Header screen="receipt" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div id="receipt-table">
                                <ReceiptTable />
                            </div>
                        </div>
                        <div className="right">
                            <div className="fees marginAuto" id="receipt-table">
                                <div className="receipt-card">
                                    <div className="receipt-btns">
                                        <h4 className="primary primary-h4" style={{ width: '100%' }}>
                                            <FormattedMessage id="receipt.need" />
                                        </h4>
                                        {this.props.serviceType !== 'booking_service' && (
                                            <Button
                                                id="btn-primary"
                                                className="btn-recpt"
                                                onClick={() => {
                                                    this.btnClick('sms');
                                                }}
                                                variant="contained"
                                            >
                                                <FormattedMessage id="receipt.sms" />
                                            </Button>
                                        )}
                                        {this.props.serviceType !== 'booking_service' && (
                                            <Button
                                                id="btn-primary"
                                                className="btn-recpt"
                                                onClick={() => {
                                                    this.btnClick('email');
                                                }}
                                                variant="contained"
                                            >
                                                <FormattedMessage id="receipt.email" />
                                            </Button>
                                        )}
                                        {this.printButton()}
                                        <Button
                                            id="btn-primary"
                                            className="btn-recpt"
                                            onClick={() => {
                                                this.btnClick('no');
                                            }}
                                            variant="contained"
                                        >
                                            <FormattedMessage id="receipt.noReceipt" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons props={{}} />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    kiosk: state.kiosk,
    serviceType: state.serviceType,
});

export default connect(mapStateToProps)(injectIntl(Receipt));
