import React, { Component } from 'react';

import './method.style.scss';

import Footer from '../../components/footer/footer.component';

import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import setByCash from '../../redux/byCash/byCash.action';
import TimeoutWarning from '../../components/timeout/timeout.component';
import { getStringCreditFees, getStringCashFees } from '../../components/functions';

import { Dispatch } from 'redux';
import { StoreState } from '../../redux/types';
import { addBreadcrumb, Severity } from '@sentry/react';
import { FormattedMessage } from 'react-intl';
import { BILL_ACCEPTOR } from '../../components/global';

const ButtonPressable = withStyles({
    root: {
        width: '480px',
        height: '80px',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 40,
        padding: '6px 12px',
        //   border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: 'rgba(41, 120, 255, 0.12)',
        color: '#0044C2',
        borderColor: '#007bff',

        '&:hover': {
            backgroundColor: 'rgba(41, 120, 255, 0.30)',
        },
    },
})(Button);

interface MethodProps {
    [keyname: string]: any;
}

interface MethodState {
    nextPage: string;
    loaded: boolean;
    cashDisabled: boolean;
    creditDisabled: boolean;
    goStartScreen: boolean;
}

class Method extends Component<MethodProps, MethodState> {
    constructor(props: any) {
        super(props);
        this.state = {
            nextPage: this.props.byCash ? '/cash/' : '/amount/',
            loaded: false,
            cashDisabled: true,
            creditDisabled: true,
            goStartScreen: true,
        };
    }

    handleToggle = (btnName: string) => {
        this.setState({ nextPage: btnName });
    };

    getState = (btnName: string) => {
        if (btnName === this.state.nextPage) {
            return 'toggled-fees';
        }
    };

    componentDidMount = () => {
        const cashDisabled = this.getBillAcceptorState();
        const creditDisabled = this.getCreditCardState();
        if (cashDisabled) {
            if (creditDisabled) {
                this.setState({ goStartScreen: true });
            } else {
                this.props.setByCash(false);
                this.setState({ nextPage: '/amount/', goStartScreen: false, creditDisabled: false });
            }
        } else {
            this.setState({ cashDisabled: false, goStartScreen: false, nextPage: '/cash/' });
            if (!creditDisabled) {
                this.setState({ creditDisabled: false, nextPage: this.props.byCash ? '/cash/' : '/amount/' });
            }
        }
    };

    clickConfirm = () => {
        if (this.state.goStartScreen) {
            this.props.history.push('/');
        } else {
            this.props.setByCash(this.state.nextPage === '/cash/');
            this.props.history.push(this.state.nextPage);
        }
    };

    getBillAcceptorState = () => {
        let disabled = true;
        if (this.props.kiosk.cashEnabled) {
            try {
                // @ts-ignore
                disabled = !(KioDevice.GetStatus(BILL_ACCEPTOR) === 0);
            } catch (err) {
                addBreadcrumb({
                    category: 'BILL ACCEPTOR',
                    message: `Bill acceptor not working: ${err}`,
                    level: Severity.Error,
                });
            }
        }
        return disabled;
    };

    getCreditCardState = () => {
        let disabled = true;
        if (this.props.kiosk.creditEnabled) {
            disabled = false;
        }
        return disabled;
    };

    cashButton = () => {
        if (this.state.cashDisabled) {
            return (
                <>
                    <ButtonPressable id="disabled" disabled={true} value="/cash/">
                        <FormattedMessage id="method.cash" />
                    </ButtonPressable>
                    <h4 style={{ textAlign: 'center' }}>
                        <FormattedMessage id="method.disabled" />
                    </h4>
                </>
            );
        } else {
            return (
                <ButtonPressable
                    id={this.getState('/cash/')}
                    value="/cash/"
                    onClick={() => this.handleToggle('/cash/')}
                >
                    <FormattedMessage id="method.cash" />
                </ButtonPressable>
            );
        }
    };
    creditButton = () => {
        if (this.state.creditDisabled) {
            return (
                <>
                    <ButtonPressable id="disabled" disabled={true} value="/amount/">
                        <FormattedMessage id="method.credit" />
                    </ButtonPressable>
                    <h4 style={{ textAlign: 'center' }}>
                        <FormattedMessage id="method.disabled" />
                    </h4>
                </>
            );
        } else {
            return (
                <ButtonPressable
                    id={this.getState('/amount/')}
                    value="/amount/"
                    onClick={() => this.handleToggle('/amount/')}
                >
                    <FormattedMessage id="method.credit" />
                </ButtonPressable>
            );
        }
    };

    render() {
        //Get string representation of fees
        const cashFees = getStringCashFees(0);
        const creditFees = getStringCreditFees(0);
        return (
            <div id="whole">
                <TimeoutWarning />
                <SnackBar />
                <Header screen="method" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div className="content-funds">
                                <div className="moveUpSmall">
                                    <div className="cash-btn account">{this.cashButton()}</div>
                                    <div className="credit-btn">{this.creditButton()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="fees">
                                <div className="cash" id={this.getState('/cash/')}>
                                    <h3 className="primary">
                                        <FormattedMessage id="fee.header" />
                                    </h3>
                                    <div className="fees-table">
                                        <div className="left-fees">
                                            <h5>
                                                <FormattedMessage id="fee.service" />:
                                            </h5>
                                            <h5>
                                                <FormattedMessage id="fee.processing" />:
                                            </h5>
                                        </div>
                                        <div className="right-fees">
                                            <h5>{cashFees.sFee ? cashFees.sFee : '$0.00'}</h5>
                                            <h5>{cashFees.pFee ? cashFees.pFee : '$0.00'}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="credit" id={this.getState('/amount/')}>
                                    <h3 className="primary">
                                        <FormattedMessage id="fee.header" />
                                    </h3>
                                    <div className="fees-table">
                                        <div className="left-fees">
                                            <h5>
                                                <FormattedMessage id="fee.service" />:
                                            </h5>
                                            <h5>
                                                <FormattedMessage id="fee.processing" />:
                                            </h5>
                                        </div>
                                        <div className="right-fees">
                                            <h5>{creditFees.sFee ? creditFees.sFee : '$0.00'}</h5>
                                            <h5>{creditFees.pFee ? creditFees.pFee : '$0.00'}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickConfirm,
                        confirm: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    fees: state.fees,
    byCash: state.byCash,
    kiosk: state.kiosk,
});

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setByCash: (byCash: boolean) => dispatch(setByCash(byCash)),
});

export default connect(mapStateToProps, mapDispatchFromProps)(Method);
