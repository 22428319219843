import { CollectionData } from './types';
// "{\"facility\": \"Clark County Jail\", \"bills_count\": 0, \"total\": \"0.00\", \"pk\": 69, \"kioskName\": \"default\", \"agent\": \"Andrew Savala\", \"email\": \"a.abovyan@bk.ru\", \"dt\": \"11/25/2020 at 04:56 PM\", \"count_1\": 0, \"count_2\": 0, \"count_5\": 0, \"count_10\": 0, \"count_20\": 0, \"count_50\": 0, \"count_100\": 0}"

export default function printReceipt(data: CollectionData) {
    try {
        const esc = '\x1B'; //ESC byte in hex notation
        const newLine = '\x0A'; //LF byte in hex notation
        const fontSize = esc + '\x21'; // Add number to make it legit 00-99
        const fontAlign = esc + '\x61'; //  00 - left 01 - center 02 - right
        const fontFamiliy = esc + '\x66'; // 00 - A, 01 - B
        const bold = esc + '\x45'; // \x01 on \x00 off
        const cut = '\x1c\xc0\x34';
        const cent = '\x9b';
        let regex;

        let cmds = `${esc}\x4a\x02`;
        cmds += `${fontSize}\x13`;
        cmds += `${fontAlign}\x01`;
        cmds += `NCIC Collection Receipt`;
        cmds += `${newLine}${newLine}`;
        cmds += `${fontSize}\x00`;
        cmds += `${data.facility},`;
        cmds += `${newLine}`;
        cmds += `${data.dt}`;
        cmds += `${newLine}${newLine}`;

        cmds += `\x1d\x4c\x05\x05`; //Set left margin
        cmds += `${fontSize}\x01`;
        cmds += `${fontAlign}\x00`;
        cmds += `${fontFamiliy}\x00`;
        cmds += `Agent: ${data.agent}`;
        cmds += `${newLine}`;
        cmds += `Username: ${data.agent__username}`;
        cmds += `${newLine}`;
        cmds += `Email: ${data.email}`;
        cmds += `${newLine}${newLine}`;
        // Bills
        regex = new RegExp(`\\s{${String(data.count_1).length}}$`);
        cmds += `$1:       `.replace(regex, String(data.count_1));
        cmds += '  ';
        regex = new RegExp(`\\s{${String(data.count_2).length}}$`);
        cmds += `$2:       `.replace(regex, String(data.count_2));
        cmds += `${newLine}`;

        regex = new RegExp(`\\s{${String(data.count_5).length}}$`);
        cmds += `$5:       `.replace(regex, String(data.count_5));
        cmds += '  ';
        regex = new RegExp(`\\s{${String(data.count_10).length}}$`);
        cmds += `$10:      `.replace(regex, String(data.count_10));
        cmds += `${newLine}`;

        regex = new RegExp(`\\s{${String(data.count_20).length}}$`);
        cmds += `$20:      `.replace(regex, String(data.count_20));
        cmds += '  ';
        regex = new RegExp(`\\s{${String(data.count_50).length}}$`);
        cmds += `$50:      `.replace(regex, String(data.count_50));
        cmds += `${newLine}`;

        regex = new RegExp(`\\s{${String(data.count_100).length}}$`);
        cmds += `$100:     `.replace(regex, String(data.count_100));
        cmds += `${newLine}`;
        cmds += `${newLine}`;
        // Coin
        regex = new RegExp(`\\s{${String(data.coin_01).length}}$`);
        cmds += `1${cent}:       `.replace(regex, String(data.coin_01));
        cmds += '  ';
        regex = new RegExp(`\\s{${String(data.coin_05).length}}$`);
        cmds += `5${cent}:      `.replace(regex, String(data.coin_05));
        cmds += `${newLine}`;

        regex = new RegExp(`\\s{${String(data.coin_10).length}}$`);
        cmds += `10${cent}:      `.replace(regex, String(data.coin_10));
        cmds += '  ';
        regex = new RegExp(`\\s{${String(data.coin_25).length}}$`);
        cmds += `25${cent}:     `.replace(regex, String(data.coin_25));
        cmds += `${newLine}`;

        regex = new RegExp(`\\s{${String(data.coin_50).length}}$`);
        cmds += `50${cent}:      `.replace(regex, String(data.coin_50));
        cmds += '  ';
        regex = new RegExp(`\\s{${String(data.coin_1).length}}$`);
        cmds += `$1:      `.replace(regex, String(data.coin_1));
        cmds += `${newLine}`;

        cmds += `${newLine}`;
        cmds += `${newLine}`;
        // FOOTER
        cmds += `COIN COUNT: ${data.coin_count}`;
        cmds += `${newLine}`;
        cmds += `BILL COUNT: ${data.bill_count}`;
        cmds += `${newLine}`;
        cmds += `${bold}\x01`;
        cmds += `TOTAL: $${data.total}`;
        cmds += `${bold}\x00`;
        cmds += `${newLine}`;
        cmds += `${newLine}`;
        cmds += `KIOSK: ${data.kioskName}`;
        cmds += `${newLine}`;
        cmds += `COLLECTION ID: ${data.pk}`;
        cmds += `${newLine}${newLine}`;
        cmds += `${fontAlign}\x01`;
        cmds += `${fontFamiliy}\x01`;
        cmds += `www.ncic.com`;
        cmds += `${newLine}${newLine}`;
        cmds += `${cut}`;

        // @ts-ignore
        KioRawWinPrinter.Print('printer', cmds);
        return true;
    } catch {
        return false;
    }
}
