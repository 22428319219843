import { Component } from 'react';
import React from 'react';

import FeesContainer from './FeesContainer';
import { FormattedMessage } from 'react-intl';

type Props = {
    amounts?: any;
    fees?: any;
    byCash?: any;
    total?: any;
    deposit?: any;
    serviceType?: any;
    sFee?: any;
    pFee?: any;
};

class BookingFees extends Component<Props> {
    renderLeftSide = () => {
        return (
            <>
                <FormattedMessage id="deposit.total">{(msg: string) => <h5>{msg}:</h5>}</FormattedMessage>
            </>
        );
    };
    renderRightSide = (props: any) => {
        return (
            <>
                <h5>${props.total}</h5>
            </>
        );
    };
    render() {
        return (
            <FeesContainer
                titleId="fee.deposit"
                {...this.props}
                renderLeftSide={this.renderLeftSide}
                renderRightSide={this.renderRightSide}
            />
        );
    }
}

export default BookingFees;
