import React from 'react';
import { FormattedMessage } from 'react-intl';

interface HeaderProps {
    tColor?: string;
    screen?: string;
}

export default function Header({ screen }: HeaderProps) {
    return (
        <div className="header primary">
            <h2>
                <FormattedMessage id={`${screen}.title`} defaultMessage="" />
            </h2>
        </div>
    );
}
