import React, { Component } from 'react';
import './start.style.scss';

import Footer from '../../components/footer/footer.component';
import PhoneIcon from '../../components/img/Phone.svg';
import CommissaryIcon from '../../components/img/Commissary.svg';
import BookingIcon from '../../components/img/Booking.svg';
import { SERVER_URL, CHECK_SERVER_NORMAL, CHECK_SERVER_CRASHED, NCIC_HEARTBEAT } from '../../components/global';
import Loading from '../../components/loading/loading.component';

import { addBreadcrumb, Severity } from '@sentry/react';

import ErrorIcon from '@material-ui/icons/Error';
import { kwPresent } from '../../components/functions';
import { setShowKeyboard } from '../../redux/showKeyboard/showKeyboard.action';
import { connect } from 'react-redux';
import store from '../../redux/store';
import RESET from '../../redux/reset';
import setServiceType from '../../redux/serviceType/serviceType.action';
import setOutOfOrderMsg from '../../redux/outOfOrderMsg/outOfOrderMsg.action';
import { StoreState } from '../../redux/types';
import setApiKey from '../../redux/apiKey/apiKey.action';
import { FormattedMessage } from 'react-intl';

type Props = {
    [name: string]: any;

    setFacility: Function;
    setFees: Function;
    setServiceType: Function;
    setShowKeyboard: Function;
    setOutOfOrderMsg: Function;
    setApiKey: Function;
};

type State = {
    serverIsDown: boolean;
    loadingServer: boolean;
    interval: number;
    kioskDisabled: boolean;
    phoneService: boolean;
    commissaryService: boolean;
    bookingService: boolean;
    clicks: number;
    sTimer?: number;
};

type HB_Response = {
    code: string;
    text: string;
};

interface ServerStatus {
    response: string;
    key?: string;
    services?: Array<string>;
    error_message?: string;
}

const PhoneCard = (props: any) => {
    return (
        <>
            <div
                onClick={() => {
                    store.dispatch(setServiceType('phone_service'));
                    props.push('/search/');
                }}
                className="card-link"
            >
                <div className="moveUpSmall">
                    <div className="primary-bgc card">
                        <img className="ph-icon" src={PhoneIcon} alt="" />
                        <h1>
                            <FormattedMessage id="start.phoneTime" defaultMessage="Add Phone Time" />
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

const CommissaryCard = (props: any) => {
    return (
        <>
            <div
                onClick={() => {
                    store.dispatch(setServiceType('commissary_service'));
                    props.push('/search/');
                }}
                className="card-link"
            >
                <div className="moveUpSmall">
                    <div className="primary-bgc card">
                        <img id="cm-icon" className="ph-icon" src={CommissaryIcon} alt="" />
                        <h1>
                            <FormattedMessage id="start.loadCommissary" defaultMessage="Load Commissary" />
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

const BookingCard = (props: any) => {
    return (
        <>
            <div
                onClick={() => {
                    store.dispatch(setServiceType('booking_service'));
                    props.push('/search-pin/');
                }}
                className="card-link"
            >
                <div className="moveUpSmall">
                    <div className="primary-bgc card">
                        <img id="bk-icon" className="ph-icon" src={BookingIcon} alt="" />
                        <h1>
                            <FormattedMessage id="start.bookingService" defaultMessage="Inmate Booking" />
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

const OutOfOrder = () => {
    return (
        <>
            <div className="outOfOrder">
                <div className="error">
                    <ErrorIcon className="icon" />
                </div>
                <h2>
                    <FormattedMessage id="outOfService" />
                </h2>
                <p>
                    <FormattedMessage id="outOfService.text" />
                </p>
            </div>
        </>
    );
};

const CLICKS_FOR_ADMIN_SCREEN = 3;

class Start extends Component<Props, State> {
    _isMounted = false;
    constructor(props: Props) {
        super(props);
        this.state = {
            serverIsDown: true,
            loadingServer: true,
            interval: 0,
            kioskDisabled: false,
            phoneService: false,
            commissaryService: true,
            bookingService: true,
            clicks: 0,
            sTimer: undefined,
        };
    }
    handleServerError = (error: any, server: string) => {
        clearInterval(this.state.interval);
        if (this._isMounted) {
            this.setState({ interval: window.setInterval(this.fetchServers, CHECK_SERVER_CRASHED) });
            if (process.env.NODE_ENV === 'development') {
                console.log(
                    `%c Couldn't connect to ${server} server, error: ${JSON.stringify(error)}`,
                    'color: #b84242',
                );
            }
            this.setState({ serverIsDown: true, loadingServer: false });
            addBreadcrumb({
                category: 'Server Error',
                message: `Couldn't connect to ${server} server, error: ${JSON.stringify(error)}`,
                level: Severity.Error,
            });
        }
    };

    goToAdmin = () => {
        let { clicks } = this.state;
        clicks += 1;
        if (clicks >= CLICKS_FOR_ADMIN_SCREEN) {
            this.props.history.push('/admin/');
        } else {
            clearTimeout(this.state.sTimer);
            const sTimer = window.setTimeout(() => this.setState({ clicks: 0 }), 3000);
            this.setState({ clicks, sTimer });
        }
    };

    fetchServers = () => {
        const http = SERVER_URL;
        // Fetch Bixly backend
        fetch(http, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data: ServerStatus) => {
                if (data.response === 'ok') {
                    this.props.setOutOfOrderMsg(data.error_message);
                    //fetch NCIC backend
                    if (data.key) {
                        this.setState({
                            kioskDisabled: false,
                            phoneService: data.services!.includes('phone'),
                            commissaryService: data.services!.includes('commissary'),
                            bookingService: data.services!.includes('booking'),
                        });
                        this.fetchNCIC();
                    } else {
                        clearInterval(this.state.interval);
                        this.setState({
                            kioskDisabled: true,
                            loadingServer: false,
                            interval: window.setInterval(this.fetchServers, CHECK_SERVER_NORMAL),
                        });
                    }
                } else {
                    this.handleServerError(data, 'bixly');
                }
            })
            .catch((error) => {
                this.handleServerError(String(error), 'bixly');
            });
    };
    fetchNCIC = () => {
        const http = NCIC_HEARTBEAT;
        fetch(http, {
            method: 'POST',
        })
            .then((response) => {
                return response.json();
            })
            .then((data: HB_Response) => {
                if (data.code === '1') {
                    clearInterval(this.state.interval);
                    if (this._isMounted) {
                        this.setState({ interval: window.setInterval(this.fetchServers, CHECK_SERVER_NORMAL) });
                        this.setState({ serverIsDown: false, loadingServer: false });
                    }
                } else {
                    this.handleServerError(data.text, 'ncic');
                }
            })
            .catch((error) => {
                this.handleServerError(String(error), 'ncic');
            });
    };

    componentDidMount() {
        if (process.env.NODE_ENV === 'development') {
            document.title = 'NCIC DEVELOPMENT';
        }
        this._isMounted = true;
        // Resets everything from previous state
        store.dispatch(RESET);
        if (kwPresent) {
            this.props.setShowKeyboard(false);
            (document as any).KioApp.CloseKeyboard();
        }
        // WORKS ONLY IN KIOWARE
        // @ts-ignore
        if (document.WRONG_ENVIRONMENT) {
            this.setState({ serverIsDown: true, loadingServer: false });
        } else {
            this.fetchServers();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.state.interval);
        clearTimeout(this.state.sTimer);
    }

    render() {
        if (this.props.history.location.pathname !== '/') {
            return null;
        }
        const { serverIsDown, loadingServer, kioskDisabled } = this.state;
        if (loadingServer) {
            return <Loading noSnackBar={true} />;
        }
        if (serverIsDown || kioskDisabled) {
            return (
                <div id="whole">
                    <div id="data">
                        <OutOfOrder />
                    </div>
                    <Footer />
                </div>
            );
        } else {
            return (
                <div id="whole">
                    <div id="data">
                        <div id="services">
                            {this.state.phoneService ? <PhoneCard push={this.props.history.push} /> : ''}
                            {this.state.commissaryService && <CommissaryCard push={this.props.history.push} />}
                            {this.state.bookingService && <BookingCard push={this.props.history.push} />}
                        </div>
                    </div>
                    <Footer goToAdmin={this.goToAdmin} />
                </div>
            );
        }
    }
}

const mapDispatchFromProps = (dispatch: Function) => ({
    setShowKeyboard: (showKeyboard: boolean) => dispatch(setShowKeyboard(showKeyboard)),
    setOutOfOrderMsg: (outOfOrderMsg: string) => dispatch(setOutOfOrderMsg(outOfOrderMsg)),
    setApiKey: (apiKey: string) => dispatch(setApiKey(apiKey)),
});

const mapStateToProps = (state: StoreState) => ({
    outOfOrderMsg: state.outOfOrderMsg,
});

export default connect(mapStateToProps, mapDispatchFromProps)(Start);
