import { SetApiKey } from '../types';

const INITIAL_STATE = '';

const apiKeyReducer = (state: string = INITIAL_STATE, action: SetApiKey) => {
    switch (action.type) {
        case 'SET_API_KEY':
            return action.payload;

        default:
            return state;
    }
};

export default apiKeyReducer;
