const getSMSBody = (props: any) => {
    let smsBody = '';
    switch (props.serviceType) {
        case 'phone_service':
            smsBody = props.toPhoneNumber
                ? props.intl.formatMessage(
                      { id: 'sms.phoneService.toPhone' },
                      {
                          deposit: props.amounts.deposit.toFixed(2),
                          phoneNumber: props.phoneNumber,
                          inmatename: props.inmate.inmatename,
                          pin: props.inmate.pin,
                          facility: props.facility,
                          transactionID: props.transactionID,
                      },
                  )
                : props.intl.formatMessage(
                      { id: 'sms.phoneService.toAccount' },
                      {
                          deposit: props.amounts.deposit.toFixed(2),
                          inmatename: props.inmate.inmatename,
                          pin: props.inmate.pin,
                          facility: props.facility,
                          transactionID: props.transactionID,
                      },
                  );
            break;
        case 'commissary_service':
            smsBody = props.intl.formatMessage(
                { id: 'sms.commissaryService' },
                {
                    deposit: props.amounts.deposit.toFixed(2),
                    inmatename: props.inmate.inmatename,
                    pin: props.inmate.pin,
                    facility: props.facility,
                    transactionID: props.transactionID,
                },
            );
    }
    return smsBody;
};

export default getSMSBody;
