import React, { Component, ErrorInfo } from 'react';

import './borders.style.scss';

import Footer from '../components/footer/footer.component';
import { printErrorReceipt } from '../components/functions';
import Buttons from '../components/buttons';
import ErrorIcon from '@material-ui/icons/Error';
import { connect } from 'react-redux';
import { StoreState } from '../redux/types';

import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

type Props = {
    [name: string]: any;
} & WrappedComponentProps;

interface State {
    error: any;
}

class SentryBoundary extends Component<Props, State> {
    _timeoutBeforeRedirect: undefined | number;
    constructor(props: Props) {
        super(props);
        this._timeoutBeforeRedirect = undefined;
        this.state = { error: null };
    }

    ErrorOccured = ({ errorText, outOfOrderMsg }: any) => {
        let errorMessage = (
            <>
                <p>
                    <FormattedMessage id="error.screen.sorry" values={{ errorText: errorText }} />
                </p>
                <p>
                    <FormattedMessage id="error.screen.tryAnother" />
                </p>
                <p>
                    <FormattedMessage id="error.screen.notified" />
                </p>
            </>
        );
        if (outOfOrderMsg) {
            errorMessage = (
                <>
                    <p>{outOfOrderMsg}</p>
                </>
            );
        }
        return (
            <>
                <div id="data">
                    <div className="outOfOrder">
                        <div className="error">
                            <ErrorIcon className="icon" />
                        </div>
                        <h2>
                            <FormattedMessage id="errorOccurred" />
                        </h2>
                        {errorMessage}
                        {this.props.kiosk.printerEnabled && <FormattedMessage id="receiptWasPrinted" />}
                    </div>
                </div>
            </>
        );
    };

    clickConfirm = () => {
        clearTimeout(this._timeoutBeforeRedirect);
        this.props.history.push('/');
        this.setState({ error: false });
    };

    onTimeOut = () => {
        this.props.history.push('/');
        this.setState({ error: false });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const { formatMessage } = this.props.intl;
        // Print error receipt
        if (this.props.kiosk.printerEnabled) {
            printErrorReceipt(formatMessage);
        }
        //return to main page in X ms
        this._timeoutBeforeRedirect = window.setTimeout(this.onTimeOut, 15000);

        this.setState({ error });
    }
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { error: error };
    }
    render() {
        if (this.state.error) {
            //when there is an error - render error page
            return (
                <div id="whole-no-header">
                    <this.ErrorOccured errorText={this.props.errorText} outOfOrderMsg={this.props.outOfOrderMsg} />
                    <Buttons props={{ confirm: true, confirmText: 'startOver', clickConfirm: this.clickConfirm }} />
                    <Footer />
                </div>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}

const mapStateToProps = (state: StoreState) => ({
    errorText: state.errorText,
    outOfOrderMsg: state.outOfOrderMsg,
    kiosk: state.kiosk,
});

export default withRouter(connect(mapStateToProps)(injectIntl(SentryBoundary)));
