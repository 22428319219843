import React, { Component } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { connect } from 'react-redux';
import { setShowKeyboard, setNextClicked } from '../redux/showKeyboard/showKeyboard.action';
import { StoreState } from '../redux/types';

import { kwPresent } from './functions';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type Label = {
    [key: string]: number | undefined;
};

export type Props = {
    setShowKeyboard: Function;
    setNextClicked: Function;
    showKeyboard: any;
    typeKeyboard?: 'number' | 'general' | 'search';
    screen: string;
    id: string;
    onPlaceholder?: boolean;
    helperText?: string;
    labelValue?: Label;
} & TextFieldProps &
    WrappedComponentProps;

type State = {
    timeout: any;
};

class VKWrapper extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            timeout: null,
        };

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus = (type: string) => {
        if (kwPresent) {
            const elType = type === 'search' ? 'search' : '';
            this.setState({
                timeout: setTimeout(() => {
                    if (this.props.showKeyboard.show) {
                        this.props.setNextClicked!(true);
                        (document as any).KioApp.StartKeyboard('', elType, type, false, '', '');
                    } else {
                        this.props.setShowKeyboard!(true);
                        (document as any).KioApp.StartKeyboard('', elType, type, false, '', '');
                    }
                }, 50),
            });
        }
    };
    onBlur = () => {
        if (kwPresent) {
            this.setState({
                timeout: setTimeout(() => {
                    if (!this.props.showKeyboard.nextClicked) {
                        this.props.setShowKeyboard!(false);
                        (document as any).KioApp.CloseKeyboard();
                    } else {
                        this.props.setNextClicked!(false);
                    }
                }, 100),
            });
        }
    };

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    render() {
        const type = this.props.typeKeyboard ? this.props.typeKeyboard : 'letter';

        const {
            screen,
            onPlaceholder,
            intl,
            labelValue,
            showKeyboard,
            setNextClicked,
            setShowKeyboard,
            typeKeyboard,
            placeholder,
            ...rest
        } = this.props;
        const ph = intl.formatMessage({ id: `${screen}.${rest.id}` });
        const sbp = intl.formatMessage({ id: 'search.byPin' });
        const helperText = rest.helperText ? intl.formatMessage({ id: rest.helperText }, labelValue) : '';
        return (
            <TextField
                {...rest}
                label={onPlaceholder ? undefined : ph}
                placeholder={placeholder === 'PIN' ? sbp : ph}
                onFocus={() => {
                    this.onFocus(type);
                }}
                onBlur={this.onBlur}
                helperText={helperText}
            />
        );
    }
}

const mapDispatchFromProps = (dispatch: Function) => ({
    setShowKeyboard: (showKeyboard: boolean) => dispatch(setShowKeyboard(showKeyboard)),
    setNextClicked: (showKeyboard: boolean) => dispatch(setNextClicked(showKeyboard)),
});

const mapStateToProps = (state: StoreState) => ({
    showKeyboard: state.showKeyboard,
});

export default connect(mapStateToProps, mapDispatchFromProps)(injectIntl(VKWrapper));
