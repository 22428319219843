import { Inmate, SetNewInmate } from '../types';

const INITIAL_STATE: Inmate = {
    unmaskedpin: '',
    inmatename: '',
    pin: '',
    id: '1',
};

const inmateReducer = (state: Inmate = INITIAL_STATE, action: SetNewInmate) => {
    switch (action.type) {
        case 'SET_NEW_INMATE':
            return action.payload;
        default:
            return state;
    }
};

export default inmateReducer;
