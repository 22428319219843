import { Bill, BillAcceptor } from '../types';
import store from '../store';
import { setNewBill } from './bill';

import cloneDeep from 'lodash/cloneDeep';
import { BILL_ACCEPTOR, COIN_ACCEPTOR } from '../../components/global';

const setBillAcceptor = (billAcceptor: BillAcceptor) => ({
    type: 'SET_BILL_ACCEPTOR',
    payload: billAcceptor,
});
const turnBillAcceptorOn = () => ({
    type: 'TURN_BILL_ACCEPTOR_ON',
    payload: store.getState().billAcceptor,
});
const turnBillAcceptorOff = () => ({
    type: 'TURN_BILL_ACCEPTOR_OFF',
    payload: store.getState().billAcceptor,
});

// @ts-ignore
window.OnKioCurrencyDevice = function (deviceName, eventType, eventData) {
    if (process.env.NODE_ENV === 'development') {
        console.log('deviceName: ', deviceName, ' - eventType: ', eventType, ' - eventData: ', eventData);
    }
    const currentBillAcceptor: BillAcceptor = cloneDeep(store.getState().billAcceptor);
    const NotifyEventType = {
        StatusChanged: 1,
        CreditsChanged: 2,
        StackResultChanged: 3,
        DispensedByAmount: 4,
        DispensedByPosition: 5,
        DispensedByDenom: 6,
        DeviceReset: 7,
    };

    // <--- Log Breadcrumb to Sentry logging
    let strType;
    for (const key in NotifyEventType) {
        if (NotifyEventType[key] === eventType) {
            strType = key;
            break;
        }
    }
    if (!strType) {
        //If we didn't find event
        strType = eventType;
    }

    switch (eventType) {
        case NotifyEventType.CreditsChanged: {
            // Skip unneseccary technical log
            if (eventData.Value === 0) {
                break;
            }
            if (deviceName === BILL_ACCEPTOR) {
                // Check if bill is of allowed denominations
                if (store.getState().kiosk.billsAccepted.includes(eventData.Value)) {
                    //Get value on creditsChange
                    store.dispatch(setNewBill({ status: 'PENDING', value: eventData.Value }));
                    // @ts-ignore
                    KioCurrencyAcceptor.Stack(BILL_ACCEPTOR);
                }
                // Automatically reject if bill is not an allowed denominations
                else {
                    // @ts-ignore
                    KioCurrencyAcceptor.Reject(BILL_ACCEPTOR);
                }
            }
            if (deviceName === COIN_ACCEPTOR) {
                if (eventType === 2) {
                    // Add amount
                    const value = eventData.Value;
                    const newBill: Bill = {
                        amount: value,
                    };
                    // @ts-ignore
                    KioCurrencyAcceptor.Stack(COIN_ACCEPTOR);
                    currentBillAcceptor.stackedBills.push(newBill);
                    currentBillAcceptor.coinsArray.push(newBill);
                    currentBillAcceptor.stackedTotal = currentBillAcceptor.stackedTotal + newBill.amount;
                    currentBillAcceptor.currentBill = newBill;
                    store.dispatch(setBillAcceptor(currentBillAcceptor));
                }
            }
            break;
        }
        case NotifyEventType.StatusChanged: {
            // Update hardware status in software
            currentBillAcceptor.status = eventData;
            store.dispatch(setBillAcceptor(currentBillAcceptor));
            break;
        }
        case NotifyEventType.StackResultChanged: {
            // If bill is stacked
            if (eventData === 0 && deviceName === BILL_ACCEPTOR) {
                // Add amount
                const value = store.getState().bill.value;
                const newBill: Bill = {
                    amount: value,
                };
                currentBillAcceptor.stackedBills.push(newBill);
                currentBillAcceptor.billsArray.push(newBill);
                currentBillAcceptor.stackedTotal = currentBillAcceptor.stackedTotal + newBill.amount;
                currentBillAcceptor.currentBill = newBill;
                store.dispatch(setBillAcceptor(currentBillAcceptor));
            }
        }
    }
};

export { setBillAcceptor, turnBillAcceptorOn, turnBillAcceptorOff };

// if status changes - disable button (if not disabled)
// reenable if we reject the bill
// if bill acceptor rejects it - it'll stay disabled
// make everything in kioCurrencyEvent
// make it CLEAN
