import { Component } from 'react';
import React from 'react';
import Webcam from 'react-webcam';

import './captureImage.style.scss';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import TimeoutWarning from '../../components/timeout/timeout.component';
import { FormattedMessage } from 'react-intl';
import { addBreadcrumb, Severity } from '@sentry/react';

import { connect } from 'react-redux';

import { StoreState } from '../../redux/types';
import { Dispatch } from 'redux';
import setCaptureImage from '../../redux/captureImage/captureImage.action';
import Loading from '../../components/loading/loading.component';

interface ConfirmProps {
    [keyname: string]: any;
}
interface ConfirmState {
    time: number;
    showSnackbar: boolean;
    loaderClass: string;
    contentHidden: boolean;
}

class CaptureImage extends Component<ConfirmProps, ConfirmState> {
    constructor(props: ConfirmProps) {
        super(props);
        this.state = {
            time: 0,
            showSnackbar: false,
            loaderClass: '',
            contentHidden: true,
        };
    }

    componentDidMount() {
        //Breadcrumbs for Sentry
        addBreadcrumb({
            category: 'Page',
            message: 'Confirm',
            level: Severity.Info,
        });
    }

    setRef = (webcam: any) => {
        // @ts-ignore
        this.webcam = webcam;
    };

    handleUserMedia = () => {
        this.setState({ loaderClass: 'loader' });
        this.setState({ contentHidden: false });
    };

    clickCapture = () => {
        // @ts-ignore
        const imageSrc = this.webcam.getScreenshot();
        this.props.setCaptureImage(imageSrc);
        this.props.history.push('/accept/');
    };

    render() {
        const videoConstraints = {
            width: 1920,
            height: 1080,
            facingMode: 'user',
        };
        return (
            <div id="whole">
                <TimeoutWarning />
                {!this.state.contentHidden && <SnackBar />}
                {!this.state.contentHidden && <Header screen="photo" />}
                <div className={`loader ${this.state.loaderClass ? 'loader--online' : 'loader--offline'}`}>
                    <Loading noSnackBar={true} noFooter={false} />
                </div>
                <div id="data">
                    <div className={`hidden ${this.state.contentHidden ? 'hidden--online' : 'hidden--offline'}`}>
                        <div className="content content-inmate">
                            <div className="left">
                                <div className="marginAuto">
                                    <div className="moveUpSmall">
                                        <div className="border-webcam">
                                            <Webcam
                                                audio={false}
                                                height={315}
                                                ref={this.setRef}
                                                forceScreenshotSourceSize
                                                screenshotFormat="image/jpeg"
                                                width={500}
                                                videoConstraints={videoConstraints}
                                                onUserMedia={this.handleUserMedia}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="confirm-inmate confirm ">
                                    <h3 className="primary">
                                        <FormattedMessage id="capture.image" />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: () => {
                            this.clickCapture();
                        },
                        capture: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    inmate: state.inmate,
    timeouts: state.timeouts,
    loadType: state.loadType,
    serviceType: state.serviceType,
});
const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setCaptureImage: (toCaptureImage: string) => dispatch(setCaptureImage(toCaptureImage)),
});

export default connect(mapStateToProps, mapDispatchFromProps)(CaptureImage);
