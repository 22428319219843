import { SetNewAddress, Address } from '../types';
import cloneDeep from 'lodash/cloneDeep';
const INITIAL_STATE: Address = {
    firstName: '',
    lastName: '',
    address: '',
    zip: '',
    city: '',
    st: '',
    country: 'US',
};

const addressReducer = (state: Address = INITIAL_STATE, action: SetNewAddress) => {
    switch (action.type) {
        case 'SET_ADDRESS':
            const trimmedAddress = cloneDeep(action.payload);
            for (const key of Object.keys(trimmedAddress)) {
                trimmedAddress[key] = trimmedAddress[key].trim();
            }
            return trimmedAddress;

        default:
            return state;
    }
};

export default addressReducer;
