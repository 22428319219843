import { setKiosk, Kiosk } from '../types';

const INITIAL_STATE = {
    billsAccepted: [2, 5, 10, 20, 50],
    cashEnabled: true,
    creditEnabled: false,
    printerEnabled: false,
    webcamEnabled: false,
    timezone: 'America/New_York',
};

const kioskReducer = (state: Kiosk = INITIAL_STATE, action: setKiosk) => {
    switch (action.type) {
        case 'SET_KIOSK':
            return action.payload;
        default:
            return state;
    }
};

export default kioskReducer;
