import { SetKioskName } from '../types';

const INITIAL_STATE = 'Test';

const kioskNameReducer = (state: string = INITIAL_STATE, action: SetKioskName) => {
    switch (action.type) {
        case 'SET_KIOSK_NAME':
            return action.payload;

        default:
            return state;
    }
};

export default kioskNameReducer;
