import { setShowKeyboard, showKeyboard } from '../types';

const INITIAL_STATE = {
    show: false,
    nextClicked: false,
};

const showKeyboardReducer = (state: showKeyboard = INITIAL_STATE, action: setShowKeyboard) => {
    switch (action.type) {
        case 'SET_SHOWKEYBOARD':
            return Object.assign({}, state, {
                show: action.payload,
            });
        case 'SET_NEXTKEYBOARD':
            return Object.assign({}, state, {
                nextClicked: action.payload,
            });
        default:
            return state;
    }
};

export default showKeyboardReducer;
