import { SetNewAmounts, Amounts } from '../types';

const INITIAL_STATE: Amounts = {
    total: 0,
    deposit: 0,
};

const amountsReducer = (state: Amounts = INITIAL_STATE, action: SetNewAmounts) => {
    switch (action.type) {
        case 'SET_AMOUNTS':
            return action.payload;

        default:
            return state;
    }
};

export default amountsReducer;
