import React, { Component, ChangeEvent } from 'react';
import { connect } from 'react-redux';

import './sms.style.scss';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';
import { numberPrettifier } from '../../components/functions';
import { SERVER_URL } from '../../components/global';

import SmsIcon from '@material-ui/icons/Sms';
import VKWrapper from '../../components/VKWrapper';

import { StoreState } from '../../redux/types';
import { withStyles } from '@material-ui/core';

import TimeoutWarning from '../../components/timeout/timeout.component';
import { Redirect } from 'react-router-dom';
import { addBreadcrumb, captureMessage, Severity } from '@sentry/react';
import Loading from '../../components/loading/loading.component';
import checkRestrictedPhoneNumbers from '../../components/restrictedPhoneNumbers';
import Warning from '../../components/toast';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import getSMSBody from './sms.body';

type SMSProps = {
    [keyname: string]: any;
} & WrappedComponentProps;
interface SMSState {
    phone: string;
    inputError: boolean;
    helperText: string;
    error: boolean;
    numberRestrictedReason: string;
    loading: boolean;
}
const styles = () => ({
    //style for font size
    resize: {
        fontSize: '30px',
    },
    label: {
        fontSize: '20px',
    },
    state: {
        height: '73px',
    },
});

class SMS extends Component<SMSProps, SMSState> {
    constructor(props: SMSProps) {
        super(props);
        this.state = {
            phone: '',
            inputError: false,
            helperText: 'minDigits',
            error: false,
            loading: false,
            numberRestrictedReason: '',
        };
    }

    validatePhone() {
        const number = this.state.phone.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3');
        return number.length === 10;
    }

    getServiceType() {
        switch (this.props.serviceType) {
            case 'phone_service':
                return 'phone';
            case 'commissary_service':
                return 'commissary';
        }
    }

    sendSms = () => {
        const http = SERVER_URL + 'send/';
        const smsBody = getSMSBody(this.props);
        const data = {
            method: 'SMS',
            phoneNumber: this.state.phone,
            body: smsBody,
        };
        const request = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        };
        fetch(http, request)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.details === 'success') {
                    addBreadcrumb({
                        category: 'Event',
                        message: `SMS sent`,
                        level: Severity.Info,
                    });
                    this.props.history.push('/sent/');
                } else {
                    captureMessage(response);
                    this.setState({ loading: false, error: true });
                }
            })
            .catch((error) => {
                captureMessage(error);
                this.setState({ loading: false, error: true });
            });
    };

    clickSend = async () => {
        if (this.validatePhone()) {
            this.setState({ loading: true });
            // Check with NCIC backend if number is not forbidden
            const num = this.state.phone.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3');
            const restrictedReason = await checkRestrictedPhoneNumbers(num, this.props.apiKey, true);
            if (restrictedReason) {
                this.setState({ loading: false, numberRestrictedReason: restrictedReason });
            }
            //Send sms logic
            else {
                this.sendSms();
            }
        } else {
            this.setState({ inputError: false });
        }
    };

    componentDidMount() {
        const phone = numberPrettifier(this.props.phoneNumber);
        this.setState({ phone: phone }, () => {
            this.setState({ inputError: this.validatePhone() });
        });
    }

    handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const el = e.target as HTMLInputElement;
        const phone = numberPrettifier(el.value.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3'));
        this.setState({ phone: phone }, () => {
            this.setState({ inputError: this.validatePhone() });
        });
    };

    render() {
        const { loading, error, numberRestrictedReason } = this.state;
        if (loading) {
            return <Loading />;
        }
        if (error) {
            return <Redirect to={{ pathname: '/receipt/', state: { receiptErrorSource: 'SMS' } }} />;
        }
        return (
            <div id="whole">
                <TimeoutWarning />
                <Warning
                    text={numberRestrictedReason ? this.props.intl.formatMessage({ id: numberRestrictedReason }) : ''}
                    open={Boolean(numberRestrictedReason)}
                    onClose={() => {
                        this.setState({ numberRestrictedReason: '' });
                    }}
                />
                <Header screen="sms" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div className="marginAuto">
                                <div className="sms-number">
                                    <VKWrapper
                                        id="phoneNumber"
                                        screen="sms"
                                        typeKeyboard="number"
                                        error={!this.state.inputError}
                                        helperText={!this.state.inputError ? this.state.helperText : ' '}
                                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                            this.handleChange(e);
                                        }}
                                        value={this.state.phone}
                                        label="Phone Number"
                                        variant="outlined"
                                        fullWidth={true}
                                        labelValue={{ number: 10 }}
                                        InputProps={{
                                            endAdornment: <SmsIcon fontSize="large" className="primary" />,
                                            classes: {
                                                input: this.props.classes.resize,
                                                notchedOutline: this.props.classes.label,
                                            },
                                        }}
                                        InputLabelProps={{
                                            classes: {
                                                root: this.props.classes.label,
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="confirm-send">
                                <h3 className="primary">
                                    <FormattedMessage id="sms.helper" />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickSend,
                        confirm: true,
                        confirmText: 'send',
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    phoneNumber: state.phoneNumber,
    amounts: state.amounts,
    inmate: state.inmate,
    toPhoneNumber: state.toPhoneNumber,
    transactionID: state.transactionID,
    facility: state.facility,
    serviceType: state.serviceType,
    apiKey: state.apiKey,
});

export default withStyles(styles)(connect(mapStateToProps)(injectIntl(SMS)));
