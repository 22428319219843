import React from 'react';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import VKWrapper from '../../components/VKWrapper';

const useStyles = makeStyles(() => ({
    search: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
        width: '100%',
    },
    inputRoot: {
        marginLeft: '20px',
        width: '70%',
    },
}));

interface BarProps {
    handleChange: Function;
    showCross: boolean;
    clear: any;
    value: string;
    focused: boolean;
    placeholder: string;
    typeKeyboard: any;
}

function SearchBar(props: BarProps) {
    const classes = useStyles();
    return (
        <div className={classes.search}>
            <VKWrapper
                id="name"
                screen="search"
                classes={{
                    root: classes.inputRoot,
                }}
                autoFocus={props.focused}
                onPlaceholder
                placeholder={props.placeholder}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    props.handleChange(e);
                }}
                variant="outlined"
                value={props.value}
                typeKeyboard={props.typeKeyboard}
                InputProps={{
                    endAdornment: props.showCross ? (
                        <ClearIcon
                            fontSize="large"
                            onClick={() => {
                                props.clear();
                            }}
                            className="primary"
                        />
                    ) : (
                        <SearchIcon fontSize="large" className="primary" />
                    ),
                }}
                fullWidth={true}
            />
        </div>
    );
}

export default SearchBar;
