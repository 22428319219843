import React, { Component } from 'react';
import './footer.style.scss';

import { connect } from 'react-redux';
import { StoreState } from '../../redux/types';
import { FormattedMessage } from 'react-intl';

class Footer extends Component<any> {
    handleSecret = () => {
        if (this.props.goToAdmin) {
            this.props.goToAdmin();
        }
    };
    render() {
        if (!this.props.showKeyboard.show) {
            return (
                <div className="footer secondary-bgc">
                    <p className="copy" onClick={this.handleSecret}>
                        <FormattedMessage id="footer.copyright" defaultMessage="Back" />
                    </p>
                    <p className="flink">ncic.com</p>
                </div>
            );
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = (state: StoreState) => ({
    showKeyboard: state.showKeyboard,
});

export default connect(mapStateToProps)(Footer);
