import { combineReducers } from 'redux';

import inmateReducer from './inmate/inmate.reducer';
import toPhoneNumberReducer from './toPhoneNumber/toPhoneNumber.reducer';
import phoneNumberReducer from './phoneNumber/phoneNumber.reducer';
import addressReducer from './billingAddress/address.reducer';
import feesReducer from './fees/fees.reducer';
import byCashReducer from './byCash/byCash.reducer';
import amountsReducer from './amounts/amounts.reducer';
import billAcceptorReducer from './billAcceptor/billAcceptor.reducer';
import { billReducer } from './billAcceptor/bill';
import facilityReducer from './facility/facility.reducer';
import swipedReducer from './swiped/swiped.reducer';
import ccReducer from './cc/cc.reducer';
import timeoutsReducer from './timeouts/timeouts.reducer';
import searchFieldReducer from './searchfield/searchfield.reducer';
import showKeyboardReducer from './showKeyboard/showKeyboard.reducer';
import timeoutReducer from './timeout/timout.reducer';
import transactionIDReducer from './transactionID/transactionID.reducer';
import errorTextReducer from './errorText/errorText.reducer';
import serviceTypeReducer from './serviceType/serviceType.reducer';
import kioskNameReducer from './kioskName/kioskName.reducer';
import apiKeyReducer from './apiKey/apiKey.reducer';
import loadTypeReducer from './loadType/loadType.reducer';
import kioskReducer from './kiosk/kiosk.reducer';
import loadLimitsReducer from './loadLimits/loadLimits.reducer';
import outOfOrderMsgReducer from './outOfOrderMsg/outOfOrderMsg.reducer';
import photoModeReducer from './photoMode/photoMode.reducer';
import captureImageReducer from './captureImage/captureImage.reducer';

const appReducer = combineReducers({
    inmate: inmateReducer,
    toPhoneNumber: toPhoneNumberReducer,
    phoneNumber: phoneNumberReducer,
    address: addressReducer,
    fees: feesReducer,
    byCash: byCashReducer,
    amounts: amountsReducer,
    billAcceptor: billAcceptorReducer,
    bill: billReducer,
    facility: facilityReducer,
    cc: ccReducer,
    swiped: swipedReducer,
    timeouts: timeoutsReducer,
    searchfield: searchFieldReducer,
    showKeyboard: showKeyboardReducer,
    timeout: timeoutReducer,
    transactionID: transactionIDReducer,
    errorText: errorTextReducer,
    serviceType: serviceTypeReducer,
    kioskName: kioskNameReducer,
    apiKey: apiKeyReducer,
    loadType: loadTypeReducer,
    kiosk: kioskReducer,
    loadLimits: loadLimitsReducer,
    outOfOrderMsg: outOfOrderMsgReducer,
    photoMode: photoModeReducer,
    captureImage: captureImageReducer,
});

const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
};
// @ts-ignore
export default (state, action) => rootReducer(action.type === 'RESET' ? undefined : state, action);
