import React, { Component, ChangeEvent } from 'react';

import './address.style.scss';

import Footer from '../../components/footer/footer.component';

import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import AddressForm from './address.form';
import PhoneField from '../../components/phoneField';
import { numberPrettifier } from '../../components/functions';
import TimeoutWarning from '../../components/timeout/timeout.component';

import setPhoneNumber from '../../redux/phoneNumber/phoneNumber.action';
import setAddress from '../../redux/billingAddress/address.action';

import { Address as Adr } from '../../redux/types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/types';

import { INITIAL_STATE, AddressState as AS } from './address.states';
import checkRestrictedPhoneNumbers from '../../components/restrictedPhoneNumbers';
import Warning from '../../components/toast';
import Loading from '../../components/loading/loading.component';
import { injectIntl, WrappedComponentProps } from 'react-intl';

const clone = (dict: Record<string, any>) => {
    return JSON.parse(JSON.stringify(dict));
};

interface phoneNumber {
    value: string;
    helperText: string;
    inputError: boolean;
    correct: boolean;
}
type AddressProps = {
    [keyname: string]: any;
} & WrappedComponentProps;

interface AddressState {
    number: phoneNumber;
    errors: string[];
    error: string;
    loading: boolean;
    clicked: boolean;
    address: AS;
}

class Address extends Component<AddressProps, AddressState> {
    constructor(props: AddressProps) {
        super(props);
        this.state = {
            number: {
                value: '',
                helperText: 'Incorrect phone number',
                inputError: false,
                correct: false,
            },
            errors: [],
            error: '',
            loading: false,
            clicked: false,
            address: INITIAL_STATE,
        };
    }

    componentDidMount() {
        const phone = numberPrettifier(this.props.phoneNumber);
        const number: phoneNumber = this.state.number;
        number.value = phone;
        number.correct = this.props.phoneNumber.length === 10;
        this.setState({ number: number });
    }

    handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //Don't allow to go on the next page if number is not full
        const number = e.target.value.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3');
        const result = numberPrettifier(number);

        const num = clone(this.state.number);
        num.value = result ? result : '';
        this.setState({ number: num });

        if (!result) {
            return;
        }
        const numCheck = result.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3').length;
        if (numCheck === 10) {
            num.correct = true;
            num.inputError = false;
        } else {
            num.correct = false;
        }
        this.setState({ number: num });
    };

    createAddress = (data: AS) => {
        const address: Adr = {
            firstName: '',
            lastName: '',
            address: '',
            zip: '',
            city: '',
            st: '',
        };
        for (const key in data) {
            address[key] = data[key].value;
        }
        return address;
    };

    checkPhone = (copy: string[]) => {
        //Phone
        if (!this.props.toPhoneNumber) {
            const number = this.state.number.value.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3');
            if (this.state.number.correct) {
                const num = clone(this.state.number);
                num.inputError = false;
                this.setState({ number: num });
                this.props.setPhoneNumber(number);
            } else {
                const num = clone(this.state.number);
                num.inputError = true;
                this.setState({ number: num });
                copy.push('number');
                this.setState({ errors: copy });
            }
        }
    };

    clickConfirm = async () => {
        //Check fields and throw error if something is not filled
        const copy: string[] = [];
        this.setState({ errors: [], clicked: true });

        //Phone
        this.checkPhone(copy);
        //Address
        const address = this.state.address;
        if (address) {
            //Check if all fields are filled
            for (const key of Object.keys(address)) {
                if (!address[key].correct) {
                    copy.push(key);
                }
            }
        } else {
            copy.push('number');
        }
        this.props.setAddress(this.createAddress(this.state.address));

        this.setState({ errors: copy }, async () => {
            if (this.state.errors.length === 0) {
                // Check for restricted number (only on phone service) 8009432189
                let restrictedReason;

                if (this.props.serviceType === 'phone_service') {
                    const num = this.state.number.value.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3');
                    this.setState({
                        loading: true,
                    });
                    restrictedReason = await checkRestrictedPhoneNumbers(num, this.props.apiKey, false);
                }

                if (restrictedReason) {
                    this.setState({ loading: false, error: restrictedReason });
                } else {
                    this.props.history.push('/method/');
                }
            }
        });
    };

    eventhandler = (data: AS) => {
        this.setState({ address: data });
    };

    render() {
        const { loading, error } = this.state;
        if (loading) return <Loading />;
        return (
            <div id="whole">
                <TimeoutWarning />
                <Warning
                    text={error ? this.props.intl.formatMessage({ id: error }) : ''}
                    open={Boolean(error)}
                    onClose={() => {
                        this.setState({ error: '' });
                    }}
                />
                <SnackBar />
                <div className="heads">
                    {!this.props.toPhoneNumber ? <Header screen="phoneNumber" /> : ''}
                    <Header screen="billingAddress" />
                </div>
                <div id="data">
                    <div className="content">
                        {!this.props.toPhoneNumber ? (
                            <div className="left">
                                <div className="content">
                                    <div className="phone-number">
                                        <div className="moveUp">
                                            <PhoneField
                                                autoFocus={!Boolean(this.props.phoneNumber)}
                                                handleChange={this.handleChange}
                                                value={this.state.number.value}
                                                inputError={this.state.number.inputError}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="right-address" id={this.props.toPhoneNumber ? 'single' : ''}>
                            <div className="content">
                                <div className="add">
                                    <div className="moveUp">
                                        <AddressForm
                                            autoFocus={Boolean(!this.props.address.firstName && this.props.phoneNumber)}
                                            onChange={this.eventhandler}
                                            errors={this.state.errors}
                                            clicked={this.state.clicked}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickConfirm,
                        confirm: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setPhoneNumber: (phoneNumber: string) => dispatch(setPhoneNumber(phoneNumber)),
    setAddress: (address: Adr) => dispatch(setAddress(address)),
});
const mapStateToProps = (state: StoreState) => ({
    inmate: state.inmate,
    toPhoneNumber: state.toPhoneNumber,
    phoneNumber: state.phoneNumber,
    address: state.address,
    loadType: state.loadType,
    apiKey: state.apiKey,
    serviceType: state.serviceType,
});

export default connect(mapStateToProps, mapDispatchFromProps)(injectIntl(Address));
