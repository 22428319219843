import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignSelf: 'center',
    },
    input: {
        backgroundColor: '#FFFFFF !important',
    },
    helperText: {
        color: '#FFFFFF',
    },
});

export default useStyles;
