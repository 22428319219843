import { SetFacility } from '../types';

const INITIAL_STATE = '';

const facilityReducer = (state: string = INITIAL_STATE, action: SetFacility) => {
    switch (action.type) {
        case 'SET_FACILITY':
            return action.payload;

        default:
            return state;
    }
};

export default facilityReducer;
