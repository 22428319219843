import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { Inmate } from '../../redux/types';

import { makeStyles } from '@material-ui/core/styles';

const tStyles = makeStyles({
    container: {
        overflow: 'hidden',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
    },
    table: {
        width: '70%',
        marginLeft: '20px',
    },
    row: {
        cursor: 'pointer',
    },
    pin: {
        color: '#25408F',
        fontSize: '24px',
    },
    name: {
        fontSize: '24px',
    },
});

interface TableProps {
    filtInmates: Array<Inmate>;
    goToConfirm: Function;
}

export default function SimpleTable({ filtInmates, goToConfirm }: TableProps) {
    const classes = tStyles();

    return (
        <TableContainer
            id="table-container"
            className={classes.container}
            style={{ marginTop: '20px' }}
            component={Paper}
        >
            <Table className={classes.table} aria-label="simple table">
                {/* <TableHead>
            <TableRow>
              <TableCell>Last Name</TableCell>
              <TableCell align="right">Pin</TableCell>
            </TableRow>
          </TableHead> */}
                <TableBody>
                    {filtInmates.map((row) => (
                        <TableRow
                            className={classes.row}
                            onClick={() =>
                                goToConfirm({
                                    inmatename: row.inmatename,
                                    pin: row.pin,
                                    id: row.id,
                                    unmaskedpin: row.unmaskedpin,
                                })
                            }
                            key={row.id}
                        >
                            <TableCell className={classes.name} component="th" scope="row">
                                {row.inmatename}
                            </TableCell>
                            <TableCell className={classes.pin} align="right">
                                {row.pin}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
