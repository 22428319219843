const setTimeout = (timeouts: number) => ({
    type: 'SET_TIMEOUT',
    payload: timeouts,
});
const setIsTimedOut = (timeouts: boolean) => ({
    type: 'SET_ISTIMEDOUT',
    payload: timeouts,
});

export { setTimeout, setIsTimedOut };
