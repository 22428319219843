import React, { Component, KeyboardEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './search.style.scss';

import Footer from '../../components/footer/footer.component';
import Buttons from '../../components/buttons';
import { MAX_ATTEMPTS, SERVER_URL, TIME_BETWEEN_ATTEMPTS } from '../../components/global';

import SearchBar from './search';
import SimpleTable from './table';

import { connect } from 'react-redux';
import { Kiosk, LoadLimits, StoreState } from '../../redux/types';
import setInmate from '../../redux/inmate/inmate.action';
import setSearchField from '../../redux/searchfield/searchfield.action';
import setFees from '../../redux/fees/fees.action';
import setFacility from '../../redux/facility/facility.action';
import setKioskName from '../../redux/kioskName/kioskName.action';
import { Inmate, Fees, SettingsData } from '../../redux/types';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Loader from 'react-loader-spinner';

import TimeoutWarning from '../../components/timeout/timeout.component';
import CloseKeyboard from '../../components/useFunction';
import { dumpFees, getArrayAllowedCash } from '../../components/functions';
import { addBreadcrumb, captureMessage, Severity } from '@sentry/react';
import setApiKey from '../../redux/apiKey/apiKey.action';
import Loading from '../../components/loading/loading.component';
import setTransactionID from '../../redux/transactionID/transactionID.action';
import setLoadType from '../../redux/loadType/loadType.action';
import setKiosk from '../../redux/kiosk/kiosk.action';
import setOutOfOrderMsg from '../../redux/outOfOrderMsg/outOfOrderMsg.action';
import setLoadLimits from '../../redux/loadLimits/loadLimits.action';
import setPhotoMode from '../../redux/photoMode/photoMode.action';

function ServerError(this: any, message: string) {
    this.name = 'Server Error';
    this.message = message;
}
ServerError.prototype = new Error();

type Props = {
    setInmate: Function;
    setSearchField: Function;
    setFees: Function;
    setFacility: Function;
    setLoadType: Function;
    setKioskName: Function;
    setApiKey: Function;
    setTransactionID: Function;
    setKiosk: Function;
    setLoadLimits: Function;
    setOutOfOrderMsg: Function;
    apiKey?: string;
    searchfield: any;
    showKeyboard: any;
    serviceType: any;
    loadType: number;
    setPhotoMode: Function;
} & RouteComponentProps;

interface SearchState {
    searchfield: string;
    inmates: Array<Inmate>;
    error: boolean;
    loading: boolean;
    typeKeyboard: string;
    placeholder: any;
    fetchingData: boolean;
}

class SearchPin extends Component<Props, SearchState> {
    //Called onClick in table. Save data and redirect
    goToConfirm = (inmate: Inmate) => {
        this.props.setSearchField(this.state.searchfield);
        this.props.setInmate(inmate);
        this.props.setTransactionID(uuidv4().replace(/-/g, '').slice(0, 17));
        //Check if in KioWare if so - close keyboard
        if (/KioWare/i.test(navigator.userAgent)) {
            CloseKeyboard();
        }
        this.props.history.push('/confirm/');
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            inmates: [],
            searchfield: '',
            error: false,
            loading: false,
            typeKeyboard: '',
            placeholder: '',
            fetchingData: false,
        };
    }
    sleep(milliseconds: number) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }
    //Fetch inmate by PIN from ncic server
    fetchInmate = async (pin: string) => {
        for (let i = 0; i < MAX_ATTEMPTS; i++) {
            try {
                this.setState({ fetchingData: true });
                const response = await fetch(`https://restapi.ncic.com/depositinmatename?apikey=${this.props.apiKey}`, {
                    method: 'POST',
                    body: JSON.stringify({ pin: pin }),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                this.setState({ inmates: data });
                this.setState({ fetchingData: false });
                return;
            } catch (err) {
                console.error(err);
                await this.sleep(TIME_BETWEEN_ATTEMPTS);
            }
        }
        this.setState({ error: true }); // Throw error if server is down
    };

    //Fetch settings from backend server
    fetchSettings = () => {
        const http = SERVER_URL + 'kiosk/';

        fetch(http + 'me/', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data: SettingsData) => {
                // redirects user to start page if service user selected was disabled
                if (data[this.props.serviceType] === null) {
                    this.props.history.push('/');
                    addBreadcrumb({
                        category: 'Server Error',
                        message: `Redirecting user, ${this.props.serviceType} was disabled.`,
                        level: Severity.Error,
                    });
                    return;
                }
                //Set settings to redux state
                this.props.setFacility(data.location.name);
                this.props.setLoadLimits({
                    cashMax: data[this.props.serviceType].cash_max,
                    cashMin: data[this.props.serviceType].cash_min,
                    creditMax: data[this.props.serviceType].credit_max,
                    creditMin: data[this.props.serviceType].credit_min,
                });
                if (this.props.serviceType === 'phone_service') {
                    this.props.setLoadType(data.phone_service.load_type);
                }
                this.props.setOutOfOrderMsg(data.error_message);
                this.props.setKiosk({
                    billsAccepted: getArrayAllowedCash(data),
                    printerEnabled: data.printer_enabled,
                    cashEnabled: data.cash_enabled,
                    creditEnabled: data.credit_enabled,
                    timezone: data.location.timezone,
                    webcamEnabled: data.webcam_enabled,
                });
                this.props.setFees(dumpFees(data, this.props.serviceType));
                this.props.setKioskName(data.name);
                this.props.setApiKey(data.location.api_key);
                this.setState({ loading: false });
                this.props.setPhotoMode(data.booking_service.photo_mode);
            })
            .catch((err) => {
                captureMessage(err);
                this.setState({ error: true }); // Throw error if server is down
            });
    };

    componentDidMount() {
        this.fetchSettings();
    }

    componentDidUpdate() {
        // @ts-ignore
        this.state.inmates;
    }
    // Handle change in searchbox
    handleChange = (e: KeyboardEvent) => {
        const el = e.target as HTMLInputElement;
        const value = el.value.slice(0, 50).trimStart();
        this.setState({ searchfield: value });
    };

    clickConfirm = () => {
        // @ts-ignore
        this.fetchInmate(this.state.searchfield);
    };

    clickGoBack = () => {
        this.props.history.goBack();
    };

    clear = () => {
        this.setState({ searchfield: '', inmates: [] });
    };

    formattedMessage = () => {
        return <FormattedMessage id="search.byPin" defaultMessage="Search Inmate by PIN" />;
    };

    render() {
        if (this.state.error) {
            // prettier-ignore
            throw new ((ServerError as unknown) as FunctionConstructor)(
                "Couldn't connect to server - check if backend is working",
            );
        }
        if (this.state.loading) {
            return <Loading noSnackBar={true} />;
        }
        const { inmates, searchfield } = this.state;
        let filtInmates: string | any[] = [];
        let table;
        if (this.state.fetchingData) {
            table = (
                <div style={{ textAlign: 'center', marginTop: '20px' }} className="div">
                    <Loader type="BallTriangle" color="#2979FF" height={100} width={100} />
                </div>
            );
        } else {
            if (inmates) {
                filtInmates = inmates.filter((inmate: Inmate) => inmate.pin.includes(searchfield));
                if (filtInmates.length > 0) {
                    table = <SimpleTable filtInmates={filtInmates} goToConfirm={this.goToConfirm} />;
                } else {
                }
            } else {
                table = <h3 style={{ textAlign: 'center', marginTop: '30px' }}>No Data</h3>;
            }
        }

        return (
            <div id="whole">
                <TimeoutWarning />
                <SearchBar
                    handleChange={this.handleChange}
                    showCross={searchfield.length >= 1}
                    clear={this.clear}
                    value={this.state.searchfield}
                    // focused={this.state.focused}
                    focused={!this.props.searchfield}
                    typeKeyboard="number"
                    // @ts-ignore
                    placeholder="PIN"
                />
                <div id="data">{table}</div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickConfirm,
                        back: true,
                        search: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapDispatchFromProps = (dispatch: Function) => ({
    setInmate: (inmate: Inmate) => dispatch(setInmate(inmate)),
    setSearchField: (searchfield: string) => dispatch(setSearchField(searchfield)),
    setFees: (fees: Fees) => dispatch(setFees(fees)),
    setFacility: (facility: string) => dispatch(setFacility(facility)),
    setKioskName: (name: string) => dispatch(setKioskName(name)),
    setLoadType: (loadType: number) => dispatch(setLoadType(loadType)),
    setApiKey: (apiKey: string) => dispatch(setApiKey(apiKey)),
    setTransactionID: (transactionID: string) => dispatch(setTransactionID(transactionID)),
    setOutOfOrderMsg: (outOfOrderMsg: string) => dispatch(setOutOfOrderMsg(outOfOrderMsg)),
    setKiosk: (kiosk: Kiosk) => dispatch(setKiosk(kiosk)),
    setLoadLimits: (loadLimits: LoadLimits) => dispatch(setLoadLimits(loadLimits)),
    setPhotoMode: (photoMode: number) => dispatch(setPhotoMode(photoMode)),
});

const mapStateToProps = (state: StoreState) => ({
    searchfield: state.searchfield,
    showKeyboard: state.showKeyboard,
    apiKey: state.apiKey,
    serviceType: state.serviceType,
    billAcceptor: state.billAcceptor,
});

export default withRouter(connect(mapStateToProps, mapDispatchFromProps)(SearchPin));
