import {
    RESTRICTED_NUMBERS_URL,
    RESTRICTED_NUMBER_MESSAGE,
    RESTRICTED_SMS_NUMBER_MESSAGE,
    SERVER_ERROR_MESSAGE,
    MAX_ATTEMPTS,
    TIME_BETWEEN_ATTEMPTS,
} from './global';
import { addBreadcrumb, captureMessage, Severity } from '@sentry/react';
import { sleep } from './functions';

interface RestrictedNumber {
    restrictednumber: string;
}

const checkRestrictedPhoneNumbers = async (num: string, apiKey: string, toSMS: boolean) => {
    let error_message = 'Unknown error';
    let sentry_message = "Didn't trigger any error";
    for (let i = 0; i < MAX_ATTEMPTS; i++) {
        try {
            const response = await fetch(RESTRICTED_NUMBERS_URL, {
                method: 'POST',
                body: JSON.stringify({ apikey: apiKey }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                const data = await response.json();
                if (data.some((item: RestrictedNumber) => item.restrictednumber === num)) {
                    addBreadcrumb({
                        category: 'Forbidden phone number',
                        message: num,
                        level: Severity.Info,
                    });
                    return toSMS ? RESTRICTED_SMS_NUMBER_MESSAGE : RESTRICTED_NUMBER_MESSAGE;
                } else {
                    return;
                }
            } else {
                sentry_message = `Server returned unexpected response: ${response.status}`;
                error_message = SERVER_ERROR_MESSAGE;
            }
        } catch (err) {
            sentry_message = `Couldn't connect to server to check phone number: ${err}`;
            error_message = SERVER_ERROR_MESSAGE;
        }
        await sleep(TIME_BETWEEN_ATTEMPTS);
    }
    captureMessage(sentry_message);
    return error_message;
};

export default checkRestrictedPhoneNumbers;
