import { setLoadLimits, LoadLimits } from '../types';

const INITIAL_STATE = {
    cashMin: 1,
    cashMax: 2,
    creditMin: 5,
    creditMax: 100,
};

const loadLimitsReducer = (state: LoadLimits = INITIAL_STATE, action: setLoadLimits) => {
    switch (action.type) {
        case 'SET_LOAD_LIMITS':
            return action.payload;

        default:
            return state;
    }
};

export default loadLimitsReducer;
