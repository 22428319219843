import React from 'react';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface WarningProps extends SnackbarProps {
    text: string;
    open: boolean;
    onClose: (event: React.SyntheticEvent<any, Event>) => void;
}

function Warning(props: WarningProps) {
    return (
        <Snackbar style={{ marginTop: '130px' }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} {...props}>
            <Alert
                onClose={(event: React.SyntheticEvent<Element, Event>) => {
                    props.onClose(event);
                }}
                severity="warning"
            >
                {props.text}
            </Alert>
        </Snackbar>
    );
}

export default Warning;
