import React, { ChangeEvent, useState, useEffect } from 'react';
import VKWrapper from '../../components/VKWrapper';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import store from '../../redux/store';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';

import TimeoutWarning from '../../components/timeout/timeout.component';
import Button from '@material-ui/core/Button';
import { Avatar, Container, Paper, Typography } from '@material-ui/core';
import setServiceType from '../../redux/serviceType/serviceType.action';
import { SERVER_URL, RECEIPT_PRINTER } from '../../components/global';
import Warning from '../../components/toast';
import Loading from '../../components/loading/loading.component';
import { useHistory } from 'react-router-dom';
import { captureMessage } from '@sentry/react';
import printReceipt from './printReceipt';
import { CollectionData } from './types';
import { FormattedMessage } from 'react-intl';
import { getPrinterStatusErrorCodes } from '../../components/functions';

const useStyles = makeStyles({
    inputC: {
        marginTop: '70px',
        paddingTop: '20px',
    },
    input: {
        marginTop: '20px',
    },
    note: {
        fontWeight: 'bold',
        fontSize: '40px',
        lineHeight: '91px',
        color: '#25408f',
    },
    q: {
        fontSize: '30px',
        color: '#25408F',
        fontWeight: 500,
        textAlign: 'center',
        margin: '20px',
    },
    paper: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '40px',
    },
    avatar: {
        margin: '10px',
        backgroundColor: '#25408F',
    },
    signin: {
        marginBottom: '30px',
    },
    icon: {
        fontSize: '170px',
        color: '#25408f',
    },
});

const Admin = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [printerEnabled, setPrinterEnabled] = useState(false);
    const [printerStatusOffline, setPrinterStatusOffline] = useState(false);
    const [errorLogin, setErrorLogin] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [retry, setRetry] = useState(false);
    const [data, setData] = useState<CollectionData | undefined>();
    const [collectionID, setCollectionID] = useState<number | undefined>();
    const [page, setPage] = useState<'login' | 'confirm' | 'success' | 'receipt'>('login');
    const classes = useStyles();
    const handleLoginChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLogin(e.target.value);
    };
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPassword(e.target.value);
    };
    const handleError = (error: string, step: 'login' | 'profile') => {
        let msg = '';
        switch (step) {
            case 'login':
                msg = `Collection failed to log in: ${JSON.stringify(error)}`;
                break;
            case 'profile':
                msg = `Collection failed to get profile info for ${login}: ${JSON.stringify(error)}`;
                break;
        }
        captureMessage(msg);
        // @ts-ignore
        KioApp.LogErr(msg);
        setLoading(false);
    };

    const retryCollection = (err: string) => {
        setRetry(true);
        setLoading(false);
        const msg = `Failed to record collection for agent (${login}) name: ${name} : ${err}`;
        captureMessage(msg);
        // @ts-ignore
        KioApp.LogErr(msg);
    };

    const _getProfileInfo = async (token: string) => {
        const result = await fetch(SERVER_URL + 'profile/me/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        try {
            const data = await result.json();
            return data;
        } catch (err) {
            handleError(err, 'profile');
        }
    };

    const sendLogin = async () => {
        if (login && password) {
            setErrorLogin(false);
            setErrorPassword(false);
            const data = { username: login, password };
            setPassword('');
            // Get Auth Token
            fetch(SERVER_URL + 'token-auth-kiosk/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((result) => result.json())
                .then(async (data) => {
                    if (data.token) {
                        // If login/password are correct
                        const profile = await _getProfileInfo(data.token);
                        if (profile && profile.role === 'agent') {
                            setToken(data.token);
                            setCollectionID(data.collectionID);
                            setEmail(profile.email);
                            setName(`${profile.first_name} ${profile.last_name}`);
                            setPage('confirm');
                            setLoading(false);
                        } else {
                            setShowSnackbar(true);
                            setLoading(false);
                        }
                    } else {
                        setShowSnackbar(true);
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    if (err) {
                        handleError(err, 'login');
                        setShowSnackbar(true);
                    }
                });
        } else {
            !login ? setErrorLogin(true) : setErrorLogin(false);
            !password ? setErrorPassword(true) : setErrorPassword(false);
            setLoading(false);
        }
    };

    const sendCollection = async () => {
        try {
            const response = await fetch(SERVER_URL + 'make_collection/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                },
                body: JSON.stringify({
                    collectionID,
                }),
            });
            if (response.status === 201 || response.status === 208) {
                setPage('receipt');
                setLoading(false);
                const data = await response.json();
                if (response.status === 208) {
                    const warnMsg = `Collection was already made: ${JSON.stringify(data.data)}`;
                    // @ts-ignore
                    KioApp.LogWarn(warnMsg);
                    captureMessage(warnMsg);
                } else {
                    const msg = `Collection successful ${JSON.stringify(data.data)}`;
                    // @ts-ignore
                    KioApp.LogInfo(msg);
                }
                setData(data.data);
                setPrinterEnabled(data.printer_enabled);
            } else {
                retryCollection(response.statusText);
                return;
            }
        } catch (error) {
            retryCollection(error);
        }
    };

    const clickConfirm = async () => {
        setLoading(true);
        switch (page) {
            case 'login':
                await sendLogin();
                break;
            case 'confirm':
                await sendCollection();
                break;
        }
    };

    const btnClick = (print?: boolean) => {
        if (print) {
            if (printReceipt(data!) === false) {
                captureMessage("Couldn't print collection receipt!");
                // @ts-ignore
                KioApp.LogErr("Couldn't print collection receipt!");
            }
        }
        history.push('/');
    };

    const Confirm = () => (
        <Container id="data" maxWidth="md" className={classes.inputC}>
            <div id="data" style={{ height: '75%' }}>
                <div className="outOfOrder">
                    <div className="error">
                        <MonetizationOnIcon className={classes.icon} />
                    </div>
                    <Typography variant="h3" className={classes.note}>
                        <FormattedMessage id="admin.confirm.header" />
                    </Typography>
                    {retry ? (
                        <FormattedMessage id="admin.confirm.failed" />
                    ) : (
                        <FormattedMessage id="admin.confirm.text" />
                    )}
                </div>
            </div>
        </Container>
    );

    const Receipt = () => (
        <div id="data">
            <div className="content" style={{ height: '90%' }}>
                <div className="left">
                    <div id="receipt-table" style={{ height: 'auto' }}>
                        <Typography className={classes.q}>
                            <FormattedMessage id="admin.sent" values={{ email: email }} />
                        </Typography>
                    </div>
                </div>
                <div className="right">
                    <div className="fees marginAuto" id="receipt-table">
                        <div className="receipt-card">
                            <div className="receipt-btns">
                                <h4 className="primary primary-h4" style={{ textAlign: 'left', width: '400px' }}>
                                    <FormattedMessage id={printerEnabled ? 'admin.print' : 'admin.disabled'} />
                                </h4>

                                <Button
                                    id={printerEnabled && !printerStatusOffline ? 'btn-primary' : 'disabled'}
                                    disabled={!printerEnabled || printerStatusOffline}
                                    className="btn-recpt"
                                    onClick={() => {
                                        btnClick(true);
                                    }}
                                    variant="contained"
                                >
                                    <FormattedMessage id="receipt.print" />
                                </Button>
                                {(!printerEnabled || printerStatusOffline) && (
                                    <h4 style={{ textAlign: 'center' }}>
                                        <FormattedMessage id="method.disabled" />
                                    </h4>
                                )}
                                <Button
                                    id="btn-primary"
                                    className="btn-recpt"
                                    onClick={() => {
                                        btnClick();
                                    }}
                                    variant="contained"
                                >
                                    <FormattedMessage id="receipt.noReceipt" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        store.dispatch(setServiceType('admin'));
        // printer device status of 0 means OK
        // printer device status of 4 means low paper
        // check printer status, if 0 or 4 printer is good to print
        // if NOT 0 or 4 there is something wrong with printer, disable print button and create sentry message
        // List of printer status codes https://m.kioware.com/api/windows/8.15/javascript/kiodevicestatus
        // @ts-ignore
        const printerStatus = KioDevice.GetStatus(RECEIPT_PRINTER);
        if (printerStatus === 0 || printerStatus === 4) {
            setPrinterStatusOffline(false);
        } else {
            setPrinterStatusOffline(true);
            if (printerEnabled) {
                getPrinterStatusErrorCodes(printerStatus);
            }
        }
    }, [printerEnabled]);

    if (loading) {
        return <Loading noSnackBar />;
    }
    let header = '';
    switch (page) {
        case 'success':
            header = page.charAt(0).toUpperCase() + page.slice(1);
            break;
        case 'receipt':
            header = 'collectionComplete';
            break;
    }
    return (
        <div id="whole">
            <TimeoutWarning />
            <Warning
                open={showSnackbar}
                text="Unable to login with provided credentials"
                onClose={() => {
                    setShowSnackbar(false);
                }}
            />
            {header && <Header screen={header} />}
            {page === 'login' && (
                <Container id="data" maxWidth="xs" className={classes.inputC}>
                    <Paper elevation={3} className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" className={classes.signin}>
                            Login
                        </Typography>
                        <VKWrapper
                            id="login"
                            screen="admin"
                            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                handleLoginChange(e);
                            }}
                            size="medium"
                            value={login}
                            label="Login"
                            error={errorLogin}
                            variant="outlined"
                            fullWidth={true}
                        />
                        <VKWrapper
                            id="password"
                            screen="admin"
                            className={classes.input}
                            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                handlePasswordChange(e);
                            }}
                            value={password}
                            type="password"
                            label="Password"
                            error={errorPassword}
                            variant="outlined"
                            fullWidth={true}
                        />
                    </Paper>
                </Container>
            )}
            {page === 'confirm' && <Confirm />}
            {page === 'receipt' && <Receipt />}
            {page !== 'receipt' && (
                <Buttons
                    props={{
                        confirm: true,
                        back: true,
                        //backLink: '/',
                        // @ts-ignore
                        clickGoBack: () => history.goBack(),
                        backText: 'cancel',
                        clickConfirm: clickConfirm,
                        confirmText: retry ? 'retry' : page,
                    }}
                />
            )}
            <Footer />
        </div>
    );
};

export default Admin;
