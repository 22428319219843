import { createStore, applyMiddleware, compose } from 'redux';

// eslint-disable-next-line
import { logger } from "redux-logger";

import rootReducer from './root-reducer';

const middlewares: any[] = [
    // logger,
]; // logger,

const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)));

export default store;
