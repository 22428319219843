import { setTimeout, setIsTimedOut, Timeout } from '../types';

const INITIAL_STATE: Timeout = {
    isTimedOut: false,
    timeout: 60 * 1000,
};

const timeoutReducer = (state: Timeout = INITIAL_STATE, action: setTimeout | setIsTimedOut) => {
    switch (action.type) {
        case 'SET_TIMEOUT':
            return Object.assign({}, state, {
                timeout: action.payload,
            });
        case 'SET_ISTIMEDOUT':
            return Object.assign({}, state, {
                isTimedOut: action.payload,
            });

        default:
            return state;
    }
};

export default timeoutReducer;
