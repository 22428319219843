import { SetServiceType } from '../types';

const INITIAL_STATE = '';
const serviceTypeReducer = (state: string = INITIAL_STATE, action: SetServiceType) => {
    switch (action.type) {
        case 'SET_SERVICE_TYPE':
            return action.payload;

        default:
            return state;
    }
};

export default serviceTypeReducer;
