import React, { Component } from 'react';
import { connect } from 'react-redux';

import './receipt.style.scss';

import { getTime, getDate } from '../../components/functions';
import { stylesReceipt } from '../../components/functions';

import { getStringCreditFees } from '../../components/functions';
import { getStringCashFees } from '../../components/functions';
import { withStyles, Box } from '@material-ui/core';

import { StoreState } from '../../redux/types';
import { FormattedMessage } from 'react-intl';

interface ReceiptState {
    throwError: boolean;
}

interface ReceiptProps {
    [keyname: string]: any;
}

class ReceiptTable extends Component<ReceiptProps, ReceiptState> {
    getCC = () => {
        return (
            <p>
                <FormattedMessage id="receipt.paper.card" /> {this.props.cc.number.replace(/\d(?=\d{4})/g, '*')}
            </p>
        );
    };
    render() {
        const date = getDate();
        const time = getTime();
        let fees: { sFee?: any; pFee?: any };
        if (this.props.byCash) {
            fees = getStringCashFees(this.props.amounts.total);
        } else {
            fees = getStringCreditFees(this.props.amounts.total);
        }
        return (
            <Box
                className="box-swipe"
                boxShadow={2}
                bgcolor="background.paper"
                m={1}
                py={2}
                px={4}
                style={{
                    width: '25rem',
                    backgroundColor: 'rgba(37, 64, 143, 0.05)',
                }}
            >
                <div className="flex-center">
                    <p>{this.props.facility},</p>
                    <p>
                        {date} <FormattedMessage id="receipt.date" /> {time}
                    </p>
                </div>
                <div className="flex-left">
                    <p>
                        <FormattedMessage id={`receipt.paper.st.${this.props.serviceType}`} />
                    </p>
                    {this.props.serviceType === 'phone_service' && (
                        <p>
                            <FormattedMessage id="receipt.loadType" />:{' '}
                            <FormattedMessage id={this.props.toPhoneNumber ? 'phone_number' : 'inmate_account'} />
                        </p>
                    )}
                    <p>
                        <FormattedMessage id="receipt.inmate" />: {this.props.inmate.inmatename}
                    </p>
                    <p>PIN: {this.props.inmate.pin}</p>
                    {this.props.serviceType !== 'booking_service' && (
                        <>
                            <p>
                                <FormattedMessage id="receipt.phone" />: {this.props.phoneNumber}
                            </p>
                        </>
                    )}
                    <p>
                        <FormattedMessage id="receipt.paymentType" />:{' '}
                        <FormattedMessage id={this.props.byCash ? 'method.cash' : 'method.credit'} />
                    </p>
                    {this.props.byCash ? '' : this.getCC()}
                </div>
                <div className="receipt-fees">
                    <div className="left-column">
                        <p className="bold">
                            <FormattedMessage id="receipt.deposit" />:
                        </p>
                        {this.props.serviceType !== 'booking_service' && (
                            <>
                                <p>
                                    <FormattedMessage id="receipt.service" />:
                                </p>
                                <p>
                                    <FormattedMessage id="receipt.processing" />:
                                </p>
                                <p>
                                    <FormattedMessage id="receipt.total" />:
                                </p>
                            </>
                        )}
                    </div>
                    <div className="right-column">
                        <p className="bold">${parseFloat(this.props.amounts.deposit).toFixed(2)}</p>
                        {this.props.serviceType !== 'booking_service' && (
                            <>
                                <p>{fees.sFee ? fees.sFee : '$0.00'}</p>
                                <p>{fees.pFee ? fees.pFee : '$0.00'}</p>
                                <p> ${parseFloat(this.props.amounts.total).toFixed(2)}</p>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex-left">
                    <p>
                        <FormattedMessage id="receipt.kiosk" />: {this.props.kioskName}
                    </p>
                    <p style={{ marginBottom: '10px' }}>
                        <FormattedMessage id="receipt.trans" />: {this.props.transactionID}
                    </p>
                </div>
            </Box>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    fees: state.fees,
    facility: state.facility,
    inmate: state.inmate,
    amounts: state.amounts,
    phoneNumber: state.phoneNumber,
    byCash: state.byCash,
    transactionID: state.transactionID,
    toPhoneNumber: state.toPhoneNumber,
    cc: state.cc,
    kioskName: state.kioskName,
    serviceType: state.serviceType,
});

export default withStyles(stylesReceipt)(connect(mapStateToProps)(ReceiptTable));
