import React, { Component, ChangeEvent } from 'react';

import './phone.style.scss';

import Footer from '../../components/footer/footer.component';

import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import PhoneField from '../../components/phoneField';
import TimeoutWarning from '../../components/timeout/timeout.component';
import { numberPrettifier } from '../../components/functions';

import { StoreState } from '../../redux/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import setPhoneNumber from '../../redux/phoneNumber/phoneNumber.action';
import Loading from '../../components/loading/loading.component';
import Warning from '../../components/toast';
import checkRestrictedPhoneNumbers from '../../components/restrictedPhoneNumbers';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

type PhoneProps = {
    [keyname: string]: any;
} & WrappedComponentProps;

interface PhoneState {
    number: string;
    inputError: boolean;
    numberCorrect: boolean;
    nextPage: string;
    helperText: string;
    loading: boolean;
    error?: string;
}

class Phone extends Component<PhoneProps, PhoneState> {
    constructor(props: PhoneProps) {
        super(props);
        this.state = {
            number: '',
            inputError: false,
            numberCorrect: false,
            nextPage: '',
            helperText: '',
            loading: false,
            error: '',
        };
    }

    //Remebering previous state
    componentDidMount() {
        const phone = numberPrettifier(this.props.phoneNumber);
        this.setState({
            number: phone,
            numberCorrect: this.props.phoneNumber.length === 10,
            nextPage: '/address/',
        });
    }

    handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const el = e.target as HTMLInputElement;
        const number = el.value.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3');

        //Don't allow to go on the next page if number is not full
        const result = numberPrettifier(number);
        this.setState({ number: result ? result : '' });
        if (!result) {
            return;
        }
        const numCheck = result.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3').length;
        if (numCheck === 10) {
            this.setState({ numberCorrect: true, nextPage: '/address/', inputError: false });
        } else {
            this.setState({ numberCorrect: false, nextPage: '' });
        }
    };

    clickConfirm = async () => {
        const number = this.state.number.replace(/\D*(\d*)\D*(\d*)\D*(\d{0,4})\D*/, '$1$2$3');

        if (this.state.numberCorrect) {
            this.setState({ loading: true });
            // Check with NCIC backend if number is not forbidden
            const restrictedReason = await checkRestrictedPhoneNumbers(number, this.props.apiKey, false);
            if (restrictedReason) {
                this.setState({ loading: false, error: restrictedReason });
            } else {
                this.setState({ loading: false });
                this.props.setPhoneNumber(number);
                this.props.history.push(this.state.nextPage);
            }
        } else {
            this.setState({ inputError: true });
        }
    };

    render() {
        const { loading, error } = this.state;
        if (loading) {
            return <Loading />;
        }
        return (
            <div id="whole">
                <TimeoutWarning />
                <Warning
                    text={error ? this.props.intl.formatMessage({ id: error }) : ''}
                    open={Boolean(error)}
                    onClose={() => {
                        this.setState({ error: '' });
                    }}
                />
                <SnackBar />
                <Header screen="phone" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div className="phone-number">
                                <PhoneField
                                    autoFocus={!this.props.phoneNumber}
                                    handleChange={this.handleChange}
                                    value={this.state.number}
                                    inputError={this.state.inputError}
                                />
                            </div>
                        </div>
                        <div className="right">
                            <div className="content-funds" style={{ paddingLeft: '20px' }}>
                                <div className="number" id="toggled">
                                    <h3 className="primary">
                                        <FormattedMessage id="phone.helper.header" />
                                    </h3>
                                    <h5>
                                        <FormattedMessage id="phone.helper.text" />
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickConfirm,
                        confirm: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setPhoneNumber: (phoneNumber: string) => dispatch(setPhoneNumber(phoneNumber)),
});

const mapStateToProps = (state: StoreState) => ({
    phoneNumber: state.phoneNumber,
    showKeyboard: state.showKeyboard,
    loadType: state.loadType,
    apiKey: state.apiKey,
});

export default connect(mapStateToProps, mapDispatchFromProps)(injectIntl(Phone));
