import React, { Component, ChangeEvent } from 'react';

import VKWrapper from '../../components/VKWrapper';
import countries from './countries.data';
import TextField from '@material-ui/core/TextField';
import HomeIcon from '@material-ui/icons/Home';
import MenuItem from '@material-ui/core/MenuItem';

import { StoreState } from '../../redux/types';
import { connect } from 'react-redux';

import { Address } from '../../redux/types';

import { INITIAL_STATE, AddressState } from './address.states';

import { withStyles } from '@material-ui/core';
import { styles } from '../../components/functions';
import { FormattedMessage } from 'react-intl';

const clone = (dict: Address) => {
    return JSON.parse(JSON.stringify(dict));
};

interface AddressProps {
    [keyname: string]: any;
    clicked?: boolean;
    autoFocus?: boolean;
}

class AddressForm extends Component<AddressProps, AddressState> {
    componentDidUpdate() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    componentDidMount() {
        let value;
        let prop;
        for (const key in this.props.address) {
            prop = this.state[key];
            value = this.props.address[key];
            prop.value = value;
            prop.correct = value.length > 0;
            this.setState({ [key]: prop });
        }
    }

    constructor(props: AddressProps) {
        super(props);

        this.state = INITIAL_STATE;
    }

    stripLetters = (inp: string) => {
        return inp.replace(/[^a-zA-Z ]*([a-zA-Z ]*)[^a-zA-Z ]*/, '$1');
    };

    stripNumbers = (inp: string) => {
        return inp.replace(/\D*(\d*)\D*/, '$1');
    };

    stripZIP = (inp: string) => {
        return inp.replace(/(^[0-9]{0,10}).*/, '$1');
    };

    handleChange(data: AddressProps) {
        const param = data.param;
        const copy = clone(this.state[param]);
        const value = data.e.target.value.trimStart();
        switch (param) {
            case 'firstName':
                copy.value = this.stripLetters(value).slice(0, 70);
                break;
            case 'lastName':
                copy.value = this.stripLetters(value).slice(0, 80);
                break;
            case 'address':
                copy.value = value.slice(0, 80);
                break;
            case 'zip':
                copy.value = this.stripZIP(value);
                if (/^[0-9]{5,10}/.test(copy.value)) {
                    copy.correct = true;
                } else {
                    copy.correct = false;
                }
                break;
            case 'city':
                copy.value = this.stripLetters(value).slice(0, 80);
                break;
            case 'st':
                copy.value = this.stripLetters(value).slice(0, 2);
                copy.correct = copy.value.length === 2;
                break;
            case 'country':
                copy.value = value;
                copy.correct = true;
                break;
        }
        if (param !== 'st' && param !== 'zip' && param !== 'country') {
            if (copy.value.length > 0) {
                copy.correct = true;
            } else {
                copy.correct = false;
            }
        }
        this.setState({ [param]: copy });
    }

    render() {
        const errors = {
            firstName: !this.state.firstName.correct && this.props.clicked,
            lastName: !this.state.lastName.correct && this.props.clicked,
            address: !this.state.address.correct && this.props.clicked,
            zip: !this.state.zip.correct && this.props.clicked,
            city: !this.state.city.correct && this.props.clicked,
            st: !this.state.st.correct && this.props.clicked,
            country: !this.state.country.correct && this.props.clicked,
        };
        return (
            <>
                {/* <HomeIcon fontSize="large" style = {{marginTop: '85px', marginRight: '20px'}}/> */}
                <div className="address-form">
                    <div className="name">
                        <VKWrapper
                            screen="address"
                            id="firstName"
                            autoFocus={this.props.autoFocus}
                            className="addressInput namead"
                            error={errors.firstName}
                            helperText={errors.firstName ? this.state.firstName.helperText : ' '}
                            onChange={(e) => {
                                this.handleChange({ e: e, param: 'firstName' });
                            }}
                            value={this.state.firstName.value}
                            label="First Name"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: this.props.classes.resize,
                                    notchedOutline: this.props.classes.label,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: this.props.classes.label,
                                    shrink: this.props.classes.focused,
                                },
                            }}
                        />
                        <VKWrapper
                            screen="address"
                            id="lastName"
                            className="addressInput namead"
                            error={errors.lastName}
                            helperText={errors.lastName ? this.state.firstName.helperText : ' '}
                            onChange={(e) => {
                                this.handleChange({ e: e, param: 'lastName' });
                            }}
                            value={this.state.lastName.value}
                            label="Last Name"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: this.props.classes.resize,
                                    notchedOutline: this.props.classes.label,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: this.props.classes.label,
                                    shrink: this.props.classes.focused,
                                },
                            }}
                        />
                    </div>

                    <div className="addressLine">
                        <VKWrapper
                            screen="address"
                            id="address"
                            fullWidth
                            typeKeyboard="search"
                            className="addressInput addressIn"
                            error={errors.address}
                            helperText={errors.address ? this.state.firstName.helperText : ' '}
                            onChange={(e) => {
                                this.handleChange({ e: e, param: 'address' });
                            }}
                            value={this.state.address.value}
                            label="Address"
                            variant="outlined"
                            InputProps={{
                                endAdornment: <HomeIcon fontSize="large" className="primary" />,
                                classes: {
                                    input: this.props.classes.resize,
                                    notchedOutline: this.props.classes.label,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: this.props.classes.label,
                                    shrink: this.props.classes.focused,
                                },
                            }}
                        />
                    </div>

                    <div className="zcs">
                        <VKWrapper
                            screen="address"
                            id="zip"
                            typeKeyboard="number"
                            className="addressInput zc"
                            error={errors.zip}
                            helperText={errors.zip ? this.state.zip.helperText : ' '}
                            labelValue={{ number: 5 }}
                            onChange={(e) => {
                                this.handleChange({ e: e, param: 'zip' });
                            }}
                            value={this.state.zip.value}
                            label="ZIP"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: this.props.classes.resize,
                                    notchedOutline: this.props.classes.label,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: this.props.classes.label,
                                    shrink: this.props.classes.focused,
                                },
                            }}
                        />
                        <VKWrapper
                            screen="address"
                            id="city"
                            className="addressInput zc"
                            error={errors.city}
                            helperText={errors.city ? this.state.firstName.helperText : ' '}
                            onChange={(e) => {
                                this.handleChange({ e: e, param: 'city' });
                            }}
                            value={this.state.city.value}
                            label="City"
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    input: this.props.classes.resize,
                                    notchedOutline: this.props.classes.label,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: this.props.classes.label,
                                    shrink: this.props.classes.focused,
                                },
                            }}
                        />
                        <VKWrapper
                            screen="address"
                            id="state"
                            value={this.state.st.value}
                            label="State"
                            variant="outlined"
                            error={errors.st}
                            helperText={errors.st ? this.state.firstName.helperText : ' '}
                            onChange={(e: ChangeEvent<{}>) => {
                                this.handleChange({ e: e, param: 'st' });
                            }}
                            InputProps={{
                                classes: {
                                    root: this.props.classes.state,
                                    input: this.props.classes.resize,
                                    notchedOutline: this.props.classes.label,
                                },
                            }}
                            SelectProps={{
                                classes: {
                                    select: this.props.classes.stateSelect,
                                },
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: this.props.classes.label,
                                    shrink: this.props.classes.focused,
                                },
                            }}
                        />
                    </div>
                    <div className="addressLine">
                        <FormattedMessage id="address.country">
                            {(msg: string) => (
                                <TextField
                                    select
                                    id="country"
                                    fullWidth
                                    className="addressInput addressIn"
                                    error={errors.country}
                                    helperText={errors.country ? this.state.firstName.helperText : ' '}
                                    onChange={(e) => {
                                        this.handleChange({ e: e, param: 'country' });
                                    }}
                                    value={this.state.country.value}
                                    label={msg}
                                    variant="outlined"
                                    InputProps={{
                                        classes: {
                                            input: `${this.props.classes.resize} ${this.props.classes.country}`,
                                            notchedOutline: this.props.classes.label,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: this.props.classes.label,
                                            shrink: this.props.classes.focused,
                                        },
                                    }}
                                >
                                    {countries.map((country) => {
                                        return (
                                            <MenuItem key={country.abbreviation} value={country.abbreviation}>
                                                {country.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            )}
                        </FormattedMessage>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    address: state.address,
});

export default withStyles(styles)(connect(mapStateToProps)(AddressForm));
