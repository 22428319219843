import { SetPhoneNumber } from '../types';

const INITIAL_STATE = '';

const phoneNumberReducer = (state: string = INITIAL_STATE, action: SetPhoneNumber) => {
    switch (action.type) {
        case 'SET_PHONE_NUMBER':
            return action.payload;

        default:
            return state;
    }
};

export default phoneNumberReducer;
