import { setToPhoneNumber } from '../types';

const INITIAL_STATE = false;

const toPhoneNumberReducer = (state: boolean = INITIAL_STATE, action: setToPhoneNumber) => {
    switch (action.type) {
        case 'SET_PAYMENT_STATE':
            return action.payload;

        default:
            return state;
    }
};

export default toPhoneNumberReducer;
