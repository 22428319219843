import { setLoadType } from '../types';

const INITIAL_STATE = 0;

const loadTypeReducer = (state: number = INITIAL_STATE, action: setLoadType) => {
    switch (action.type) {
        case 'SET_LOAD_TYPE':
            return action.payload;

        default:
            return state;
    }
};

export default loadTypeReducer;
