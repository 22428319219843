import store from '../redux/store';
import { setShowKeyboard } from '../redux/showKeyboard/showKeyboard.action';

const useScript = (url: string) => {
    const script = document.createElement('script');

    script.innerText = url;
    script.async = true;

    document.body.appendChild(script);
};

const CloseKeyboard = () => {
    store.dispatch(setShowKeyboard(false));
    useScript('KioApp.CloseKeyboard()');
};
export default CloseKeyboard;
