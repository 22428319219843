import React, { Component } from 'react';

import './sent.style.scss';

import Footer from '../../components/footer/footer.component';
import Header from '../../components/header';
import Buttons from '../../components/buttons';
import ReceiptTable from '../../components/receipt/receipt.component';
import { stylesReceipt } from '../../components/functions';

import { withStyles } from '@material-ui/core';

import TimeoutWarning from '../../components/timeout/timeout.component';
import { FormattedMessage } from 'react-intl';

import { StoreState } from '../../redux/types';
import { connect } from 'react-redux';

interface SentProps {
    [keyname: string]: any;
}

class Sent extends Component<SentProps> {
    createData(name: string, value: string) {
        return { name, value };
    }

    btnClick = () => {
        this.props.history.push('/');
    };

    render() {
        return (
            <div id="whole">
                <TimeoutWarning text="timeout.redirect" />
                <Header screen="sent" />
                <div id="data">
                    <div className="content">
                        <div className="left">
                            <div className="marginAuto">
                                <ReceiptTable />
                            </div>
                        </div>
                        <div className="right">
                            <div className="confirm-send">
                                <h3 className="primary">
                                    {this.props.serviceType !== 'booking_service' ? (
                                        <FormattedMessage id="sent.helper" />
                                    ) : (
                                        <FormattedMessage id="sent.helper.bookingService" />
                                    )}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickConfirm: this.btnClick,
                        confirm: true,
                        confirmText: 'startOver',
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state: StoreState) => ({
    serviceType: state.serviceType,
});

export default withStyles(stylesReceipt)(connect(mapStateToProps)(Sent));
