import { setSwiped } from '../types';

const INITIAL_STATE = false;

const swipedReducer = (state: boolean = INITIAL_STATE, action: setSwiped) => {
    switch (action.type) {
        case 'SET_SWIPED':
            return action.payload;

        default:
            return state;
    }
};

export default swipedReducer;
