import { Component } from 'react';
import React from 'react';

import { FormattedMessage } from 'react-intl';

type Props = {
    amounts?: any;
    fees?: any;
    byCash?: any;
    total?: any;
    deposit?: any;
    serviceType?: any;
    titleId?: string;
    sFee?: any;
    pFee?: any;
    renderLeftSide: Function;
    renderRightSide: Function;
};

class FeesContainer extends Component<Props> {
    render() {
        return (
            <div className="fees">
                <>
                    <h3 className="primary">
                        <FormattedMessage id={this.props.titleId} />
                    </h3>
                    <div className="fees-table">
                        <div className="left-fees">{this.props.renderLeftSide(this.props)}</div>
                        <div className="right-fees">{this.props.renderRightSide(this.props)}</div>
                    </div>
                </>
            </div>
        );
    }
}

export default FeesContainer;
