import React, { Component } from 'react';
import $ from 'jquery';
import './jquery.cardswipe';

import './swipe.style.scss';

import Footer from '../../components/footer/footer.component';

import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import Fees from '../../components/fees';

import Card from '../../components/img/card.svg';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState, CC as CCn } from '../../redux/types';
import setCC from '../../redux/cc/cc.action';
import setSwiped from '../../redux/swiped/swiped.action';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import TimeoutWarning from '../../components/timeout/timeout.component';
import Loading from '../../components/loading/loading.component';
import { addBreadcrumb, Severity } from '@sentry/react';
import { FormattedMessage } from 'react-intl';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const doNothing = () => {
    // prevents cardswipe from default behaviour
};

interface CC {
    error: boolean;
    number: string;
    firstName: string;
    lastName: string;
    cvc?: string;
    date: string;
}

interface SwipeProps {
    [keyname: string]: any;
}

interface SwipeState {
    swiped: boolean;
    swipedError: boolean;
    processError: boolean;
    loading: boolean;
    scanAttempt: number;
}

class Swipe extends Component<SwipeProps, SwipeState> {
    _isMounted = false;
    constructor(props: any) {
        super(props);
        this.state = {
            swiped: false,
            swipedError: false,
            processError: false,
            loading: false,
            scanAttempt: 0,
        };
    }

    componentDidMount = () => {
        this._isMounted = true;

        // @ts-ignore
        $.cardswipe({
            firstLineOnly: false,
            success: this.cardScanSuccessHandler,
            failure: this.cardScanFailureHandler,
            parsers: ['visa', 'mastercard', 'amex', 'discover'],
            error: () => {
                addBreadcrumb({
                    category: 'CC READER',
                    message: 'Failed to read card',
                    level: Severity.Error,
                });
                this.cardScanFailureHandler();
            },
            debug: false,
        });
        $(document)
            .on('scanstart.cardswipe', this.cardScanStartHandler)
            .on('scanend.cardswipe', this.cardScanEndHandler);
        if (this.props.location.state) {
            this.setState({ processError: true });
        }
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        // @ts-ignore
        $.cardswipe({
            firstLineOnly: false,
            success: doNothing,
            failure: doNothing,
            parsers: ['visa', 'mastercard', 'amex', 'discover', 'generic'],
            error: doNothing,
            debug: false,
        });
    };

    cardScanFailureHandler = () => {
        if (this._isMounted) {
            this.setState({ swipedError: true }, () => {
                const currentAttempt = this.state.scanAttempt;
                setInterval(() => {
                    if (this._isMounted) {
                        if (currentAttempt === this.state.scanAttempt) {
                            this.setState({ swipedError: false });
                        }
                    }
                }, 5000);
            });
        }
    };

    cardScanStartHandler = () => {
        if (this._isMounted) {
            const atmpt = this.state.scanAttempt + 1;
            this.setState({ loading: true, scanAttempt: atmpt });
        }
    };
    cardScanEndHandler = () => {
        if (this._isMounted) {
            this.setState({ loading: false });
        }
    };

    cardScanSuccessHandler = (data: any) => {
        const scannedCard = {
            error: false,
            number: data['account'],
            firstName: data['firstName'],
            lastName: data['lastName'],
            date: `${data['expMonth']}/${data['expYear']}`,
        };
        this.setCC(scannedCard);
        this.props.setSwiped(true);
    };

    setCC = (data: CC) => {
        if (this._isMounted) {
            if (data.error) {
                this.setState({ swipedError: true });
            } else {
                const cc = data;
                // @ts-ignore
                delete cc.error;
                this.props.setCC(cc);
                this.setState({ swiped: true, swipedError: false });
            }
        }
    };

    componentDidUpdate() {
        if (this.state.swiped && !this.state.swipedError) {
            this._isMounted = false;
            this.props.setSwiped(true);
            this.props.history.push('/cc/');
        }
    }

    clickLink = () => {
        this.props.setCC({ number: '', name: '', cvc: '', date: '' });
        this.props.setSwiped(false);
        this.props.history.push('/cc/');
    };

    render() {
        if (this.state.loading) {
            // return <h1>Hi</h1>
            return <Loading description="loading.readingCard" />;
        } else {
            return (
                <div id="whole">
                    <TimeoutWarning />
                    <Snackbar
                        style={{ marginTop: '130px' }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={this.state.swipedError}
                        onClose={() => this.setState({ swiped: false, swipedError: false })}
                    >
                        <Alert onClose={() => this.setState({ swiped: false, swipedError: false })} severity="warning">
                            <FormattedMessage id="swipe.error.swipeAgain" />
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        style={{ marginTop: '250px' }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={this.state.processError}
                        onClose={() => this.setState({ swiped: false, processError: false })}
                    >
                        <Alert onClose={() => this.setState({ swiped: false, processError: false })} severity="error">
                            <FormattedMessage id="swipe.error.declined" />
                        </Alert>
                    </Snackbar>
                    <SnackBar />
                    <Header screen="swipe" />
                    <div id="data">
                        <div className="content">
                            <div className="left">
                                <div className="content">
                                    <div className="marginAuto">
                                        <img src={Card} width="350" alt="" />
                                        <h5
                                            onClick={() => {
                                                this.clickLink();
                                            }}
                                            className="link center"
                                        >
                                            <FormattedMessage id="swipe.link.manual" />
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="right right-swipe">
                                <div className="content">
                                    <div className="marginAuto">
                                        <Fees byCash={false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Buttons
                        props={{
                            clickGoBack: () => this.props.history.goBack(),
                            back: true,
                        }}
                    />
                    <Footer />
                </div>
            );
        }
    }
}

const mapStateToProps = (state: StoreState) => ({
    amounts: state.amounts,
});

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setCC: (cc: CCn) => dispatch(setCC(cc)),
    setSwiped: (swiped: boolean) => dispatch(setSwiped(swiped)),
});

export default connect(mapStateToProps, mapDispatchFromProps)(Swipe);
