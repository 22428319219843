import React, { Component } from 'react';

import '../address/address.style.scss';

import Footer from '../../components/footer/footer.component';

import Header from '../../components/header';
import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import AddressForm from '../address/address.form';
import CloseKeyboard from '../../components/useFunction';
import TimeoutWarning from '../../components/timeout/timeout.component';

import { connect } from 'react-redux';

import setPhoneNumber from '../../redux/phoneNumber/phoneNumber.action';
import setAddress from '../../redux/billingAddress/address.action';

import { Address as Adr } from '../../redux/types';
import { Dispatch } from 'redux';
import { StoreState } from '../../redux/types';

import { INITIAL_STATE, AddressState as AS } from '../address/address.states';

interface AddressProps {
    [keyname: string]: any;
}
interface AddressState {
    errors: string[];
    clicked: boolean;
    address: AS;
}

class ConfirmAddress extends Component<AddressProps, AddressState> {
    constructor(props: AddressProps) {
        super(props);
        this.state = {
            errors: [],
            clicked: false,
            address: INITIAL_STATE,
        };
    }

    createAddress = (data: AS) => {
        const address: Adr = {
            firstName: '',
            lastName: '',
            address: '',
            zip: '',
            city: '',
            st: '',
        };
        for (const key in data) {
            address[key] = data[key].value;
        }
        return address;
    };

    clickConfirm = () => {
        //Check fields, close keyboard and throw error if something is not filled
        const copy = [];
        this.setState({ errors: [], clicked: true });

        //Address
        const address = this.state.address;
        if (address) {
            //Check if all fields are filled
            for (const key of Object.keys(address)) {
                if (!address[key].correct) {
                    copy.push(key);
                }
            }
        } else {
            copy.push('address');
        }
        //Check if in KioWare if so - close keyboard
        if (/KioWare/i.test(navigator.userAgent)) {
            CloseKeyboard();
        }
        this.setState({ errors: copy }, () => {
            if (this.state.errors.length === 0) {
                this.props.setAddress(this.createAddress(this.state.address));
                this.props.history.push('/swipe/');
            }
        });
    };

    eventhandler = (data: AS) => {
        this.setState({ address: data });
    };

    render() {
        return (
            <div id="whole">
                <TimeoutWarning />
                <SnackBar />
                <div className="heads">
                    <Header screen="confirmAddress" />
                </div>
                <div id="data">
                    <div className="content">
                        <div className="right-address" id="single">
                            <div className="content">
                                <div className="add">
                                    <div className="moveUp">
                                        <AddressForm
                                            onChange={this.eventhandler}
                                            errors={this.state.errors}
                                            clicked={this.state.clicked}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Buttons
                    props={{
                        clickGoBack: () => this.props.history.goBack(),
                        clickConfirm: this.clickConfirm,
                        confirm: true,
                        back: true,
                    }}
                />
                <Footer />
            </div>
        );
    }
}

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setPhoneNumber: (phoneNumber: string) => dispatch(setPhoneNumber(phoneNumber)),
    setAddress: (address: Adr) => dispatch(setAddress(address)),
});
const mapStateToProps = (state: StoreState) => ({
    inmate: state.inmate,
    toPhoneNumber: state.toPhoneNumber,
    phoneNumber: state.phoneNumber,
    address: state.address,
});

export default connect(mapStateToProps, mapDispatchFromProps)(ConfirmAddress);
