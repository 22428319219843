import React, { Component } from 'react';

import './cc.style.scss';

import Footer from '../../components/footer/footer.component';

import Buttons from '../../components/buttons';
import SnackBar from '../../components/snackbar/snackbar.component';
import {
    datePrettifier,
    getFirstLastName,
    getNumberCreditFees,
    getNumberServiceType,
    sendTransaction,
    TransactionData,
} from '../../components/functions';
import setAddress from '../../redux/billingAddress/address.action';
import TimeoutWarning from '../../components/timeout/timeout.component';
import Header from '../../components/header';
import Fees from '../../components/fees';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState, CC as CCn } from '../../redux/types';
import { Address as Adr } from '../../redux/types';
import setCC from '../../redux/cc/cc.action';

import { addBreadcrumb, captureMessage, Severity } from '@sentry/react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import VKWrapper from '../../components/VKWrapper';

import { INITIAL_STATE_CC } from '../address/address.states';
import { capitalize, withStyles } from '@material-ui/core';

import setAmounts from '../../redux/amounts/amounts.action';
import setTransactionID from '../../redux/transactionID/transactionID.action';
import Loading from '../../components/loading/loading.component';
import setErrorText from '../../redux/errorText/errorText.action';
import {
    CREDIT_PHONE_DEPOSIT_URL,
    CREDIT_INMATE_DEPOSIT_URL,
    CREDIT_COMMISSARY_DEPOSIT_URL,
    MAX_ATTEMPTS,
    TIME_BETWEEN_ATTEMPTS,
    SUCCESS_REPORT_MESSAGE,
    ERROR_REPORT_MESSAGE,
} from '../../components/global';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';

function ServerError(this: any, message: string) {
    this.name = 'Server Error';
    this.message = message;
}

ServerError.prototype = new Error();

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const clone = (dict: CCinState) => {
    return JSON.parse(JSON.stringify(dict));
};

const styles = () => ({
    //style for font size
    resize: {
        fontSize: '25px',
    },
    label: {
        fontSize: '20px',
    },
    state: {
        height: '73px',
    },
    readOnly: {
        color: 'rgba(37, 64, 143, 0.6)',
    },
});

interface CCinState {
    error: boolean;
    number: Field;
    firstName: Field;
    lastName: Field;
    cvc: Field;
    date: Field;
}

interface Field {
    value: string;
    helperText: string;
    inputError: boolean;
    correct: boolean;
}

type Props = {
    setCC: Function;
    setAddress: Function;
    setAmounts: Function;
    setTransactionID: Function;
    setErrorText: Function;
    toPhoneNumber: boolean;
    amounts: any;
    fees: any;
    cc: any;
    swiped: any;
    address?: any;
    classes: any;
    timeouts: any;
    transactionID: string;
    inmate: any;
    phoneNumber: any;
    apiKey?: string;
    serviceType?: string;
    captureImage?: string;
} & RouteComponentProps;

interface CCState {
    [keyname: string]: any;
    errors: string[];
    cc: CCinState;
    swiped: boolean;
    swipedError: boolean;
    error: boolean;
    attempt: number;
}

class CCpage extends Component<Props, CCState> {
    constructor(props: any) {
        super(props);
        this.state = {
            errors: [],
            errorsCC: [],
            cc: INITIAL_STATE_CC,
            swiped: false,
            swipedError: false,
            clicked: false,
            loading: true,
            error: false,
            attempt: 0,
        };
    }

    handleError = async (error: any) => {
        addBreadcrumb({
            category: 'Response',
            message: JSON.stringify(error),
            level: Severity.Info,
        });
        if (this.state.attempt === MAX_ATTEMPTS - 1) {
            this.props.setErrorText('an error occurred processing your payment');
            const WinLog = `Transaction ID: ${this.props.transactionID}\nInmate ID: ${this.props.inmate.id}\nInmate Name: ${this.props.inmate.inmatename}\nInmate PIN: ${this.props.inmate.pin}\nAmount: ${this.props.amounts.total}\nPayment Type: Credit Card\nService Type: ${this.props.serviceType}`;
            // @ts-ignore
            KioApp.LogErr(`${ERROR_REPORT_MESSAGE}: ${error}\n${WinLog}`);
            // Send to backend
            await this._sendTransaction('Failed sending payment to NCIC servers.');
            this.setState({ error: true, loading: false });
        } else {
            this.setState({ attempt: this.state.attempt + 1 }, () => {
                setTimeout(() => {
                    this.payAndRedirect();
                }, TIME_BETWEEN_ATTEMPTS);
            });
        }
    };

    _sendTransaction = async (error?: string) => {
        const { sFee, pFee } = getNumberCreditFees(this.props.amounts.total);
        const { firstName, lastName } = getFirstLastName(this.props.inmate.inmatename);
        const serviceType = getNumberServiceType(this.props.serviceType!);
        const ccNumber = this.props.swiped ? this.props.cc.number : this.state.cc.number.value;
        let data: TransactionData = {
            id: this.props.transactionID,
            inmate_id: this.props.inmate.id,
            inmate_first_name: firstName,
            inmate_last_name: lastName,
            pin: this.props.inmate.unmaskedpin,
            phone: this.props.phoneNumber,
            payment_type: 0,
            bills: [],
            coin: [],
            subtotal: this.props.amounts.deposit,
            service_fee: sFee,
            card_number: ccNumber.substr(-4),
            processing_fee: pFee,
            total: this.props.amounts.total,
            ps_load_type: this.props.toPhoneNumber ? 1 : 0,
            service_type: serviceType,
            photo: this.props.captureImage,
        };
        if (error) {
            data = { ...data, successful: false, result_description: capitalize(error) };
        }
        await sendTransaction({ ...data, str_service_type: this.props.serviceType });
    };

    payAndRedirect = () => {
        let { sFee, pFee } = getNumberCreditFees(this.props.amounts.total);
        // To prevent fees from being 'null'
        sFee = sFee ? sFee : 0;
        pFee = pFee ? pFee : 0;
        const body = {
            apikey: this.props.apiKey,
            amount: this.props.amounts.total,
            transactionid: this.props.transactionID,
            depositorfirstname: this.props.swiped ? this.props.cc.firstName : this.state.cc.firstName.value.trim(),
            depositorlastname: this.props.swiped ? this.props.cc.lastName : this.state.cc.lastName.value.trim(),
            depositorphone: this.props.phoneNumber,
            depositoraddress: this.props.address.address,
            depositorcity: this.props.address.city,
            depositorstate: this.props.address.st,
            depositorzip: this.props.address.zip,
            cardnumber: this.props.swiped ? this.props.cc.number : this.state.cc.number.value,
            paymonth: this.props.swiped ? this.props.cc.date.slice(0, 2) : this.state.cc.date.value.slice(0, 2),
            payyear: this.props.swiped ? this.props.cc.date.slice(3) : this.state.cc.date.value.slice(3),
            cvv: this.state.cc.cvc.value,
            depositorcountry: this.props.address.country,
            processingfee: pFee,
            servicefee: sFee,
        };
        const bodyWithoutKey = cloneDeep(body);
        delete bodyWithoutKey.apikey;
        // @ts-ignore
        delete bodyWithoutKey.cvv;
        bodyWithoutKey.cardnumber = bodyWithoutKey.cardnumber.replace(/^.*?(\d{4})$/, '*$1');
        if (this.props.toPhoneNumber) {
            // @ts-ignore
            body.number = this.props.phoneNumber;
        } else {
            // @ts-ignore
            body.id = this.props.inmate.id;
        }
        addBreadcrumb({
            category: 'Request',
            message: JSON.stringify(bodyWithoutKey),
            level: Severity.Info,
        });
        let URL = '';
        switch (this.props.serviceType) {
            case 'commissary_service':
                URL = CREDIT_COMMISSARY_DEPOSIT_URL;
                break;
            case 'phone_service':
                URL = this.props.toPhoneNumber ? CREDIT_PHONE_DEPOSIT_URL : CREDIT_INMATE_DEPOSIT_URL;
                break;
            default:
                captureMessage('Unknown service type');
                break;
        }
        fetch(
            //Change endpoint regarding type of service
            URL,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this._redirect(data);
            })
            .catch((error) => {
                this.handleError(error);
            });
    };

    _redirect = async (data: any) => {
        //breadcrumb response
        addBreadcrumb({
            category: 'Response',
            message: JSON.stringify(data),
            level: Severity.Info,
        });
        // If operation was succesful
        if (data.code === '1') {
            this.props.setTransactionID(data.transactionid);
            const WinLog = `Transaction ID: ${this.props.transactionID}\nInmate ID: ${this.props.inmate.id}\nInmate Name: ${this.props.inmate.inmatename}\nInmate PIN: ${this.props.inmate.pin}\nAmount: ${this.props.amounts.total}\nPayment Type: Credit Card\nService Type: ${this.props.serviceType}`;
            // @ts-ignore
            KioApp.LogInfo(`${SUCCESS_REPORT_MESSAGE}: \n${WinLog}`);
            await this._sendTransaction();
            if (!this.props.swiped) {
                this.props.setCC({ number: this.state.cc.number.value });
            }
            this.props.history.push('/receipt/');
        }
        // If transaction failed
        else {
            // WRONG DATA
            if (/cc transaction failed/.test(data.text)) {
                addBreadcrumb({
                    category: 'Response',
                    message: JSON.stringify(data),
                    level: Severity.Error,
                });
                this.props.setTransactionID(data.transactionid);
                const WinLog = `Transaction ID: ${this.props.transactionID}\nInmate ID: ${this.props.inmate.id}\nInmate Name: ${this.props.inmate.inmatename}\nInmate PIN: ${this.props.inmate.pin}\nAmount: ${this.props.amounts.total}\nPayment Type: Credit Card\nService Type: ${this.props.serviceType}\nReason: Credit card declined`;
                // @ts-ignore
                KioApp.LogInfo(`${ERROR_REPORT_MESSAGE}: \n${WinLog}`);
                // Send transaction to backend
                await this._sendTransaction('Credit card declined');
                this.setState({ swipedError: true, loading: false });
            }
            // SERVER ERROR
            else {
                this.handleError(data.text);
            }
        }
    };

    clickConfirm = () => {
        let copy = [];
        let key;
        this.setState({ errors: [], errorsCC: [], clicked: true });
        copy = [];
        for (key of Object.keys(this.state.cc)) {
            if (!this.state.cc[key].correct && key !== 'error') {
                copy.push(key);
            }
        }
        this.setState({ errorsCC: copy }, () => {
            if (this.state.errorsCC.length === 0) {
                //Send CC information
                //if error setState(swipeError: true)
                this.setState({ loading: true });
                this.payAndRedirect();
                // this.props.history.push('/receipt/');
            }
        });
    };

    stripLetters = (inp: string) => {
        return inp.replace(/[^a-zA-Z]*([a-zA-Z]*)[^a-zA-Z]*/, '$1');
    };

    stripNumbers = (inp: string) => {
        return inp.replace(/\D*(\d*)\D*/, '$1');
    };

    componentDidMount() {
        if (this.props.swiped) {
            this.payAndRedirect();
        } else {
            this.setState({ loading: false });
            const CC = INITIAL_STATE_CC;
            CC.firstName.value = this.props.address.firstName;
            CC.firstName.correct = true;
            CC.lastName.value = this.props.address.lastName;
            CC.lastName.correct = true;
            this.setState({ cc: CC });
        }
    }

    handleChange(data: any) {
        const param = data.param;
        const copy = clone(this.state.cc[param]);
        const value = data.e.target.value.trimStart();
        switch (param) {
            case 'firstName':
                copy.value = value.replace(/\d/g, '').slice(0, 70);
                if (copy.value.length > 0) {
                    copy.correct = true;
                    copy.inputError = false;
                } else {
                    copy.correct = false;
                }
                break;
            case 'lastName':
                copy.value = value.replace(/\d/g, '').slice(0, 80);
                if (copy.value.length > 0) {
                    copy.correct = true;
                    copy.inputError = false;
                } else {
                    copy.correct = false;
                }
                break;
            case 'number':
                const number = value.replace(/\D/g, '').slice(0, 19);
                copy.value = number;
                if (number.length > 12 && number.length < 20) {
                    copy.correct = true;
                } else {
                    copy.correct = false;
                }
                break;
            case 'cvc':
                const cvc = value.replace(/\D/g, '');
                copy.value = cvc.slice(0, 4);
                if (copy.value.length === 3 || copy.value.length === 4) {
                    copy.correct = true;
                } else {
                    copy.correct = false;
                }
                break;
            case 'date':
                const date = value.replace(/\D/g, '').slice(0, 4);
                copy.value = datePrettifier(date);
                if (date.length === 4) {
                    copy.correct = true;
                } else {
                    copy.correct = false;
                }
                break;
        }
        const copyCC = clone(this.state.cc);
        copyCC[param] = copy;
        this.setState({ cc: copyCC });
    }

    onTimeOut = () => {
        this.props.history.push('/');
    };

    render() {
        if (this.state.error) {
            // prettier-ignore
            throw new ((ServerError as unknown) as FunctionConstructor)("server didn't respond");
        }
        const errors = {
            number: !this.state.cc.number.correct && this.state.clicked,
            firstName: !this.state.cc.firstName.correct && this.state.clicked,
            lastName: !this.state.cc.lastName.correct && this.state.clicked,
            cvc: !this.state.cc.cvc.correct && this.state.clicked,
            date: !this.state.cc.date.correct && this.state.clicked,
        };
        const inputClasses = this.props.classes.resize + (this.props.swiped ? ' ' + this.props.classes.readOnly : '');
        if (this.state.swipedError && this.props.swiped) {
            return <Redirect to={{ pathname: '/swipe/', state: {} }} />;
        }
        if (this.state.loading) {
            return <Loading description="loading.processingPayment" />;
        } else {
            return (
                <div id="whole">
                    <TimeoutWarning timer={this.props.timeouts.cc} onTimeOut={this.onTimeOut} />
                    <Snackbar
                        style={{ marginTop: '250px' }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={this.state.swipedError}
                        onClose={() => this.setState({ swiped: false, swipedError: false })}
                    >
                        <Alert onClose={() => this.setState({ swiped: false, swipedError: false })} severity="error">
                            <FormattedMessage id="cc.error.declined" />
                        </Alert>
                    </Snackbar>
                    <SnackBar />
                    {/* pay with credit */}
                    <Header screen="cc" />
                    <div id="data">
                        <div className="content">
                            <div className="left">
                                <div className="content-cc">
                                    <h4 className="primary header-cc">
                                        {' '}
                                        <FormattedMessage id="cc.header" />
                                    </h4>

                                    <div className="cc">
                                        <VKWrapper
                                            id="cardNumber"
                                            screen="cc"
                                            fullWidth
                                            autoFocus={!this.props.swiped}
                                            typeKeyboard="number"
                                            className="addressInput addressIn"
                                            error={errors.number}
                                            helperText={errors.number ? 'Invalid' : ' '}
                                            onChange={(e) => {
                                                this.handleChange({ e: e, param: 'number' });
                                            }}
                                            value={this.state.cc.number.value}
                                            label="Credit Card number"
                                            variant="outlined"
                                            type="password"
                                            InputProps={{
                                                readOnly: this.props.swiped,
                                                classes: {
                                                    input: inputClasses,
                                                    notchedOutline: this.props.classes.label,
                                                },
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: this.props.classes.label,
                                                },
                                            }}
                                        />
                                        <div className="cvc-name" style={{ display: 'flex' }}>
                                            <VKWrapper
                                                id="firstName"
                                                screen="cc"
                                                fullWidth
                                                className="addressInput addressIn "
                                                error={errors.firstName}
                                                style={{ marginRight: '20px' }}
                                                helperText={errors.firstName ? 'Invalid' : ' '}
                                                onChange={(e) => {
                                                    this.handleChange({ e: e, param: 'firstName' });
                                                }}
                                                value={this.state.cc.firstName.value}
                                                label="First name"
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: this.props.swiped,
                                                    classes: {
                                                        input: inputClasses,
                                                        notchedOutline: this.props.classes.label,
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: this.props.classes.label,
                                                    },
                                                }}
                                            />
                                            <VKWrapper
                                                fullWidth
                                                id="lastName"
                                                screen="cc"
                                                className="addressInput addressIn "
                                                error={errors.lastName}
                                                helperText={errors.lastName ? 'Invalid' : ' '}
                                                onChange={(e) => {
                                                    this.handleChange({ e: e, param: 'lastName' });
                                                }}
                                                value={this.state.cc.lastName.value}
                                                label="Last name"
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: this.props.swiped,
                                                    classes: {
                                                        input: inputClasses,
                                                        notchedOutline: this.props.classes.label,
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: this.props.classes.label,
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className="cvc-name" style={{ display: 'flex' }}>
                                            <VKWrapper
                                                id="cvc"
                                                screen="cc"
                                                typeKeyboard="number"
                                                fullWidth
                                                autoFocus={this.props.swiped}
                                                style={{ marginRight: '20px' }}
                                                className="addressInput addressIn"
                                                error={errors.cvc}
                                                helperText={errors.cvc ? 'Invalid' : ' '}
                                                onChange={(e) => {
                                                    this.handleChange({ e: e, param: 'cvc' });
                                                }}
                                                value={this.state.cc.cvc.value}
                                                label="CVC"
                                                variant="outlined"
                                                type="password"
                                                InputProps={{
                                                    // readOnly: this.props.swiped,
                                                    classes: {
                                                        input: this.props.classes.resize,
                                                        notchedOutline: this.props.classes.label,
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: this.props.classes.label,
                                                    },
                                                }}
                                            />
                                            <VKWrapper
                                                id="date"
                                                screen="cc"
                                                fullWidth
                                                typeKeyboard="number"
                                                className="addressInput addressIn"
                                                error={errors.date}
                                                helperText={errors.date ? 'date.format' : ' '}
                                                onChange={(e) => {
                                                    this.handleChange({ e: e, param: 'date' });
                                                }}
                                                value={this.state.cc.date.value}
                                                label="Date"
                                                placeholder="MM/YY"
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: this.props.swiped,
                                                    classes: {
                                                        input: inputClasses,
                                                        notchedOutline: this.props.classes.label,
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    classes: {
                                                        root: this.props.classes.label,
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right right-swipe">
                                <div className="fees">
                                    <Fees byCash={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Buttons
                        props={{
                            clickGoBack: () => this.props.history.goBack(),
                            back: true,
                            confirm: true,
                            confirmText: 'finish',
                            clickConfirm: this.clickConfirm,
                        }}
                    />
                    <Footer />
                </div>
            );
        }
    }
}

const mapStateToProps = (state: StoreState) => ({
    amounts: state.amounts,
    inmate: state.inmate,
    phoneNumber: state.phoneNumber,
    transactionID: state.transactionID,
    address: state.address,
    fees: state.fees,
    cc: state.cc,
    swiped: state.swiped,
    timeouts: state.timeouts,
    toPhoneNumber: state.toPhoneNumber,
    apiKey: state.apiKey,
    serviceType: state.serviceType,
    captureImage: state.captureImage,
});

const mapDispatchFromProps = (dispatch: Dispatch) => ({
    setCC: (cc: CCn) => dispatch(setCC(cc)),
    setAddress: (address: Adr) => dispatch(setAddress(address)),
    setAmounts: (amounts: any) => dispatch(setAmounts(amounts)),
    setTransactionID: (amounts: any) => dispatch(setTransactionID(amounts)),
    setErrorText: (errorText: string) => dispatch(setErrorText(errorText)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchFromProps)(CCpage));
